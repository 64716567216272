import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';
import Classroom from 'models/Classroom';
import userService from 'services/userService';
import { ClassroomList } from 'view/components/common/ClassroomList';
import { useClassroomContext } from 'contexts/ClassroomContext';
import Modal from 'view/components/common/Modal';
import { CreateClassroomForm } from 'view/components/teacher/home/CreateClassroomForm';
import { AppPage } from 'view/components/common/AppPage';
import useApiCall from 'contexts/ApiCall';
import AppSidebar from 'view/components/common/Sidebar';
import User from 'models/User';
import contentService from 'services/contentService';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import HeaderRowButton from 'view/components/buttons/HeaderButton';

export const TeacherHomePage: React.FC = () => {
  const { user } = useUserContext();
  const { setClassroom } = useClassroomContext();
  const [classrooms, setClassrooms] = useState<Classroom[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const pageContent = contentService.getTeacherHomePage();
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;
    makeApiCall(userService.getUserClassrooms, user)
      .then((respClassrooms) => setClassrooms(respClassrooms))
      .catch((error) => alert(error.message));
  }, [user, makeApiCall]);

  const enterClassroomHandler = (classroom: Classroom) => {
    setClassroom(classroom);
    navigate(`/teacher/classroom/${classroom.id}`);
  };

  return (
    <AppPage>
      <div className="app-page-content">
        <AppSidebar user={user as User} />
        <div className="app-main-content">
          <div className="row spaced" style={{ paddingBottom: '20px' }}>
            <h1>{pageContent.content.title}</h1>
            <HeaderRowButton
              icon={faBook}
              label="Catalog"
              onClick={() => navigate('/catalog')}
            />
          </div>
          <ClassroomList
            classrooms={classrooms}
            onEnterClassroom={enterClassroomHandler}
            openAddClassroomForm={setModalOpen}
          />
        </div>
        <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
          <CreateClassroomForm
            onCreateClassroom={(newClassroom) => {
              setClassrooms((prev) => [...prev, newClassroom]);
              setModalOpen(false);
            }}
          />
        </Modal>
      </div>
    </AppPage>
  );
};
