import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, ChangeEvent } from 'react';
import { UserInput } from 'view/components/common/UserInput';

import 'view/style/home/login.css';

interface PasswordInputProps {
  id?: string;
  label?: string;
  password: string;
  verify?: boolean;
  valid?: boolean;
  text?: string;
  setPassword: (password: string) => void;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  id,
  label,
  password,
  verify = true,
  valid,
  text,
  setPassword,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <UserInput
      id={id ?? 'password'}
      type={showPassword ? 'text' : 'password'}
      label={label ?? 'Password'}
      hint={verify ? '8+ characters' : ''}
      valid={valid}
      text={text}
      inlineLabel={
        <FontAwesomeIcon
          icon={showPassword ? faEye : faEyeSlash}
          onClick={() => setShowPassword((prev) => !prev)}
        />
      }
      onChange={handlePasswordChange}
      minLength={8}
    />
  );
};

export default PasswordInput;
