import React, { useState, useMemo, useRef, useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import useApiCall from 'contexts/ApiCall';
import aiService from 'services/aiService';
import ImageResize from 'models/TextEditor/ImageResize';

import 'quill/dist/quill.snow.css';
import 'view/style/teacher/components/assignment/textEditor.css';

Quill.register('modules/imageResize', ImageResize);

interface TextEditorProps {
  initialValue: string | undefined;
  onChange: (content: string, delta: any, source: any, editor: any) => void;
  onGenerateText?: (text: string) => void;
}

export const TextEditor: React.FC<TextEditorProps> = ({
  initialValue,
  onChange,
  onGenerateText,
}) => {
  const makeApiCall = useApiCall();
  const [content, setContent] = useState(initialValue ?? '');

  const onGenerateTextRef = useRef(onGenerateText);
  useEffect(() => {
    onGenerateTextRef.current = onGenerateText;
  }, [onGenerateText]);

  useEffect(() => {
    if (initialValue !== undefined && initialValue !== content) {
      setContent(initialValue);
    }
  }, [initialValue, content]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ size: ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          ['link', 'image'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
            { align: [] },
          ],
          ['generateButton'],
        ],
        handlers: {
          generateButton: function (this: any) {
            const editorText = this.quill.getText();
            const generateAction = onGenerateTextRef.current;

            if (generateAction) {
              generateAction(editorText);
            } else {
              makeApiCall(aiService.generateText, editorText)
                .then((resp) => this.quill.setText(resp.text))
                .catch(() => alert('Failed to generate text'));
            }
          },
        },
      },
      imageResize: {},
    }),
    [makeApiCall],
  );

  const formats = [
    'header',
    'height',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'color',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'size',
    'width', // Allow width
    'height', // Allow height
  ];

  const handleEditorChange = (
    newContent: string,
    delta: any,
    source: any,
    editor: any,
  ) => {
    setContent(newContent);
    onChange(newContent, delta, source, editor);
  };

  return (
    <div className="text-editor">
      <ReactQuill
        id="text"
        theme="snow"
        modules={modules}
        formats={formats}
        placeholder="Write your content..."
        value={content}
        onChange={handleEditorChange}
      />
    </div>
  );
};
