import React, { useState } from 'react';
import Book from 'models/Book';
import ActionButton from 'view/components/buttons/ActionButton';
import contentService from 'services/contentService';
import { CatalogTags } from './CatalogTags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faStar } from '@fortawesome/free-solid-svg-icons';
import BookCover from './BookCover';

import 'view/style/catalog/components/bookCard.css';

interface BookCardProps {
  book: Book;
  liked: boolean;
  onLike: (book: Book, liked: boolean) => void;
  onView: (book: Book) => void;
  onSelect?: (book: Book) => void;
}

type BookDetail = {
  label: string;
  accessor: keyof Book;
};

const BookCard = React.forwardRef<HTMLDivElement, BookCardProps>(
  ({ book, liked, onLike, onView, onSelect }, ref) => {
    const formContent = contentService.getCatalogPage().book_preview;
    const [isLiked, setIsLiked] = useState<boolean>(liked);

    const handleLike = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      event?.stopPropagation();
      setIsLiked((prev) => {
        onLike(book, !prev);
        return !prev;
      });
    };

    const handleViewBook = () => {
      onView(book);
    };

    return (
      <div className="book-card" onClick={handleViewBook} ref={ref}>
        <BookCover book={book} />
        <div className="book-card-details">
          <div className="row spaced">
            <span className="label-large">{book.title}</span>
            <FontAwesomeIcon
              icon={faStar}
              onClick={handleLike}
              color={isLiked ? '#447D00' : '#D4D4D4'}
            />
          </div>
          {book.learner_level.length > 0 && (
            <div key={`book-detail-level`} className="book-card-metadata">
              <div className="row">
                <FontAwesomeIcon icon={faGraduationCap} size="xs" />
                <label className="label-normal">
                  {book.learner_level.join(' / ')}
                </label>
              </div>
            </div>
          )}
          <div className="col">
            {formContent.details.map((detail: BookDetail) => (
              <div
                key={`book-detail-${detail.label}`}
                className="book-card-metadata"
                style={{ justifyContent: 'space-between' }}
              >
                <label className="label-small">{detail.label}:</label>
                <label className="label-small">
                  {book[detail.accessor]?.toString() ?? 'N/A'}
                </label>
              </div>
            ))}
          </div>
          {book.topics.length > 0 && (
            <div key={`book-detail-topics`} className="book-card-metadata">
              <CatalogTags tags={book.topics} />
            </div>
          )}
          {book.tags.length > 0 && (
            <div key={`book-detail-tags`} className="book-card-metadata">
              <CatalogTags tags={book.tags} />
            </div>
          )}
          {onSelect && (
            <ActionButton
              type="go"
              onClick={() => onSelect(book)}
              label="Select"
            />
          )}
        </div>
      </div>
    );
  },
);

export default BookCard;
