import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import contentService from 'services/contentService';
import { SOCIAL_LINKS } from '../contact/contact';
import VideoPlayer from 'view/components/common/VideoPlayer';
import { useInView } from 'react-intersection-observer';
import HorizontalCarousel from 'view/components/common/HorizontalCarousel';
import Modal from 'view/components/common/Modal';
import DemoRequestForm from 'view/components/landing/DemoRequestForm';

import './landing.css';

const promoVideo = require('assets/video/promo.mp4');

const ICONS: Record<string, string> = {
  auto_graph: require('assets/images/auto-graph.svg').default,
  connect_without_contact: require('assets/images/connect-without-contact.svg')
    .default,
  forum: require('assets/images/forum.svg').default,
  grading: require('assets/images/grading.svg').default,
  groups: require('assets/images/groups.svg').default,
  reduce_capacity: require('assets/images/reduce-capacity.svg').default,
  arrow_forward: require('assets/icons/arrow-forward.svg').default,
  arrow_down: require('assets/icons/arrow-down.svg').default,
  linkedin: require('assets/icons/linkedin.svg').default,
  facebook: require('assets/icons/facebook.svg').default,
  instagram: require('assets/icons/instagram.svg').default,
};

const IMAGES: Record<string, string> = {
  logo_white: require('assets/images/logos/official_logo_white.png'),
  teacher_catalog: require('assets/images/demo/teacher/Catalog.png'),
  teacher_onboarding: require('assets/images/demo/teacher/Onboarding.png'),
  teacher_insights: require('assets/images/demo/teacher/Insights.png'),
  wormy_e: require('assets/images/logos/wormy-book-3.png'),
  student_reading: require('assets/images/demo/student/Reading.png'),
  student_writing: require('assets/images/demo/student/Writing.png'),
  student_listening: require('assets/images/demo/student/Listening.png'),
  student_speaking: require('assets/images/demo/student/Speaking.png'),
};

// const demo_book = new Book(
//   '',
//   'Our Mission',
//   'Our Mission To provide an effective, equitable, accessible, and easy-to-use software solution to help students reach their full potential by giving them a strong foundation of literacy and fluency in English.',
//   '<h2>Our Mission</h2><p>To provide an effective, equitable, accessible, and easy-to-use software solution to help students reach their full potential by giving them a strong foundation of literacy and fluency in English.</p>',
//   '',
//   '',
//   '',
//   4,
//   '',
//   '',
//   true,
//   false,
// )

export const LandingPage: React.FC = () => {
  const landingContent = contentService.getLandingPage();
  const [expandedFaqs, setExpandedFaqs] = useState<number[]>([]);
  const [showPricingDetails, setShowPricingDetails] = useState<boolean>(false);
  const [showRequestDemo, setShowRequestDemo] = useState<boolean>(false);
  const { ref: welcomeRef, inView: welcomeInView } = useInView({
    triggerOnce: false,
    threshold: 0,
  });
  const { ref: summaryRef, inView: summaryInView } = useInView({
    triggerOnce: false,
    threshold: 0,
  });
  const { ref: demoRef, inView: demoInView } = useInView({
    triggerOnce: false,
    threshold: 0,
  });
  const { ref: logisticsRef, inView: logisticsInView } = useInView({
    triggerOnce: false,
    threshold: 0,
  });
  const { ref: faqRef, inView: faqInView } = useInView({
    triggerOnce: false,
    threshold: 0,
  });
  const { ref: signupRef, inView: signupInView } = useInView({
    triggerOnce: false,
    threshold: 0,
  });
  const navigate = useNavigate();

  const handleFaqClick = (idx: number) => {
    setExpandedFaqs((prev) => {
      const expanded = prev.find((it) => it === idx) !== undefined;
      if (expanded) {
        return [...prev.filter((it) => it !== idx)];
      }
      return [...prev, idx];
    });
  };

  const handleSocialClick = (social: string) => {
    window.open(SOCIAL_LINKS[social], '_blank');
  };

  return (
    <div className="landing-page">
      <div
        ref={welcomeRef}
        className={`welcome-content ${welcomeInView ? 'fade-in-section' : ''}`}
      >
        <div className="hero">
          <div className="text-content">
            <h1 className="green">{landingContent.content.welcome.title}</h1>
            <span className="label-medium">
              {landingContent.content.welcome.subText}
            </span>
            <div className="row">
              <button
                className="landing-button green"
                onClick={() => navigate('signup/')}
              >
                <span className="label-normal">Sign up</span>
              </button>
              <button
                className="landing-button blue"
                onClick={() => setShowRequestDemo(true)}
              >
                <span className="label-normal">Request a demo</span>
              </button>
            </div>
          </div>
          <div className="content">
            <VideoPlayer src={promoVideo} />
          </div>
        </div>
      </div>

      <div
        ref={summaryRef}
        className={`summary-content ${summaryInView ? 'fade-in-section' : ''}`}
      >
        <h1 className="green">{landingContent.content.summary.title}</h1>
        <div className="content">
          {landingContent.content.summary.content.map(
            (item: Record<string, string>) => (
              <div key={`card-${item.desc}`} className="card">
                <img src={ICONS[item.icon]} alt="" />
                <span className="label-large">{item.desc}</span>
              </div>
            ),
          )}
        </div>
      </div>

      <div
        ref={demoRef}
        className={`demo-content ${demoInView ? 'fade-in-section' : ''}`}
      >
        <div className="text-content">
          <h1 className="white">{landingContent.content.demo.student.title}</h1>
        </div>
        <HorizontalCarousel
          textContent={landingContent.content.demo.student.subTexts.map(
            (text: string) => (
              <div className="text-content">
                <span className="label-medium">{text}</span>
              </div>
            ),
          )}
          pages={[
            <img src={IMAGES.student_reading} alt="" />,
            <img src={IMAGES.student_listening} alt="" />,
            <img src={IMAGES.student_speaking} alt="" />,
            <img src={IMAGES.student_writing} alt="" />,
          ]}
        />
        <hr />
        <div className="teacher-demo">
          <HorizontalCarousel
            pages={[
              <img src={IMAGES.teacher_insights} alt="" />,
              <img src={IMAGES.teacher_onboarding} alt="" />,
              <img src={IMAGES.teacher_catalog} alt="" />,
            ]}
          />
          <div className="text-content">
            <h1 className="white">
              {landingContent.content.demo.teacher.title}
            </h1>
            <span className="label-medium">
              {landingContent.content.demo.teacher.subText}
            </span>
          </div>
        </div>
      </div>

      <div
        ref={logisticsRef}
        className={`logistics-content ${
          logisticsInView ? 'fade-in-section' : ''
        }`}
      >
        <div className="text-content faqs">
          <h1 className="green">{landingContent.content.pricing.title}</h1>
          <span className="label-medium">
            {landingContent.content.pricing.subText}
          </span>
          <div
            key="pricing-details"
            className={`classroom-panel${
              showPricingDetails ? ' expanded' : ''
            }`}
            onClick={() => setShowPricingDetails((prev) => !prev)}
          >
            <div className="row">
              <span className="label-large">Details</span>
              <img
                src={
                  showPricingDetails ? ICONS.arrow_down : ICONS.arrow_forward
                }
                alt=""
              />
            </div>
            <ul>
              {showPricingDetails &&
                landingContent.content.pricing.details.map(
                  (detail: Record<string, string>) => (
                    <li>
                      <b>{detail.range}:</b> {detail.price}
                    </li>
                  ),
                )}
            </ul>
            <span className="label-medium"></span>
          </div>
        </div>

        <hr />

        <div
          ref={faqRef}
          className={`faqs ${faqInView ? 'fade-in-section' : ''}`}
        >
          <h1 className="green">FAQ</h1>
          {landingContent.content.faq.content.map(
            (faq: Record<string, string>, idx: number) => {
              const expanded =
                expandedFaqs.find((it) => it === idx) !== undefined;
              return (
                <div
                  key={`faq-${idx}`}
                  className={`classroom-panel${expanded ? ' expanded' : ''}`}
                  onClick={() => handleFaqClick(idx)}
                >
                  <div className="row">
                    <span className="label-large">{faq.question}</span>
                    <img
                      src={expanded ? ICONS.arrow_down : ICONS.arrow_forward}
                      alt=""
                    />
                  </div>
                  <span className="label-medium">{expanded && faq.answer}</span>
                </div>
              );
            },
          )}
        </div>
      </div>

      <hr />

      <div
        ref={signupRef}
        className={`signup-content ${signupInView ? 'fade-in-section' : ''}`}
      >
        <div className="social-media-row">
          <span
            style={{ color: '#447D00', width: '80vw' }}
            className="label-xl"
          >
            Follow Us
          </span>
          <div className="row">
            <div
              className="social-media-button"
              onClick={() => handleSocialClick('facebook')}
            >
              <img src={ICONS.facebook} alt="" />
              <span>Facebook</span>
            </div>
            <div
              className="social-media-button"
              onClick={() => handleSocialClick('linkedin')}
            >
              <img src={ICONS.linkedin} alt="" />
              <span>LinkedIn</span>
            </div>
            <div
              className="social-media-button"
              onClick={() => handleSocialClick('instagram')}
            >
              <img src={ICONS.instagram} alt="" />
              <span>Instagram</span>
            </div>
          </div>
        </div>
        <div className="signup-header">
          <img src={IMAGES.wormy_e} alt="" style={{ width: '200px' }} />
          <div className="text-content">
            <h1 className="white">{landingContent.content.signup.title}</h1>
            <span className="label-medium">
              {landingContent.content.signup.subText}
            </span>
            <button
              className="landing-button white"
              onClick={() => navigate('signup/')}
            >
              <span className="label-normal">Create an Account</span>
            </button>
          </div>
        </div>
      </div>

      <footer>
        <div className="brand-info">
          <img src={IMAGES.logo_white} alt="" />
          <span className="label-medium">
            © 2023 Orator Technology Inc. All rights reserved.
          </span>
        </div>
        <div className="links">
          {landingContent.footer.links.map((item: Record<string, string>) => (
            <Link key={`footer-link-${item.name}`} to={item.url}>
              {item.name}
            </Link>
          ))}
        </div>
      </footer>
      <Modal isOpen={showRequestDemo} onClose={() => setShowRequestDemo(false)}>
        <DemoRequestForm onSubmit={() => setShowRequestDemo(false)} />
      </Modal>
    </div>
  );
};
