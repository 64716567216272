import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Book from 'models/Book';
import MissedWord from 'models/MissedWord';
import Modal from 'view/components/common/Modal';
import {
  QuestionViewerState,
  QuestionsViewer,
} from 'view/components/student/QuestionsViewer';
import StudentQuestion from 'models/StudentQuestion';
import { AppPage } from 'view/components/common/AppPage';
import useApiCall from 'contexts/ApiCall';
import AssignmentSettings from 'view/components/student/assignment/AssignmentSettings';
import teacherService from 'services/teacherService';
import Assignment from 'models/Assignment';
import QuestionResponse from 'models/QuestionResponse';
import Question from 'models/Question';
import AssignmentContent from '../student/assignment/AssignmentContent';
import AssignmentViewerSidebar from '../student/assignment/AssignmentViewerSidebar';
import StudentGradebookEntry from 'models/StudentGradebookEntry';
import AssignmentSubmission from 'models/AssignmentSubmission';

import 'view/style/student/assignment.css';

type AssignmentPreviewProps = {
  assignment: Assignment;
  onSelect?: (
    assignment: Assignment,
    book?: Book,
    questions?: Question[],
  ) => void;
  onExit: () => void;
};

const AssignmentPreview: React.FC<AssignmentPreviewProps> = ({
  assignment,
  onSelect,
  onExit,
}) => {
  const [book, setBook] = useState<Book>();
  const [questions, setQuestions] = useState<StudentQuestion[]>();
  const [viewQuestions, setViewQuestions] = useState<boolean>(false);
  const [isReading, setIsReading] = useState<boolean>(false);
  const [viewSettings, setViewSettings] = useState<boolean>(false);
  const [assignmentWords, setAssignmentWords] = useState<MissedWord[]>();
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (!assignment || book || questions) return;
    makeApiCall(teacherService.getAssignmentById, assignment.id)
      .then((resp) => {
        setBook(resp.book);
        setQuestions(
          resp.questions.map((q: Question) => {
            return {
              question: q,
              response: QuestionResponse.generateDefault(),
            };
          }),
        );
      })
      .catch((error) => onExit());
  }, [
    assignment,
    assignment.id,
    book,
    questions,
    setBook,
    setQuestions,
    makeApiCall,
    navigate,
    onExit,
  ]);

  useEffect(() => {
    if (assignmentWords) return;
    if (assignment.key_words) {
      setAssignmentWords(
        assignment.key_words.map(
          (kw) => new MissedWord('-1', kw, '-1', 0, false, true),
        ),
      );
    } else {
      setAssignmentWords([]);
    }
  }, [assignment.key_words, assignmentWords, setAssignmentWords]);

  const handleCloseQuestions = () => {
    setViewQuestions(false);
  };

  const handleViewQuestions = () => {
    setViewQuestions(true);
  };

  const handleSelectAssignment = () => {
    if (!onSelect) return;
    onSelect(
      assignment,
      book,
      questions?.map((q) => q.question),
    );
  };

  const previewAssignment = useMemo(
    () =>
      new StudentGradebookEntry(
        assignment,
        AssignmentSubmission.generateDefault(assignment.id),
      ),
    [assignment],
  );

  if (!assignment) return <AppPage />;

  return (
    <div className="app-page-content">
      <AssignmentViewerSidebar
        assignment={previewAssignment}
        missedWords={[]}
        useDyslexic={false}
        onExitAssignment={onExit}
        onViewSettings={() => setViewSettings(true)}
        onToggleDyslexic={() => {}}
        onSelect={handleSelectAssignment}
      />
      <AssignmentContent
        assignment={previewAssignment}
        book={book}
        missedWords={assignmentWords ?? []}
        questions={questions}
        isReading={isReading}
        useDyslexic={false}
        setIsReading={setIsReading}
        onBookCompleted={() => {}}
        onStopReading={() => {}}
        onMissedWord={async () => {}}
        onViewQuestions={handleViewQuestions}
        onListeningCompleted={() => {}}
        onListeningPaused={() => {}}
        onMultiPartCompleted={() => {}}
        onQuestionResponseChanged={() => undefined}
        teacherView={true}
      />
      {questions && (
        <Modal isOpen={viewQuestions} onClose={handleCloseQuestions}>
          <QuestionsViewer
            state={QuestionViewerState.Locked}
            grade={0}
            questions={questions}
            onExit={handleCloseQuestions}
          />
        </Modal>
      )}
      <Modal isOpen={viewSettings} onClose={() => setViewSettings(false)}>
        <AssignmentSettings />
      </Modal>
    </div>
  );
};

export default AssignmentPreview;
