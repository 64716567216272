import React, { useState } from 'react';
import Button from 'view/components/buttons/Button';
import { ReadingState } from '../BookReader';
import {
  faCheck,
  faPauseCircle,
  faPlayCircle,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';

interface BookReaderCatalogControlsProps {
  readingState: ReadingState;
  hideQuestions: boolean;
  onViewQuestions?: () => void;
  onRetry: () => void;
  onSelect?: (assignmentType: string) => void;
  onStartReading: () => void;
  onStopReading: () => void;
}

const BookReaderCatalogControls: React.FC<BookReaderCatalogControlsProps> = ({
  hideQuestions,
  readingState,
  onViewQuestions,
  onRetry,
  onSelect,
  onStartReading,
  onStopReading,
}) => {
  const [assignmentType, setAssignmentType] = useState<string>('');
  const handleSelectAssignment = () => {
    if (!onSelect) return;

    onSelect(assignmentType);
  };

  return (
    <div className="assignment-controls">
      {onSelect && (
        <>
          <select onChange={(event) => setAssignmentType(event.target.value)}>
            <option value="Default">Reading</option>
            <option value="Read-Aloud">Listening</option>
            <option value="Default">Listening Assessment</option>
            <option value="Speaking">Speaking Assessment</option>
          </select>
          <Button
            type="option"
            onClick={handleSelectAssignment}
            label="Select"
            fontAwesomeIcon={faCheck}
          />
        </>
      )}
      {readingState === ReadingState.Paused && (
        <Button
          type="go"
          onClick={onStartReading}
          text="Read"
          fontAwesomeIcon={faPlayCircle}
          label="Read"
        />
      )}
      {readingState === ReadingState.Reading && (
        <Button
          type="default"
          onClick={onStopReading}
          text="Stop"
          fontAwesomeIcon={faPauseCircle}
          label="Stop"
        />
      )}
      {!hideQuestions && (
        <div className="book-completed-text">
          {onViewQuestions && (
            <Button
              type="option"
              onClick={onViewQuestions}
              text="Questions"
              label="Questions"
            />
          )}
        </div>
      )}
      {readingState === ReadingState.Completed && (
        <Button
          onClick={onRetry}
          text="retry"
          fontAwesomeIcon={faSyncAlt}
          label="Retry"
        />
      )}
    </div>
  );
};

export default BookReaderCatalogControls;
