import {
  faBook,
  faLandmark,
  faFlask,
  faMicroscope,
  faDna,
  faLeaf,
  faAtom,
  faGlobe,
  faGlasses,
  faBrain,
  faUsers,
  faGavel,
  faGraduationCap,
  faCode,
  faRobot,
  faLock,
  faBriefcase,
  faMoneyBillWave,
  faMusic,
  faTheaterMasks,
  faPalette,
  faCalculator,
  faPencilAlt,
  faBalanceScale,
  faRocket,
  faLaptopCode,
  faTree,
  faVolleyballBall,
  faChartBar,
  faCapsules,
  faUserMd,
  faBusinessTime,
  faGlobeAmericas,
  faPaw,
  faPlaceOfWorship,
  faDatabase,
  faLightbulb,
  faHeart,
  faIndustry,
  faCogs,
  faBolt,
  faBuilding,
  faRulerCombined,
  faSquareRootAlt,
  faDice,
  faTh,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ICONS: Record<string, JSX.Element> = {
  // History
  History: <FontAwesomeIcon icon={faLandmark} size="xs" />,
  'U.S. History': <FontAwesomeIcon icon={faLandmark} size="xs" />,
  'Roman History': <FontAwesomeIcon icon={faLandmark} size="xs" />,
  'European History': <FontAwesomeIcon icon={faLandmark} size="xs" />,
  'World History': <FontAwesomeIcon icon={faGlobeAmericas} size="xs" />,
  'Spanish History': <FontAwesomeIcon icon={faLandmark} size="xs" />,
  'French History': <FontAwesomeIcon icon={faLandmark} size="xs" />,
  'German History': <FontAwesomeIcon icon={faLandmark} size="xs" />,
  'Chinese History': <FontAwesomeIcon icon={faLandmark} size="xs" />,
  'Japanese History': <FontAwesomeIcon icon={faLandmark} size="xs" />,
  'English History': <FontAwesomeIcon icon={faLandmark} size="xs" />,

  // Science
  Biology: <FontAwesomeIcon icon={faMicroscope} size="xs" />,
  Microbiology: <FontAwesomeIcon icon={faMicroscope} size="xs" />,
  Genetics: <FontAwesomeIcon icon={faDna} size="xs" />,
  Biochemistry: <FontAwesomeIcon icon={faFlask} size="xs" />,
  Ecology: <FontAwesomeIcon icon={faLeaf} size="xs" />,
  Zoology: <FontAwesomeIcon icon={faPaw} size="xs" />,
  Botany: <FontAwesomeIcon icon={faTree} size="xs" />,
  Chemistry: <FontAwesomeIcon icon={faFlask} size="xs" />,
  'Organic Chemistry': <FontAwesomeIcon icon={faFlask} size="xs" />,
  'Inorganic Chemistry': <FontAwesomeIcon icon={faFlask} size="xs" />,
  'Physical Chemistry': <FontAwesomeIcon icon={faFlask} size="xs" />,
  Physics: <FontAwesomeIcon icon={faAtom} size="xs" />,
  Astronomy: <FontAwesomeIcon icon={faRocket} size="xs" />,
  Geology: <FontAwesomeIcon icon={faGlobe} size="xs" />,
  'Earth Science': <FontAwesomeIcon icon={faGlobe} size="xs" />,
  'Environmental Science': <FontAwesomeIcon icon={faTree} size="xs" />,

  // Mathematics
  Mathematics: <FontAwesomeIcon icon={faCalculator} size="xs" />,
  Algebra: <FontAwesomeIcon icon={faCalculator} size="xs" />,
  Geometry: <FontAwesomeIcon icon={faRulerCombined} size="xs" />,
  Calculus: <FontAwesomeIcon icon={faSquareRootAlt} size="xs" />,
  Statistics: <FontAwesomeIcon icon={faChartBar} size="xs" />,
  Probability: <FontAwesomeIcon icon={faDice} size="xs" />,
  'Discrete Mathematics': <FontAwesomeIcon icon={faCalculator} size="xs" />,
  'Linear Algebra': <FontAwesomeIcon icon={faTh} size="xs" />,
  'Differential Equations': <FontAwesomeIcon icon={faCalculator} size="xs" />,

  // Literature & Language
  Literature: <FontAwesomeIcon icon={faBook} size="xs" />,
  'World Literature': <FontAwesomeIcon icon={faBook} size="xs" />,
  'Creative Writing': <FontAwesomeIcon icon={faPencilAlt} size="xs" />,
  'Language Arts': <FontAwesomeIcon icon={faGlasses} size="xs" />,
  'Foreign Languages': <FontAwesomeIcon icon={faGlobe} size="xs" />,

  // Social Sciences
  Sociology: <FontAwesomeIcon icon={faUsers} size="xs" />,
  Psychology: <FontAwesomeIcon icon={faBrain} size="xs" />,
  Philosophy: <FontAwesomeIcon icon={faBalanceScale} size="xs" />,
  Economics: <FontAwesomeIcon icon={faChartBar} size="xs" />,
  'Political Science': <FontAwesomeIcon icon={faGavel} size="xs" />,
  Anthropology: <FontAwesomeIcon icon={faLandmark} size="xs" />,
  Education: <FontAwesomeIcon icon={faGraduationCap} size="xs" />,
  Geography: <FontAwesomeIcon icon={faGlobeAmericas} size="xs" />,
  Religion: <FontAwesomeIcon icon={faPlaceOfWorship} size="xs" />,

  // Computer Science & Technology
  'Computer Science': <FontAwesomeIcon icon={faLaptopCode} size="xs" />,
  Programming: <FontAwesomeIcon icon={faCode} size="xs" />,
  'Data Science': <FontAwesomeIcon icon={faDatabase} size="xs" />,
  'Artificial Intelligence': <FontAwesomeIcon icon={faBrain} size="xs" />,
  'Machine Learning': <FontAwesomeIcon icon={faRobot} size="xs" />,
  Cybersecurity: <FontAwesomeIcon icon={faLock} size="xs" />,
  'Software Engineering': <FontAwesomeIcon icon={faLaptopCode} size="xs" />,
  'Web Development': <FontAwesomeIcon icon={faCode} size="xs" />,

  // Arts
  'Visual Arts': <FontAwesomeIcon icon={faPalette} size="xs" />,
  Music: <FontAwesomeIcon icon={faMusic} size="xs" />,
  Theater: <FontAwesomeIcon icon={faTheaterMasks} size="xs" />,
  Dance: <FontAwesomeIcon icon={faMusic} size="xs" />,

  // Business
  Business: <FontAwesomeIcon icon={faBriefcase} size="xs" />,
  Finance: <FontAwesomeIcon icon={faMoneyBillWave} size="xs" />,
  Marketing: <FontAwesomeIcon icon={faChartBar} size="xs" />,
  Management: <FontAwesomeIcon icon={faBusinessTime} size="xs" />,
  Accounting: <FontAwesomeIcon icon={faCalculator} size="xs" />,
  Entrepreneurship: <FontAwesomeIcon icon={faLightbulb} size="xs" />,

  // Health & Medicine
  Medicine: <FontAwesomeIcon icon={faUserMd} size="xs" />,
  Nursing: <FontAwesomeIcon icon={faUserMd} size="xs" />,
  Pharmacology: <FontAwesomeIcon icon={faCapsules} size="xs" />,
  'Public Health': <FontAwesomeIcon icon={faHeart} size="xs" />,

  // Law
  Law: <FontAwesomeIcon icon={faGavel} size="xs" />,
  'International Relations': (
    <FontAwesomeIcon icon={faGlobeAmericas} size="xs" />
  ),

  // Engineering
  Engineering: <FontAwesomeIcon icon={faIndustry} size="xs" />,
  'Mechanical Engineering': <FontAwesomeIcon icon={faCogs} size="xs" />,
  'Electrical Engineering': <FontAwesomeIcon icon={faBolt} size="xs" />,
  'Civil Engineering': <FontAwesomeIcon icon={faBuilding} size="xs" />,
  'Chemical Engineering': <FontAwesomeIcon icon={faFlask} size="xs" />,

  // Misc
  Sports: <FontAwesomeIcon icon={faVolleyballBall} size="xs" />,
  Default: <FontAwesomeIcon icon={faBook} size="xs" />,
};
