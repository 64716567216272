import React, { ReactNode } from 'react';
import Button from 'view/components/buttons/Button';
import { FileInput } from './FileInputs';
import { FromToInput } from 'view/components/common/UserInput';
import FinePrint from 'view/components/common/FinePrint';
import { VersionTag } from 'view/components/common/VersionTag';
import Assignment from 'models/Assignment';
import Book from 'models/Book';
import AudioPlayer from 'view/components/common/AudioPlayer';
import { useClassroomContext } from 'contexts/ClassroomContext';
import WordAdder from 'view/components/common/WordAdder';
import { useUserContext } from 'contexts/UserContext';
import { LearnerLevel, Topic } from 'utils/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartSimple,
  faClock,
  faClone,
  faComment,
  faFolderOpen,
  faGlobe,
  faGraduationCap,
  faImage,
  faKey,
  faMicrophone,
  faNewspaper,
  faSchool,
} from '@fortawesome/free-solid-svg-icons';

const arrow_back: string = require('assets/icons/arrow_back.svg').default;

interface AssignmentSidebarProps {
  assignment: Assignment;
  book?: Book;
  options: string[];
  coverImage?: string;
  handleBookCoverChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCancel: () => void;
  setAssignment: React.Dispatch<React.SetStateAction<Assignment | undefined>>;
  updateAssignment: (event: any) => void;
  updateBook?: (event: any) => void;
  children?: ReactNode;
}

const AssignmentSidebar: React.FC<AssignmentSidebarProps> = ({
  assignment,
  book,
  options,
  coverImage,
  handleBookCoverChange,
  handleInputFileChange,
  onCancel,
  setAssignment,
  updateAssignment,
  updateBook,
  children,
}) => {
  const { user } = useUserContext();
  const { classroom } = useClassroomContext();

  const handleAddKeyWord = (keyWord: string) => {
    setAssignment((prev) =>
      Assignment.fromServerAssignment({
        ...prev,
        key_words: Array.from(
          new Set([...(prev as Assignment).key_words, keyWord.trim()]),
        ),
      }),
    );
  };

  const handleDeleteKeyWord = (word: string) => {
    setAssignment((prev) =>
      Assignment.fromServerAssignment({
        ...(prev as Assignment),
        key_words: prev?.key_words.filter((w) => w !== word),
      }),
    );
  };

  const handleUpdateSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    if (value === '') {
      updateAssignment({ target: { value: [], id: event.target.id } });
    } else {
      updateAssignment({ target: { value: [value], id: event.target.id } });
    }

    if (updateBook) {
      if (value === '') {
        updateBook({ target: { value: [], id: event.target.id } });
      } else {
        updateBook({ target: { value: [value], id: event.target.id } });
      }
    }
  };

  return (
    <div className="app-sidebar">
      <div className="col">
        <Button onClick={onCancel} leadIcon={arrow_back} label="Back" />
        <div className="assignment-sidebar-content">
          <input
            id="start_date"
            type="datetime-local"
            defaultValue={assignment.start_date ?? ''}
            onChange={updateAssignment}
          />
          <input
            id="due_date"
            type="datetime-local"
            defaultValue={assignment.due_date ?? ''}
            onChange={updateAssignment}
          />
          <FileInput
            id="assignment-content-file-upload"
            label="Upload File"
            accept=".pdf, .txt, .jpeg, .jpg, .png, .heic"
            onFileChange={handleInputFileChange}
          />
          {classroom &&
            classroom.min_reading_level < classroom.max_reading_level && (
              <div className="assignment-attribute-container">
                <div className="sidebar-text">
                  <FontAwesomeIcon icon={faChartSimple} />
                  <label className="label-normal">Reading Groups</label>
                </div>
                <FromToInput
                  id="reading_level"
                  value={{
                    min: assignment.min_reading_level,
                    max: assignment.max_reading_level,
                  }}
                  onChange={updateAssignment}
                />
                <FinePrint
                  text="Note: Only students within this range will be assigned to this assignment"
                  type="danger"
                />
              </div>
            )}
          {/* {options.includes('speech-accuracy') && (
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <img src={speaker} alt="" />
                <label className="label-normal">Speech Accuracy</label>
              </div>
              <select
                id="correctness_level"
                value={assignment.correctness_level}
                onChange={updateAssignment}
              >
                <option value="Easy">Generous</option>
                <option value="Close">Default</option>
                <option value="Exact">Strict</option>
              </select>
            </div>
          )} */}
          {options.includes('missed-word-interval') && (
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <FontAwesomeIcon icon={faClock} />
                <label className="label-normal">Missed Word Interval</label>
                <input
                  type="number"
                  id="missed_word_interval"
                  defaultValue={assignment?.missed_word_interval ?? 3}
                  onChange={updateAssignment}
                />
              </div>
              <FinePrint text="Time in seconds the reader has to correctly pronounce a word" />
            </div>
          )}
          {options.includes('key-words') && (
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <FontAwesomeIcon icon={faKey} />
                <label className="label-normal">Key Words</label>
              </div>
              <WordAdder
                words={Array.from(assignment.key_words)}
                onAddWord={handleAddKeyWord}
                onDeleteWord={handleDeleteKeyWord}
              />
            </div>
          )}
          {book && options.includes('book-cover') && (
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <FontAwesomeIcon icon={faImage} />
                <label className="label-normal">Cover Image</label>
              </div>
              <FileInput
                id={'book-cover-upload'}
                label="Click to upload"
                accept=".jpeg, .jpg, .png"
                onFileChange={handleBookCoverChange}
              />
              {(coverImage || book.cover_url) && (
                <div className="book-card-cover">
                  <img src={coverImage ?? book.cover_url} alt="" />
                </div>
              )}
            </div>
          )}
          <div className="assignment-attribute-container">
            <div className="sidebar-text">
              <FontAwesomeIcon icon={faGraduationCap} />
              <label className="label-normal">Learner Level</label>
            </div>
            <select
              id="learner_level"
              value={
                assignment.learner_level.length
                  ? assignment.learner_level[0]
                  : ''
              }
              onChange={handleUpdateSelect}
            >
              <option value="">None</option>
              {Object.values(LearnerLevel).map((level) => (
                <option key={level} value={level}>
                  {level}
                </option>
              ))}
            </select>
          </div>
          <div className="assignment-attribute-container">
            <div className="sidebar-text">
              <FontAwesomeIcon icon={faNewspaper} />
              <label className="label-normal">Topic</label>
            </div>
            <select
              id="topics"
              value={assignment.topics.length ? assignment.topics[0] : ''}
              onChange={handleUpdateSelect}
            >
              <option value="">None</option>
              {Object.values(Topic)
                .sort()
                .map((level) => (
                  <option key={level} value={level}>
                    {level}
                  </option>
                ))}
            </select>
          </div>
          {book && options.includes('audio') && book.audio_url && (
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <FontAwesomeIcon icon={faMicrophone} />
                <label className="label-normal">Audio</label>
              </div>
              <AudioPlayer audioUrl={book.audio_url} />
            </div>
          )}
          {options.includes('show-popup') && (
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <FontAwesomeIcon icon={faClone} />
                <label className="label-normal">Show Missed Word Popup</label>
                <input
                  type="checkbox"
                  id="show_missed_word_popup"
                  checked={assignment?.show_missed_word_popup}
                  onChange={(event) =>
                    setAssignment((prev) =>
                      Assignment.fromServerAssignment({
                        ...prev,
                        show_missed_word_popup: event.target.checked,
                      }),
                    )
                  }
                />
              </div>
              <FinePrint text="Toggle whether a popup with word defintiion and pronunciation is displayed after a student misses a word" />
            </div>
          )}
          {options.includes('show-feedback') && (
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <FontAwesomeIcon icon={faComment} />
                <label className="label-normal">Show Feedback</label>
                <input
                  type="checkbox"
                  id="show_feedback"
                  checked={assignment?.show_feedback}
                  onChange={(event) =>
                    setAssignment((prev) =>
                      Assignment.fromServerAssignment({
                        ...prev,
                        show_feedback: event.target.checked,
                      }),
                    )
                  }
                />
              </div>
              <FinePrint text="Toggle whether to show AI generated feedback to students before the assignment due date" />
            </div>
          )}
          {user?.district?.id && (
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <FontAwesomeIcon icon={faSchool} />
                <label className="label-normal">Publish to District</label>
                <input
                  type="checkbox"
                  id="is_public"
                  checked={assignment.district ? true : false}
                  onChange={(event) =>
                    updateAssignment({
                      target: {
                        id: 'district',
                        value: event.target.checked
                          ? user!.district!.id
                          : undefined,
                      },
                    })
                  }
                />
              </div>
              <FinePrint text="Allow your assignment to be reused by other teachers in your district" />
            </div>
          )}
          {updateBook && (
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <FontAwesomeIcon icon={faGlobe} />
                <label className="label-normal">Make Public</label>
                <input
                  type="checkbox"
                  id="is_public"
                  checked={book?.is_public || book?.is_under_review}
                  onChange={(event) => {
                    if (book?.is_public) {
                      updateBook({
                        target: { id: 'is_public', value: !book?.is_public },
                      });
                    } else {
                      updateBook({
                        target: {
                          id: 'is_under_review',
                          value: event.target.checked,
                        },
                      });
                    }
                  }}
                />
              </div>
              <FinePrint text="This enables your submission to be viewable in our public catalog" />
            </div>
          )}
          <div className="assignment-attribute-container">
            <div className="sidebar-text">
              <FontAwesomeIcon icon={faFolderOpen} />
              <label className="label-normal">Module</label>
              <select
                id="module"
                defaultValue={assignment.module ?? ''}
                onChange={updateAssignment}
              >
                <option value={''}>None</option>
                {classroom?.modules.map((m) => (
                  <option key={`module-${m.id}`} value={m.id}>
                    {m.name}
                  </option>
                ))}
              </select>
            </div>
            {/* <FinePrint text="Amount of time the reader has to correctly pronounce a word" /> */}
          </div>
          {children}
        </div>
      </div>
      <VersionTag />
    </div>
  );
};

export default AssignmentSidebar;
