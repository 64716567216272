import React, { useEffect, useRef, useState } from 'react';
import Assignment from 'models/Assignment';
import AssignmentCard from './AssignmentCard';

import 'view/style/catalog/components/bookRow.css';

interface AssignmentRowProps {
  label: string;
  assignments: Assignment[];
  hasNextPage: boolean;
  liked: Set<string>;
  loadNextPage: () => Promise<void>;
  onSelect: (assignment: Assignment) => void;
}

const AssignmentRow: React.FC<AssignmentRowProps> = ({
  label,
  assignments,
  hasNextPage,
  liked,
  loadNextPage,
  onSelect,
}) => {
  const rowRef = useRef<HTMLDivElement>(null);
  const lastBookRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!lastBookRef.current || !hasNextPage || isLoading) return;

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        // Mark that we are now fetching
        setIsLoading(true);
        loadNextPage().finally(() => {
          // Once loadNextPage is done (whether success or error),
          // set isLoading back to false
          setIsLoading(false);
        });
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 1.0,
    });
    observer.observe(lastBookRef.current);

    return () => observer.disconnect();
  }, [assignments, hasNextPage, isLoading, loadNextPage]);

  return (
    <div className="book-catalog-row" ref={rowRef}>
      {assignments.map((assignment, index) => {
        const isLast = index === assignments.length - 1;
        return isLast ? (
          <AssignmentCard
            assignment={assignment}
            liked={liked.has(assignment.id)}
            onLike={() => {}}
            onView={onSelect}
            key={`${label}-assignment-card-${assignment.id}`}
            ref={lastBookRef}
          />
        ) : (
          <AssignmentCard
            key={`${label}-assignment-card-${assignment.id}`}
            assignment={assignment}
            onView={onSelect}
            onLike={() => {}}
            liked={liked.has(assignment.id)}
          />
        );
      })}
    </div>
  );
};

export default AssignmentRow;
