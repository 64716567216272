import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LandingButton } from 'view/components/buttons/LandingButton';
import { useUserContext } from 'contexts/UserContext';
import HamburgerMenu from 'view/components/common/HamburgerMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut, faUserCircle } from '@fortawesome/free-solid-svg-icons';

import 'view/style/common/components/header.css';

const logo: string = require('assets/images/logos/official_logo.png');

export const Header: React.FC = () => {
  const location = useLocation();
  const [headerElement, setHeaderElement] = useState<ReactElement | null>(null);
  const [viewportWidth, setViewportWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    if (
      location.pathname === '/' ||
      location.pathname.includes('/contact') ||
      location.pathname.includes('/privacy-policy') ||
      location.pathname.includes('/terms-of-service')
    ) {
      setHeaderElement(<LandingHeader />);
    } else if (
      !location.pathname.includes('/login') &&
      !location.pathname.includes('/signup') &&
      !location.pathname.includes('/forgot-password') &&
      !location.pathname.includes('/subscribe')
    ) {
      if (viewportWidth <= 980) {
        setHeaderElement(<AppHeaderMobile />);
      } else {
        setHeaderElement(<AppHeader />);
      }
    } else {
      setHeaderElement(null);
    }
  }, [location, location.pathname, viewportWidth]);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return headerElement;
};

const AppHeader: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  return (
    <header className="fixed-header">
      <div className="col a"></div>
      <div className="col b">
        <img src={logo} alt="" onClick={() => navigate('/')} />
      </div>
      <div className="col c" style={{ alignItems: 'flex-end' }}>
        <div className="row">
          <LandingButton
            type="grey"
            onClick={() =>
              navigate(`${user?.account_type.toLowerCase()}/profile/`)
            }
          >
            <FontAwesomeIcon icon={faUserCircle} />
            Profile
          </LandingButton>
          {/*
          <LandingButton 
            type='grey' 
            onClick={() => navigate(`${user?.account_type.toLowerCase()}/help/`)}
          >
            <img src={help} alt=''/>
            Help
          </LandingButton>  */}
          <LandingButton type="black" onClick={() => navigate('/logout')}>
            <FontAwesomeIcon icon={faSignOut} />
            Logout
          </LandingButton>
        </div>
      </div>
    </header>
  );
};

const AppHeaderMobile: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  return (
    <header className="fixed-header">
      <div className="col a"></div>
      <div className="col b">
        <img src={logo} alt="" onClick={() => navigate('/')} />
      </div>
      <div className="col c" style={{ alignItems: 'flex-end' }}>
        <HamburgerMenu
          menuItems={[
            {
              path: `/${user?.account_type.toLowerCase()}/profile/`,
              label: 'Profile',
            },
            {
              path: `/${user?.account_type.toLowerCase()}/help/`,
              label: 'Help',
            },
            {
              path: '/logout',
              label: 'Logout',
            },
          ]}
        />
      </div>
    </header>
  );
};

const LandingHeader: React.FC = () => {
  const navigate = useNavigate();

  return (
    <header className="landing-page-nav">
      <img src={logo} alt="" onClick={() => navigate('/')} />
      <div className="actions">
        <LandingButton type="white" onClick={() => navigate('contact/')}>
          Contact us
        </LandingButton>
        <LandingButton type="black" onClick={() => navigate('login/')}>
          Login
        </LandingButton>
      </div>
    </header>
  );
};
