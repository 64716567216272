import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useClassroomContext } from 'contexts/ClassroomContext';
import Assignment from 'models/Assignment';
import TeacherGradebook from 'models/TeacherGradebook';
import teacherService from 'services/teacherService';
import TeacherGradebookEntry from 'models/TeacherGradebookEntry';
import { DisplayMissedWord, ServerMissedWords } from 'utils/types';
import { AppPage } from 'view/components/common/AppPage';
import { formatSeconds } from 'utils/utils';
import useApiCall from 'contexts/ApiCall';
import AppSidebar from 'view/components/common/Sidebar';
import contentService from 'services/contentService';
import ActionButton from 'view/components/buttons/ActionButton';
import Modal from 'view/components/common/Modal';
import GeneratePdfPopup from 'view/components/teacher/analytics/GeneratePdfPopup';
import AssignmentAnayltics from 'view/components/teacher/analytics/AssignmentAnalytics';
import ClassroomAnalytics from 'view/components/teacher/analytics/ClassroomAnalytics';
import SubmissionAnalytics from 'view/components/teacher/analytics/SubmissionAnalytics';
import StudentAnalytics from 'view/components/teacher/analytics/student/StudentAnalytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClipboardList,
  faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';

import 'view/style/teacher/analytics.css';

export const defaultMissedWordChartData = [
  {
    label: 'Known',
    data: [],
    backgroundColor: 'rgba(68, 125, 0, 1)',
  },
  {
    label: 'Unknown',
    data: [],
    backgroundColor: 'rgba(177, 36, 63, 1)',
  },
];

export type ChartData = {
  label: string;
  data: number[];
  backgroundColor: string;
};

enum AnaltyicsType {
  CLASSROOM = 'classroom',
  ASSIGNMENT = 'assignment',
  STUDENT = 'student',
  SUBMISSION = 'studentAssignment',
}

function countMissedWords(
  data?: ServerMissedWords,
  studentName?: string,
  assignmentId?: string,
) {
  console.debug(
    'countMissedWords invoked with ',
    studentName,
    ' ',
    assignmentId,
  );
  const result: DisplayMissedWord[] = [];

  if (!data) return result;

  Object.entries(data).forEach(([student, assignments]) => {
    if (!studentName || student === studentName) {
      Object.entries(assignments).forEach(([assignment, words]) => {
        if (!assignmentId || Number(assignment) === Number(assignmentId)) {
          words.forEach((wordObj) => {
            const { word, known, isKeyWord } = wordObj;
            const wordKey = word.toLowerCase();
            // see if a DisplayMissedWord with this word already exists in the result
            let wordIdx = result.findIndex((dmw) => dmw.word === wordKey);
            if (wordIdx === -1) {
              result.push({
                word: wordKey,
                missed: 1,
                unknown: Number(!known),
                isKeyWord: isKeyWord,
              });
            } else {
              result[wordIdx].missed++;
              result[wordIdx].unknown += Number(!known);
              result[wordIdx].isKeyWord =
                result[wordIdx].isKeyWord || isKeyWord;
            }
          });
        }
      });
    }
  });

  // sort in descending order based on missed attribute
  const resultSorted = result.sort((dmw1, dmw2) => dmw2.missed - dmw1.missed);
  return resultSorted;
}

export const AnalyticsPage: React.FC = () => {
  const { classroom } = useClassroomContext();
  const [selectedAssignment, setSelectedAssignment] = useState<Assignment>();
  const [selectedStudent, setSelectedStudent] =
    useState<TeacherGradebookEntry>();
  const [gradebook, setGradebook] = useState<TeacherGradebook>();
  const [missedWords, setMissedWords] = useState<ServerMissedWords>();
  const [averages, setAverages] = useState<Record<string, any>>();
  const [showPdfPopup, setShowPdfPopup] = useState<boolean>(false);
  const [analyticsType, setAnalyticsType] = useState<AnaltyicsType>(
    AnaltyicsType.CLASSROOM,
  );
  const pageContent = contentService.getTeacherAnalyticsPage();
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (gradebook || !classroom) return;
    const cachedGradebook = teacherService.getCachedGradebook();
    if (cachedGradebook) setGradebook(cachedGradebook);
    else {
      makeApiCall(teacherService.getGradebook, classroom.id)
        .then((respGradebook) => setGradebook(respGradebook))
        .catch((error) => alert(error.message));
    }
  }, [gradebook, classroom, makeApiCall]);

  useEffect(() => {
    if (missedWords || !classroom) return;
    makeApiCall(teacherService.getClassroomMissedWords, classroom)
      .then((respWords) => setMissedWords(respWords))
      .catch((error) => alert(error.message));
  }, [missedWords, classroom, makeApiCall]);

  useEffect(() => {
    var newAnalyticsType;
    if (selectedAssignment && selectedStudent) {
      newAnalyticsType = AnaltyicsType.SUBMISSION;
    } else if (selectedAssignment) {
      newAnalyticsType = AnaltyicsType.ASSIGNMENT;
    } else if (selectedStudent) {
      newAnalyticsType = AnaltyicsType.STUDENT;
    } else {
      newAnalyticsType = AnaltyicsType.CLASSROOM;
    }
    setAnalyticsType(newAnalyticsType);

    if (newAnalyticsType === AnaltyicsType.CLASSROOM) {
      setAverages({
        grade: gradebook?.averages.grade,
        completion_score: gradebook?.averages.completion_score,
        correctness_score: gradebook?.averages.correctness_score,
        key_word_accuracy_score: gradebook?.averages.key_word_accuracy_score,
        completion_time: gradebook?.averages.completion_time,
        missed_words: 0,
      });
    } else if (newAnalyticsType === AnaltyicsType.ASSIGNMENT) {
      setAverages({
        grade:
          gradebook?.averages.assignments[selectedAssignment!.getId()].grade,
        completion_score:
          gradebook?.averages.assignments[selectedAssignment!.getId()]
            .completion_score,
        correctness_score:
          gradebook?.averages.assignments[selectedAssignment!.getId()]
            .correctness_score,
        key_word_accuracy_score:
          gradebook?.averages.assignments[selectedAssignment!.getId()]
            .key_word_accuracy_score,
        completion_time:
          gradebook?.averages.assignments[selectedAssignment!.getId()]
            .completion_time,
        missed_words: 0,
      });
    } else if (newAnalyticsType === AnaltyicsType.STUDENT) {
      setAverages({
        grade: selectedStudent!.averages.grade,
        completion_score: selectedStudent!.averages.completion_score,
        correctness_score: selectedStudent!.averages.correctness_score,
        key_word_accuracy_score:
          selectedStudent!.averages.key_word_accuracy_score,
        completion_time: selectedStudent!.averages.completion_time,
        missed_words: 0,
      });
    } else {
      const idx = selectedStudent?.assignment_submissions.findIndex(
        (sa) => sa.assignment === selectedAssignment?.getId(),
      );
      if (idx === -1 || !idx) return;
      setAverages({
        grade: selectedStudent?.assignment_submissions[idx].grade,
        completion_score:
          selectedStudent?.assignment_submissions[idx].completion_score,
        correctness_score:
          selectedStudent?.assignment_submissions[idx].correctness_score,
        key_word_accuracy_score:
          selectedStudent?.assignment_submissions[idx].key_word_accuracy_score,
        completion_time:
          selectedStudent?.assignment_submissions[idx].completion_time,
        missed_words: 0,
      });
    }
  }, [selectedAssignment, selectedStudent, gradebook]);

  const displayMissedWords: DisplayMissedWord[] = useMemo(
    () =>
      countMissedWords(
        missedWords,
        selectedStudent?.username,
        selectedAssignment?.id,
      ),
    [missedWords, selectedStudent, selectedAssignment],
  );

  const handleAssignmentSelectChange = (e: any) => {
    if (!gradebook) return;
    const selectedValue = e.target.value;

    if (selectedValue === 'all') {
      setSelectedAssignment(undefined);
    } else {
      const selectedAssignment = gradebook.assignments?.find(
        (assignment) => assignment.title === selectedValue,
      );
      setSelectedAssignment(selectedAssignment);
    }
  };

  const handleStudentSelectChange = (e: any) => {
    const selectedValue = e.target.value;

    if (selectedValue === 'all') {
      setSelectedStudent(undefined);
    } else {
      const selectedStudent = gradebook?.students.find(
        (student) => student.username === selectedValue,
      );
      setSelectedStudent(selectedStudent);
    }
  };

  const handleExitAnalytics = () => {
    navigate(`/teacher/classroom/${classroom?.getId()}`);
  };

  if (!gradebook) return <AppPage></AppPage>;

  return (
    <AppPage>
      <div className="app-page-content">
        <AppSidebar onExit={handleExitAnalytics}>
          <>
            <div className="sidebar-text">
              <FontAwesomeIcon icon={faClipboardList} />
              <label className="label-normal">
                {pageContent.sidebar.assignments.label}
              </label>
            </div>
            <select onChange={handleAssignmentSelectChange}>
              <option value="all" id="analytics-assignment-all">
                {pageContent.sidebar.assignments.dropdown.default}
              </option>
              {gradebook.assignments?.map((assignment) => (
                <option
                  value={assignment.title}
                  id={`analytics-assignment-${assignment.getId()}`}
                  key={`analytics-assignment-${assignment.getId()}`}
                >
                  {assignment.title}
                </option>
              ))}
            </select>
            <div className="sidebar-text">
              <FontAwesomeIcon icon={faGraduationCap} />
              <label className="label-normal">
                {pageContent.sidebar.students.label}
              </label>
            </div>
            <select onChange={handleStudentSelectChange}>
              <option value="all" id="analytics-student-all">
                {pageContent.sidebar.students.dropdown.default}
              </option>
              {gradebook?.students.map((student) => (
                <option
                  value={student.username}
                  id={`analytics-student-${student.username}`}
                  key={`analytics-student-${student.username}`}
                >
                  {student.displayName()}
                </option>
              ))}
            </select>
            <div className="assignment-detail-header">
              <label className="label-medium">
                {pageContent.sidebar.details.completion.title}
              </label>
            </div>
            <div className="assignment-details-list">
              <div className="assignment-detail">
                <label className="label-small">
                  {pageContent.sidebar.details.completion.attributes[0].label}
                </label>
                <label className="label-small">
                  {averages?.completion_score}%
                </label>
              </div>
              <div className="assignment-detail">
                <label className="label-small">
                  {pageContent.sidebar.details.completion.attributes[1].label}
                </label>
                <label className="label-small">{averages?.grade}%</label>
              </div>
              <div className="assignment-detail">
                <label className="label-small">
                  {pageContent.sidebar.details.completion.attributes[2].label}
                </label>
                <label className="label-small">
                  {formatSeconds(averages?.completion_time)}
                </label>
              </div>
            </div>
            <div className="assignment-detail-header">
              <label className="label-medium">
                {pageContent.sidebar.details.accuracy.title}
              </label>
            </div>
            <div className="assignment-details-list">
              <div className="assignment-detail">
                <label className="label-small">
                  {pageContent.sidebar.details.accuracy.attributes[0].label}
                </label>
                <label className="label-small">
                  {averages?.correctness_score}%
                </label>
              </div>
              <div className="assignment-detail">
                <label className="label-small">
                  {pageContent.sidebar.details.accuracy.attributes[1].label}
                </label>
                <label className="label-small">
                  {averages?.key_word_accuracy_score}%
                </label>
              </div>
              <div className="assignment-detail">
                <label className="label-small">
                  {pageContent.sidebar.details.accuracy.attributes[2].label}
                </label>
                <label className="label-small">
                  {displayMissedWords && Object.keys(displayMissedWords).length}
                </label>
              </div>
            </div>
            <ActionButton
              label="Download PDF Report"
              onClick={() => setShowPdfPopup(true)}
            />
          </>
        </AppSidebar>
        <div className="app-main-content">
          <h1>
            {classroom?.name} | {pageContent.content.title}
          </h1>
          {analyticsType === AnaltyicsType.CLASSROOM && (
            <ClassroomAnalytics
              missedWords={displayMissedWords ?? []}
              gradebook={gradebook}
            />
          )}
          {analyticsType === AnaltyicsType.ASSIGNMENT && (
            <AssignmentAnayltics
              missedWords={displayMissedWords ?? []}
              assignment={selectedAssignment!}
              students={gradebook.students}
            />
          )}
          {analyticsType === AnaltyicsType.STUDENT && (
            <StudentAnalytics
              gradebook={gradebook}
              student={selectedStudent!}
              missedWords={displayMissedWords ?? []}
            />
          )}
          {analyticsType === AnaltyicsType.SUBMISSION && (
            <SubmissionAnalytics missedWords={displayMissedWords ?? []} />
          )}
        </div>
      </div>
      <Modal
        isOpen={showPdfPopup}
        onClose={() => setShowPdfPopup((prev) => !prev)}
      >
        <GeneratePdfPopup gradebook={gradebook} />
      </Modal>
    </AppPage>
  );
};
