import Book from 'models/Book';
import User from 'models/User';
import { parseError } from 'utils/utils';
import apiService from 'services/apiService';
import CachingEngine from 'utils/CachingEngine';

export class CatalogService {
  private static instance: CatalogService;

  public static get(): CatalogService {
    if (!CatalogService.instance) {
      CatalogService.instance = new CatalogService();
    }

    return CatalogService.instance;
  }

  getCatalogBooks = async (
    next?: string | null,
    searchParams?: Record<string, string | string[]>,
  ): Promise<{ books: Book[]; next: string | null }> => {
    try {
      // Fetch any cached data (this example doesn't differentiate filters).
      const cachedData = await CachingEngine.getData('catalog');
      const cachedBooks =
        cachedData?.map((b: Book) => Book.fromServerBook(b)) ?? [];

      // If we have cached data, no "next" link, and no new search params,
      // return the cached results.
      if (cachedBooks.length > 0 && !next && !searchParams) {
        return {
          books: cachedBooks,
          next: null,
        };
      }

      // Build the request URL and config
      const url = next ?? '/catalog/books/';
      const config = searchParams ? { params: searchParams } : {};

      // Fetch from the server
      const response = await apiService.api.get(url, config);

      // Convert the incoming data to Book objects
      const newBooks: Book[] = response.data.results.map(
        (respBook: Record<string, any>) => Book.fromServerBook(respBook),
      );

      // Combine old + new
      const combinedBooks = [...cachedBooks, ...newBooks];

      // Save merged results back to cache
      await CachingEngine.setData('catalog', combinedBooks);

      // Return the newly fetched slice + next link
      return {
        books: newBooks,
        next: response.data.next,
      };
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getLikedBooks = async (
    next?: string | null,
    searchParams?: Record<string, string | string[]>,
  ): Promise<{ books: Book[]; next: string | null }> => {
    try {
      // Fetch any cached data (this example doesn't differentiate filters).
      const cachedData = await CachingEngine.getData('catalog-likes');
      const cachedBooks =
        cachedData?.map((b: Book) => Book.fromServerBook(b)) ?? [];

      // If we have cached data, no "next" link, and no new search params,
      // return the cached results.
      if (cachedBooks.length > 0 && !next && !searchParams) {
        return {
          books: cachedBooks,
          next: null,
        };
      }

      // Build the request URL and config
      const url = next ?? '/catalog/liked_books/';
      const config = searchParams ? { params: searchParams } : {};

      // Fetch from the server
      const response = await apiService.api.get(url, config);

      // Convert the incoming data to Book objects
      const newBooks: Book[] = response.data.results.map(
        (respBook: Record<string, any>) => Book.fromServerBook(respBook),
      );

      // Combine old + new
      const combinedBooks = [...cachedBooks, ...newBooks];

      // Save merged results back to cache
      await CachingEngine.setData('catalog-likes', combinedBooks);

      // Return the newly fetched slice + next link
      return {
        books: newBooks,
        next: response.data.next,
      };
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  addLikedBook = async (
    user: User,
    book: Book,
    liked: boolean,
  ): Promise<Record<string, any>> => {
    try {
      const response = await apiService.api.post(
        `/liked_books/${liked ? '' : 'remove/'}`,
        {
          user: user.getId(),
          book: book.id,
        },
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getCatalogAssignments = async (
    assignmentType: string,
    next?: string | null,
    searchParams?: Record<string, string | string[]>,
  ): Promise<Record<string, any>> => {
    try {
      const config = searchParams ? { params: searchParams } : {};
      var url = next ?? `/catalog/assignments/?type=${assignmentType}`;
      const response = await apiService.api.get(url, config);
      return {
        assignments: response.data.results,
        next: response.data.next,
      };
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };
}

export const catalogService = CatalogService.get();
