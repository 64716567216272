import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConfirmation } from 'contexts/ConfirmationContext';
import React from 'react';
import { ButtonType } from 'utils/types';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';

import 'view/style/common/buttons.css';

const FILL_COLORS: Record<string, string> = {
  go: '#BAFC95',
  delete: '#FC95A8',
  // option: '#FCF095',
  default: '#F0EFED',
  option: 'var(--light-purple)',
  transparent: 'rgba(0, 0, 0, 0)',
};

interface ButtonProps {
  type?: ButtonType;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  text?: string;
  label: string;
  leadIcon?: string;
  trailIcon?: string;
  buttonType?: 'button' | 'submit' | 'reset';
  requiresConfirmation?: boolean;
  confirmationMessage?: string;
  fontAwesomeIcon?: IconDefinition;
  shake?: boolean;
  hidden?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  type,
  onClick,
  text,
  label,
  leadIcon,
  trailIcon,
  buttonType,
  fontAwesomeIcon,
  requiresConfirmation = false,
  confirmationMessage = '',
  shake = false,
  hidden = false,
}) => {
  const { openModal } = useConfirmation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!onClick) return;
    if (requiresConfirmation) {
      event.preventDefault();
      event.stopPropagation();
      openModal(confirmationMessage, onClick);
    } else {
      onClick(event);
    }
  };

  const fill_color = type ? FILL_COLORS[type] : FILL_COLORS['none'];

  return (
    <div className={`app-button ${shake ? ' shake' : ''}`} hidden={hidden}>
      <button
        className="button-content"
        onClick={handleClick}
        style={{ background: fill_color }}
        type={buttonType}
      >
        {leadIcon && <img src={leadIcon} alt="" />}
        {fontAwesomeIcon && <FontAwesomeIcon icon={fontAwesomeIcon} />}
        <span className="label-large">{label}</span>
        {trailIcon && <img src={trailIcon} alt="" />}
      </button>
      {text && <AudioAssistanceButton text={text} />}
    </div>
  );
};

export default Button;
