import React, { useEffect, useState } from 'react';
import { useUserContext } from 'contexts/UserContext';
import Classroom from 'models/Classroom';
import userService from 'services/userService';
import Modal from 'view/components/common/Modal';
import studentService from 'services/studentService';
import { useNavigate } from 'react-router-dom';
import { useClassroomContext } from 'contexts/ClassroomContext';
import { JoinClassroomForm } from 'view/components/student/JoinClassroomForm';
import { ClassroomList } from 'view/components/common/ClassroomList';
import { AppPage } from 'view/components/common/AppPage';
import useApiCall from 'contexts/ApiCall';
import AppSidebar from 'view/components/common/Sidebar';
import User from 'models/User';
import contentService from 'services/contentService';
import ScreenReader from 'utils/ScreenReader';
import { SpeechRecognitionService } from 'services/speechRecognitionService';
import { faUniversity } from '@fortawesome/free-solid-svg-icons';
import HeaderRowButton from 'view/components/buttons/HeaderButton';

export const StudentHomePage: React.FC = () => {
  const { user } = useUserContext();
  const { setClassroom } = useClassroomContext();
  const [classrooms, setClassrooms] = useState<Classroom[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const pageContent = contentService.getStudentHomePage();
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;
    makeApiCall(userService.getUserClassrooms, user)
      .then((respClassrooms) => setClassrooms(respClassrooms))
      .catch((error) => alert(error.message));
  }, [user, makeApiCall]);

  const handleJoinClassroomClose = () => {
    setModalOpen(false);
    if (!user) return;
    makeApiCall(userService.getUserClassrooms, user)
      .then((respClassrooms) => setClassrooms(respClassrooms))
      .catch((error) => alert(error.message));
  };

  const joinClassroomHandler = (classroom_id: string, access_code: string) => {
    if (!user) return;
    makeApiCall(
      studentService.joinClassroom,
      user.getId(),
      classroom_id,
      access_code,
    )
      .then((respClassroom) =>
        setClassrooms((prev) => [...prev, respClassroom]),
      )
      .catch((error) => alert(error.message));
  };

  const enterClassroomHandler = (classroom: Classroom) => {
    ScreenReader.setLanguage(classroom.language);
    SpeechRecognitionService.setLanguage(classroom.language);
    setClassroom(classroom);
    navigate(`/student/classroom/${classroom.id}`);
  };

  return (
    <AppPage>
      <div className="app-page-content">
        <AppSidebar user={user as User} />
        <div className="app-main-content">
          <div className="row spaced" style={{ paddingBottom: '20px' }}>
            <h1>{pageContent.content.title}</h1>
            <HeaderRowButton
              label="Academy"
              icon={faUniversity}
              onClick={() => navigate('/student/academy')}
            />
          </div>
          <ClassroomList
            classrooms={classrooms}
            onEnterClassroom={enterClassroomHandler}
            openAddClassroomForm={setModalOpen}
          />
        </div>
        <Modal isOpen={modalOpen} onClose={handleJoinClassroomClose}>
          <JoinClassroomForm joinClassroomHandler={joinClassroomHandler} />
        </Modal>
      </div>
    </AppPage>
  );
};
