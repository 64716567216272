import useApiCall from 'contexts/ApiCall';
import MissedWord from 'models/MissedWord';
import React, { useEffect, useState } from 'react';
import dictionaryService from 'services/dictionaryService';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import IconButton from 'view/components/buttons/IconButton';
import HorizontalCarousel from 'view/components/common/HorizontalCarousel';
import WordDefinition from '../WordDefinition';
import { DictionaryDefinition } from 'utils/types';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

interface FlashcardPracticeProps {
  missedWords: MissedWord[];
  onProgress?: () => void;
  onCompletion?: () => void;
}

const FlashcardPractice: React.FC<FlashcardPracticeProps> = ({
  missedWords,
  onProgress,
  onCompletion,
}) => {
  const [flashcards, setFlashcards] = useState<JSX.Element[]>([]);

  useEffect(() => {
    setFlashcards(
      missedWords.map((mw) => (
        <Flashcard key={`flashcard-${mw.word}`} word={mw.word} />
      )),
    );
  }, [missedWords]);

  return (
    <div className="practice-modal">
      <HorizontalCarousel pages={flashcards} onSubmit={onCompletion} />
    </div>
  );
};

export default FlashcardPractice;

interface FlashcardProps {
  word: string;
}

const Flashcard: React.FC<FlashcardProps> = ({ word }) => {
  const [definition, setDefinition] = useState<DictionaryDefinition>();
  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const makeApiCall = useApiCall();

  useEffect(() => {
    makeApiCall(dictionaryService.getWordDefinition, word)
      .then((respDef) => setDefinition(respDef ?? {}))
      .catch((error) => console.error(error.message));
  }, [word, makeApiCall]);

  const handleFlipFlashcard = () => {
    setIsFlipped((prev) => !prev);
  };

  return (
    <div className="flashcard">
      <div className="row">
        <label className="label-xl">
          {word +
            `${
              !definition?.translation || definition.translation === ''
                ? ''
                : ' (' + definition.translation + ')'
            }`}
        </label>
        <AudioAssistanceButton text={word} audioSrc={definition?.audio_url} />
      </div>
      <span className="book-text">{definition?.ipa ?? word}</span>
      <IconButton icon={faSyncAlt} onClick={handleFlipFlashcard} />
      {isFlipped && <WordDefinition definition={definition} />}
    </div>
  );
};
