import React, { useMemo } from 'react';

import 'view/style/common/components/wordList.css';
import { ICONS } from './CatalogIcons';

interface CatalogTagsProps {
  tags: string[];
}

export const CatalogTags: React.FC<CatalogTagsProps> = ({ tags }) => {
  return (
    <div className="word-list">
      {tags.map((tag) => (
        <CatalogTag tag={tag} key={`cat-tag-${tag}`} />
      ))}
    </div>
  );
};

interface CatalogTagProps {
  tag: string;
}

const CatalogTag: React.FC<CatalogTagProps> = ({ tag }) => {
  const icon: JSX.Element = useMemo(
    () => ICONS[tag] ?? ICONS['Default'],
    [tag],
  );

  return (
    <div key={`tags-list-${tag}`} className="word">
      {icon}
      <span className="label-xs">{tag}</span>
    </div>
  );
};
