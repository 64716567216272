import {
  faArrowRight,
  faBook,
  faBookOpen,
  faCheckCircle,
  faClone,
  faEarListen,
  faExclamationCircle,
  faListUl,
  faMicrophone,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StudentGradebookEntry from 'models/StudentGradebookEntry';
import React from 'react';
import ItemPanel from 'view/components/common/ItemPanel';
import StatusIcon from 'view/components/common/StatusIcon';

type DisplayMapping = {
  label: string;
  color?: string;
  icon?: IconDefinition;
};

const asgnDisplay: Record<string, DisplayMapping> = {
  Flashcards: { label: 'Flashcards', color: 'var(--purple)', icon: faClone },
  Pronounce: { label: 'Flashcards', color: 'var(--purple)', icon: faClone },
  Listening: {
    label: 'Listening assessment',
    color: 'var(--dark-blue)',
    icon: faEarListen,
  },
  'Read-Aloud': {
    label: 'Listening',
    color: 'var(--dark-blue)',
    icon: faEarListen,
  },
  Default: { label: 'Reading', color: 'var(--dark-green)', icon: faBook },
  Speaking: {
    label: 'Speaking assessment',
    color: 'var(--dark-green)',
    icon: faMicrophone,
  },
  'Multi-Part': { label: 'Multipart', color: 'var(--purple)', icon: faListUl },
  late: {
    label: 'Overdue',
    color: 'var(--dark-pink)',
    icon: faExclamationCircle,
  },
  todo: { label: 'Open', color: 'var(--dark-green)', icon: faBookOpen },
  done: { label: 'Done', color: 'var(--gray-4)', icon: faCheckCircle },
};

type AssignmentListType = 'late' | 'todo' | 'done';

interface AssignmentListProps {
  assignments?: StudentGradebookEntry[];
  selected?: StudentGradebookEntry;
  type: AssignmentListType;
  onSelect: (assignment: StudentGradebookEntry) => void;
}

const AssignmentList: React.FC<AssignmentListProps> = ({
  assignments = [],
  selected,
  type,
  onSelect,
}) => {
  return (
    <>
      {assignments.map((entry) => (
        <ItemPanel
          key={`gradebook-entry-${entry.assignment_submission.getId()}`}
          id={`gradebook-entry-${entry.assignment_submission.getId()}`}
          selected={entry === selected}
          onClick={() => onSelect(entry)}
          onDoubleClick={() => onSelect(entry)}
        >
          <div className="row">
            <label className="label-normal">{entry.assignment.title}</label>
            <StatusIcon
              icon={asgnDisplay[entry.assignment.assignment_type].icon!}
              color={asgnDisplay[entry.assignment.assignment_type].color!}
              text={asgnDisplay[entry.assignment.assignment_type].label}
            />
          </div>
          <div className="row">
            <StatusIcon
              icon={asgnDisplay[type].icon!}
              color={asgnDisplay[type].color!}
              text={asgnDisplay[type].label}
            />
            {entry === selected && (
              <>
                {'Select'}
                <FontAwesomeIcon icon={faArrowRight} size="xl" />
              </>
            )}
          </div>
        </ItemPanel>
      ))}
    </>
  );
};

export default AssignmentList;
