import React, { useState } from 'react';
import { WordList } from './WordList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';

type WordAdderProps = {
  words: string[];
  onAddWord: (word: string) => void;
  onDeleteWord: (word: string) => void;
};

const WordAdder: React.FC<WordAdderProps> = ({
  words,
  onAddWord,
  onDeleteWord,
}) => {
  const [nextWord, setNextWord] = useState<string>('');

  const handleAddWord = () => {
    setNextWord('');
    onAddWord(nextWord);
  };

  return (
    <>
      <div className="row stretch">
        <input
          type="text"
          placeholder="Enter a word..."
          value={nextWord}
          onChange={(event) => setNextWord(event.target.value)}
        />
        <FontAwesomeIcon
          icon={faPlusSquare}
          onClick={handleAddWord}
          size="lg"
        />
      </div>
      <WordList words={Array.from(words)} onDeleteWord={onDeleteWord} />
    </>
  );
};

export default WordAdder;
