import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type HeaderRowButtonProps = {
  icon?: IconDefinition;
  label: string;
  onClick: () => void;
  color?: string;
};

const HeaderRowButton: React.FC<HeaderRowButtonProps> = ({
  icon,
  label,
  color,
  onClick,
}) => {
  return (
    <div className="header-row-button" onClick={onClick}>
      {icon && <FontAwesomeIcon icon={icon} size="1x" />}
      <span className="label-normal">{label}</span>
    </div>
  );
};

export default HeaderRowButton;
