import MissedWord from '../models/MissedWord';

export type ServerMissedWords = Record<string, Record<string, MissedWord[]>>;

export interface Subscription {
  id: string;
  code: string;
  name: string;
  duration: any;
  type?: string;
  price: number;
  max_users?: number;
  num_users: number;
  start_date: any;
}

export interface SignupFormData {
  account_type: string;
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  email: string;
  subscription?: Subscription;
  clever_id?: string;
  subscription_code?: string;
  district_clever_id?: string;
}

export interface SubscriptionFormData {
  subscription?: Subscription;
  subscription_code?: string;
}

export type ButtonType =
  | 'edit'
  | 'delete'
  | 'go'
  | 'default'
  | 'transparent'
  | 'option';

export class TeacherGeneratedStudent {
  username: string = '';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  readingLevel: number = 1;
  password: string = '';
  clever_id?: string;
}

export type DisplayMissedWord = {
  word: string;
  missed: number;
  unknown: number;
  isKeyWord: boolean;
};

export enum SpeechMode {
  Easy = 1,
  Close = 2,
  Strict = 3,
}

export type Language = {
  name: string;
  code: string;
};

export type DictionaryDefinition = {
  translation?: string;
  definition: string;
  ipa: string;
  synonyms: string[];
  antonyms: string[];
  audio_url?: string;
};

export enum DefinitionType {
  DEFINITION = 'DEFINITION',
  SYNONYM = 'SYNONYM',
  ANTONYM = 'ANTONYM',
}

export enum QuestionType {
  SHORTANSWER = 'text',
  MULTIPLECHOICE = 'choice',
  CONVERSATION = 'conversation',
  READALOUD = 'readAloud',
  TRANSCRIBE = 'transcribe',
  LISTENING = 'listening',
  FILLINBLANK = 'fillInBlank',
  RESPOND = 'respond',
}

export enum LearnerLevel {
  BEGINNER = 'Young Learner',
  INTERMEDIATE = 'Intermediate',
  ADVANCED = 'Advanced',
  PROFESSIONAL = 'Professional',
}

export enum Topic {
  // History Topics
  HISTORY = 'History',
  US_HISTORY = 'US History',
  ROMAN_HISTORY = 'Roman History',
  EUROPEAN_HISTORY = 'European History',
  WORLD_HISTORY = 'World History',
  SPANISH_HISTORY = 'Spanish History',
  FRENCH_HISTORY = 'French History',
  GERMAN_HISTORY = 'German History',
  CHINESE_HISTORY = 'Chinese History',
  JAPANESE_HISTORY = 'Japanese History',
  ENGLISH_HISTORY = 'English History',

  // Science Topics
  BIOLOGY = 'Biology',
  MICROBIOLOGY = 'Microbiology',
  GENETICS = 'Genetics',
  BIOCHEMISTRY = 'Biochemistry',
  ECOLOGY = 'Ecology',
  ZOOLOGY = 'Zoology',
  BOTANY = 'Botany',
  CHEMISTRY = 'Chemistry',
  ORGANIC_CHEMISTRY = 'Organic Chemistry',
  INORGANIC_CHEMISTRY = 'Inorganic Chemistry',
  PHYSICAL_CHEMISTRY = 'Physical Chemistry',
  PHYSICS = 'Physics',
  ASTRONOMY = 'Astronomy',
  GEOLOGY = 'Geology',
  EARTH_SCIENCE = 'Earth Science',
  ENVIRONMENTAL_SCIENCE = 'Environmental Science',

  // Mathematics Topics
  MATHEMATICS = 'Mathematics',
  ALGEBRA = 'Algebra',
  GEOMETRY = 'Geometry',
  CALCULUS = 'Calculus',
  STATISTICS = 'Statistics',
  PROBABILITY = 'Probability',
  DISCRETE_MATHEMATICS = 'Discrete Mathematics',
  LINEAR_ALGEBRA = 'Linear Algebra',
  DIFFERENTIAL_EQUATIONS = 'Differential Equations',

  // Literature & Language Topics
  LITERATURE = 'Literature',
  WORLD_LITERATURE = 'World Literature',
  CREATIVE_WRITING = 'Creative Writing',
  LANGUAGE_ARTS = 'Language Arts',
  FOREIGN_LANGUAGES = 'Foreign Languages',

  // Social Studies & Humanities
  SOCIOLOGY = 'Sociology',
  PSYCHOLOGY = 'Psychology',
  PHILOSOPHY = 'Philosophy',
  ECONOMICS = 'Economics',
  POLITICAL_SCIENCE = 'Political Science',
  ANTHROPOLOGY = 'Anthropology',
  EDUCATION = 'Education',
  GEOGRAPHY = 'Geography',
  RELIGION = 'Religion',

  // Computer Science & Technology Topics
  COMPUTER_SCIENCE = 'Computer Science',
  PROGRAMMING = 'Programming',
  DATA_SCIENCE = 'Data Science',
  ARTIFICIAL_INTELLIGENCE = 'Artificial Intelligence',
  MACHINE_LEARNING = 'Machine Learning',
  CYBERSECURITY = 'Cybersecurity',
  SOFTWARE_ENGINEERING = 'Software Engineering',
  WEB_DEVELOPMENT = 'Web Development',

  // Arts Topics
  VISUAL_ARTS = 'Visual Arts',
  MUSIC = 'Music',
  THEATER = 'Theater',
  DANCE = 'Dance',

  // Business & Economics Topics
  BUSINESS = 'Business',
  FINANCE = 'Finance',
  MARKETING = 'Marketing',
  MANAGEMENT = 'Management',
  ACCOUNTING = 'Accounting',
  ENTREPRENEURSHIP = 'Entrepreneurship',

  // Health & Medicine Topics
  MEDICINE = 'Medicine',
  NURSING = 'Nursing',
  PHARMACOLOGY = 'Pharmacology',
  PUBLIC_HEALTH = 'Public Health',

  // Law & Government Topics
  LAW = 'Law',
  INTERNATIONAL_RELATIONS = 'International Relations',

  // Engineering Topics
  ENGINEERING = 'Engineering',
  MECHANICAL_ENGINEERING = 'Mechanical Engineering',
  ELECTRICAL_ENGINEERING = 'Electrical Engineering',
  CIVIL_ENGINEERING = 'Civil Engineering',
  CHEMICAL_ENGINEERING = 'Chemical Engineering',

  // Miscellaneous Topics
  ENVIRONMENTAL_STUDIES = 'Environmental Studies',
  ECONOMETRICS = 'Econometrics',
  SPORTS = 'Sports',
}
