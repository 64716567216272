import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useClassroomContext } from 'contexts/ClassroomContext';
import Assignment from 'models/Assignment';
import Book from 'models/Book';
import teacherService from 'services/teacherService';
import Question from 'models/Question';
import { AppPage } from 'view/components/common/AppPage';
import { extractTextFromFiles } from 'utils/fileUtils';
import useApiCall from 'contexts/ApiCall';
import BaseAssignmentForm from 'view/components/teacher/assignment/forms/Assignment';
import { QuestionEditor } from 'view/components/teacher/assignment/QuestionEditor';
import Modal from 'view/components/common/Modal';
import ListeningAssignmentForm from 'view/components/teacher/assignment/forms/Listening';
import SpeakingAssignmentForm from 'view/components/teacher/assignment/forms/Speaking';
import { useGradebook } from 'contexts/TeacherGradebookContext';
import MultiPartAssignmentForm from 'view/components/teacher/assignment/newcomers/Multipart';
import PracticeAssignmentForm from 'view/components/teacher/assignment/forms/Practice';
import Classroom from 'models/Classroom';
import { QuestionType } from 'utils/types';
import MultipleChoiceOption from 'models/MultipleChoiceOption';
import aiService from 'services/aiService';
import { useUserContext } from 'contexts/UserContext';
import CachingEngine from 'utils/CachingEngine';

import 'view/style/teacher/assignment.css';

export const TeacherAssignmentPage: React.FC = () => {
  const { user } = useUserContext();
  const { classroom } = useClassroomContext();
  const { gradebook, setGradebook } = useGradebook();
  const [assignment, setAssignment] = useState<Assignment>();
  const [book, setBook] = useState<Book>();
  const [editQuestions, setEditQuestions] = useState<boolean>(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [isAutoGenerateAllowed, setIsAutoGenerateAllowed] =
    useState<boolean>(true);
  const [coverImageFile, setCoverImageFile] = useState<File>();
  const [coverImage, setCoverImage] = useState<string>();
  const { assignmentId, assignmentType } = useParams();
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (!classroom || assignmentId) return;
    if (!assignment) {
      CachingEngine.getData('assignment').then((resp) => {
        if (resp) {
          const cachedAssignment = Assignment.fromAssignment(resp);
          cachedAssignment.due_date = undefined;
          cachedAssignment.start_date = undefined;
          cachedAssignment.classroom = classroom.id;
          cachedAssignment.district = user?.district?.id;
          setAssignment(cachedAssignment);
        } else {
          setAssignment(Assignment.generateDefault(classroom, assignmentType));
        }
      });
    }
    if (!book) {
      CachingEngine.getData('book').then((resp) => {
        if (resp) {
          const cachedBook = Book.fromBook(resp);
          cachedBook.is_public = false;
          cachedBook.is_under_review = false;
          setBook(cachedBook);
        } else {
          setBook(Book.generateDefault(classroom, user?.username));
        }
      });
    }
  }, [
    assignmentId,
    assignment,
    book,
    classroom,
    assignmentType,
    user?.username,
    user?.district?.id,
  ]);

  useEffect(() => {
    if (assignment) return;

    Promise.all([
      CachingEngine.getData('book'),
      CachingEngine.getData('assignment'),
      CachingEngine.getData('questions'),
    ]).then(([cachedBook, cachedAssignment, cachedQuestions]) => {
      if (cachedBook) setBook(cachedBook);
      if (cachedAssignment) setAssignment(cachedAssignment);
      if (cachedQuestions) setQuestions(cachedQuestions);

      if (assignmentId && !cachedAssignment) {
        makeApiCall(teacherService.getAssignmentById, assignmentId)
          .then(async (resp) => {
            setAssignment(resp.assignment);
            setBook(resp.book);
            setQuestions(
              resp.questions.sort(
                (a: Question, b: Question) => a.index - b.index,
              ),
            );
            CachingEngine.setData('assignment', resp.assignment.toJSON());
            CachingEngine.setData('book', resp.book.toJSON());
            CachingEngine.setData('questions', resp.questions.toJSON());
          })
          .catch((error) => alert(error.message));
      }
    });
  }, [assignmentId, assignment, makeApiCall]);

  useEffect(() => {
    if (gradebook || !classroom) return;
    const cachedGradebook = teacherService.getCachedGradebook();
    if (cachedGradebook) setGradebook(cachedGradebook);
    else {
      makeApiCall(teacherService.getGradebook, classroom.id)
        .then((respGradebook) => setGradebook(respGradebook))
        .catch((error) => alert(error.message));
    }
  }, [gradebook, classroom, setGradebook, makeApiCall]);

  const handleCancel = () => {
    if (!classroom) return;
    CachingEngine.deleteData('assignment');
    CachingEngine.deleteData('book');
    CachingEngine.deleteData('questions');
    window.history.back();
  };

  const updateAssignment = (event: any) => {
    if (!assignment || !classroom) return;
    var value = event.target.value;
    if (event.target.id === 'min_reading_level') {
      value = Math.min(
        Math.max(classroom.min_reading_level, value),
        assignment.max_reading_level,
      );
    } else if (event.target.id === 'max_reading_level') {
      value = Math.max(
        Math.min(classroom.max_reading_level, value),
        assignment.min_reading_level,
      );
    }

    setAssignment((prevAssignment: any) => {
      const updatedAssignment = Assignment.fromServerAssignment({
        ...prevAssignment,
        [event.target.id]: value,
      });
      CachingEngine.setData('assignment', updatedAssignment.toJSON());
      return updatedAssignment;
    });
  };

  const updateBook = (event: any) => {
    if (!book || !classroom) return;
    var value = event.target.value;
    if (event.target.id === 'difficulty') {
      value = Math.min(Math.max(event.target.value, 0), 2000);
    }
    setBook((prevBook: any) => {
      const updatedBook = Book.fromServerBook({
        ...prevBook,
        [event.target.id]: value,
      });
      CachingEngine.setData('assignment', updatedBook.toJSON());
      return updatedBook;
    });
  };

  const handleCreateAssignment = (
    generateAudio?: boolean,
    shouldAnalyze: boolean = true,
  ) => {
    // console.log(book, assignment, classroom, assignmentId, gradebook);
    if (!book || !assignment || !classroom || !gradebook) return;
    if (!assignmentId) {
      if (book.id === '' || true) {
        makeApiCall(
          teacherService.createBook,
          book,
          coverImageFile,
          generateAudio,
          shouldAnalyze,
        )
          .then((resp) =>
            makeApiCall(
              teacherService.createAssignment,
              assignment,
              questions,
              resp.id,
            )
              .then((respAssignment) => {
                if (gradebook) {
                  teacherService.setCachedGradebook(
                    gradebook.addAssignment(respAssignment),
                  );
                }
                navigate(-1);
              })
              .catch((error) => {
                setBook((prev) =>
                  Book.fromServerBook({
                    ...resp,
                    html_content: book.html_content,
                  }),
                );
                alert(error.message);
              }),
          )
          .catch((error) => alert(error));
      } else {
        //   makeApiCall(
        //     teacherService.createAssignment,
        //     book.id,
        //     assignment,
        //     questions,
        //   )
        //     .then((respAssignment) => {
        //       if (gradebook) {
        //         setGradebook(gradebook.addAssignment(respAssignment));
        //       }
        //       navigate(`/teacher/classroom/${classroom.id}/`);
        //     })
        //     .catch((error) => alert(error.message));
      }
    } else {
      makeApiCall(
        teacherService.updateAssignment,
        assignment,
        questions,
        book,
        coverImageFile,
        generateAudio,
      )
        .then((respAssignment) => {
          teacherService.setCachedGradebook(
            gradebook.updateAssignment(respAssignment),
          );
          navigate(-1);
        })
        .catch((error) => alert(error.message));
    }
  };

  const handleCreateMultiPartAssignment = useCallback(() => {
    if (!classroom) return;
    if (assignmentId) {
      makeApiCall(teacherService.updateAssignment, assignment, questions)
        .then((respAssignment) => {
          if (gradebook) {
            teacherService.setCachedGradebook(
              gradebook.updateAssignment(respAssignment),
            );
          }
          navigate(-1);
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      makeApiCall(teacherService.createAssignment, assignment, questions)
        .then((respAssignment) => {
          if (gradebook) {
            teacherService.setCachedGradebook(
              gradebook.addAssignment(respAssignment),
            );
          }
          navigate(-1);
        })
        .catch((error) => {
          alert(error);
        });
    }
  }, [
    classroom,
    assignmentId,
    assignment,
    questions,
    gradebook,
    makeApiCall,
    navigate,
  ]);

  const handleUpdateText = (html_content: string) => {
    if (!book) return;
    setBook((prevBook: any) => {
      return Book.fromServerBook({
        ...prevBook,
        html_content: html_content,
        // text: extractInnerTextFromHTML(html_content),
      });
    });
  };

  const handleCreateQuestion = (
    text: string,
    choices?: MultipleChoiceOption[],
    index?: number,
    questionType?: QuestionType,
  ) => {
    if (!assignment) return;
    if (assignmentId) {
      makeApiCall(
        teacherService.createQuestion,
        text,
        index ?? questions.length + 1,
        assignment,
        choices,
      )
        .then((respQ) =>
          setQuestions((prevQuestions) => [...prevQuestions, respQ]),
        )
        .catch((error) => alert(error.message));
    } else {
      setQuestions((prevQuestions) => [
        ...prevQuestions,
        new Question(
          (prevQuestions.length + 1).toString(),
          '',
          text,
          prevQuestions.length + 1,
          100,
          questionType ?? QuestionType.SHORTANSWER,
          choices?.map((c) => MultipleChoiceOption.fromServerOption(c)),
        ),
      ]);
    }
  };

  const handleUpdateQuestion = (
    question: Question,
    submit: boolean = true,
    image?: File,
  ) => {
    const questionIdx = questions.findIndex((q) => q.id === question.id);
    if (questionIdx === -1) return;

    const updatedQuestions = [...questions];
    updatedQuestions[questionIdx] = question;
    updatedQuestions[questionIdx].image_file = image ?? question.image_file;
    setQuestions(updatedQuestions);

    if (assignmentId && submit) {
      makeApiCall(teacherService.updateQuestion, updatedQuestions[questionIdx])
        .then((resp) => {
          updatedQuestions[questionIdx] = Question.fromServerQuestion(resp);
          setQuestions(updatedQuestions);
        })
        .catch((error) => alert(error.message));
    }
  };

  const handleDeleteQuestion = (question: Question) => {
    if (assignmentId) {
      makeApiCall(teacherService.deleteQuestion, question)
        .then((resp) =>
          setQuestions((prevQuestions) => [
            ...prevQuestions
              .filter((q) => q.getId() !== question.getId())
              .map((q) =>
                Question.fromQuestion(
                  new Question(
                    q.getId(),
                    q.assignment,
                    q.text,
                    q.index < question.index ? q.index : q.index - 1,
                    q.max_points,
                    q.question_type,
                    q.choices ? [...q.choices] : undefined,
                  ),
                ),
              ),
          ]),
        )
        .catch((error) => alert(error.message));
    } else {
      setQuestions((prevQuestions) => [
        ...prevQuestions
          .filter((q) => q.getId() !== question.getId())
          .map((q) =>
            Question.fromQuestion(
              new Question(
                q.getId(),
                q.assignment,
                q.text,
                q.index < question.index ? q.index : q.index - 1,
                q.max_points,
                q.question_type,
                q.choices ? [...q.choices] : undefined,
              ),
            ),
          ),
      ]);
    }
  };

  const handleDeleteAssignment = () => {
    if (!assignment || !assignmentId) return;
    makeApiCall(teacherService.deleteAssignment, assignmentId)
      .then((resp) => {
        if (gradebook) {
          const updatedGradebook = gradebook.removeAssignment(assignment);
          teacherService.setCachedGradebook(updatedGradebook);
        }
        navigate(-1);
      })
      .catch((error) => alert(`Failed to delete assignment: ${error}`))
      .finally(() => setAssignment(undefined));
  };

  const handleBookCoverChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageAsString = e.target?.result;
        setCoverImage(imageAsString?.toString());
      };

      reader.readAsDataURL(file);
      setCoverImageFile(file);
    }
  };

  const handleInputFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.target.files) return;
    makeApiCall(extractTextFromFiles, event.target.files)
      .then((respText) =>
        setBook((prev) =>
          Book.fromServerBook({
            ...prev,
            html_content: respText,
          }),
        ),
      )
      .catch((error) => alert(error.message));
  };

  const handleGenerateText = useCallback(
    (text: string) => {
      if (!text || text === '') return;
      makeApiCall(
        aiService.generateText,
        text,
        book?.difficulty,
        assignment?.key_words,
      ).then((resp) => {
        setBook((prev?: Book) => {
          const updatedBook = Book.fromBook(prev as Book);
          updatedBook.html_content = `<p>${resp.text}</p>`;
          updatedBook.difficulty = Number(resp.difficulty);
          updatedBook.title = resp.title;
          return updatedBook;
        });
        setAssignment((prev?: Assignment) => {
          const updatedAssignment = Assignment.fromAssignment(
            prev as Assignment,
          );
          updatedAssignment.title = resp.title;
          return updatedAssignment;
        });
      });
    },
    [assignment?.key_words, book?.difficulty, makeApiCall],
  );

  if (!assignment || !book) return <AppPage />;

  return (
    <AppPage>
      {assignment.assignment_type === 'Default' && (
        <BaseAssignmentForm
          assignment={assignment}
          book={book}
          setAssignment={setAssignment}
          updateAssignment={updateAssignment}
          updateBook={updateBook}
          handleInputFileChange={handleInputFileChange}
          handleBookCoverChange={handleBookCoverChange}
          handleCreateAssignment={handleCreateAssignment}
          handleDeleteAssignment={handleDeleteAssignment}
          setViewBooks={() => {}}
          setViewQuestions={() => setEditQuestions(true)}
          handleUpdateText={handleUpdateText}
          onCancel={handleCancel}
          onGenerateText={handleGenerateText}
        />
      )}
      {(assignment.assignment_type === 'Listening' ||
        assignment.assignment_type === 'Read-Aloud') && (
        <ListeningAssignmentForm
          assignment={assignment}
          book={book}
          coverImage={coverImage}
          updateAssignment={updateAssignment}
          updateBook={updateBook}
          handleInputFileChange={handleInputFileChange}
          handleBookCoverChange={handleBookCoverChange}
          handleCreateAssignment={handleCreateAssignment}
          handleDeleteAssignment={handleDeleteAssignment}
          setAssignment={setAssignment}
          setViewBooks={() => {}}
          setViewQuestions={() => setEditQuestions(true)}
          handleUpdateText={handleUpdateText}
          onCancel={handleCancel}
          onGenerateText={handleGenerateText}
        />
      )}
      {assignment.assignment_type === 'Speaking' && (
        <SpeakingAssignmentForm
          assignment={assignment}
          book={book}
          coverImage={coverImage}
          setAssignment={setAssignment}
          updateAssignment={updateAssignment}
          updateBook={updateBook}
          handleInputFileChange={handleInputFileChange}
          handleBookCoverChange={handleBookCoverChange}
          handleCreateAssignment={handleCreateAssignment}
          handleDeleteAssignment={handleDeleteAssignment}
          setViewBooks={() => {}}
          setViewQuestions={() => setEditQuestions(true)}
          handleUpdateText={handleUpdateText}
          onCancel={handleCancel}
          onGenerateText={handleGenerateText}
        />
      )}
      {assignment.assignment_type === 'Multi-Part' && (
        <MultiPartAssignmentForm
          assignment={assignment}
          questions={questions}
          setAssignment={setAssignment}
          updateAssignment={updateAssignment}
          updateBook={updateBook}
          handleInputFileChange={handleInputFileChange}
          handleBookCoverChange={handleBookCoverChange}
          handleCreateAssignment={handleCreateMultiPartAssignment}
          handleDeleteAssignment={handleDeleteAssignment}
          onCancel={handleCancel}
          onCreateQuestion={handleCreateQuestion}
          onUpdateQuestion={handleUpdateQuestion}
          onDeleteQuestion={handleDeleteQuestion}
          setViewBooks={() => {}}
        />
      )}
      <Modal
        isOpen={
          assignment.assignment_type === 'Flashcards' ||
          assignment.assignment_type === 'Pronounce'
        }
        onClose={handleCancel}
      >
        <PracticeAssignmentForm
          assignment={assignment}
          classroom={classroom as Classroom}
          onExit={handleCancel}
        />
      </Modal>
      <Modal isOpen={editQuestions} onClose={() => setEditQuestions(false)}>
        <QuestionEditor
          book={book}
          questions={questions}
          defaultQuestions={book?.default_questions}
          isAutoGenerateAllowed={
            book.html_content !== undefined &&
            book.html_content.length > 50 &&
            isAutoGenerateAllowed
          }
          setIsAutoGenerateAllowed={setIsAutoGenerateAllowed}
          onCreateQuestion={handleCreateQuestion}
          onUpdateQuestion={handleUpdateQuestion}
          onDeleteQuestion={handleDeleteQuestion}
          onExit={() => setEditQuestions(false)}
        />
      </Modal>
    </AppPage>
  );
};
