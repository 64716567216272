import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import 'view/style/common/components/statusIcon.css';

interface StatusIconProps {
  text: string;
  icon: IconDefinition;
  color: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const StatusIcon: React.FC<StatusIconProps> = ({
  text,
  icon,
  color,
  onClick,
}) => {
  return (
    <div
      className={`status-icon ${onClick ? 'clickable' : ''}`}
      style={{ color: color }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} size="sm" />
      <span className="label-small">{text}</span>
    </div>
  );
};

export default StatusIcon;
