import React, { useEffect, useMemo, useState } from 'react';
import ReadingFrame from 'view/components/common/ReadingFrame';
import Question from 'models/Question';
import StudentQuestion from 'models/StudentQuestion';
import QuestionResponse from 'models/QuestionResponse';
import TranscribeFrame from './TranscribeFrame';
import FillInBlankFrame from './FillInBlankFrame';
import QuestionFrame from './QuestionFrame';
import ConversationFrame from './ConversationFrame';
import ReadAloudFrame from './ReadAloudFrame';
import { QuestionType } from 'utils/types';
import triggerConfetti from 'contexts/celebrations/Confetti';
import { AppPage } from 'view/components/common/AppPage';
import MultipartControls from './MultipartControls';

import 'view/style/student/components/newcomer/multipartAssignment.css';

// const wormy_you: string = require('assets/images/logos/wormy-you.png');

const MultiPartFrames: Record<string, React.FC<FrameProps>> = {
  [QuestionType.READALOUD]: ReadAloudFrame,
  [QuestionType.TRANSCRIBE]: TranscribeFrame,
  [QuestionType.CONVERSATION]: ConversationFrame,
  [QuestionType.RESPOND]: ConversationFrame,
  [QuestionType.SHORTANSWER]: QuestionFrame,
  [QuestionType.MULTIPLECHOICE]: QuestionFrame,
  [QuestionType.LISTENING]: QuestionFrame,
  [QuestionType.FILLINBLANK]: FillInBlankFrame,
};

type MultiPartAssignmentProps = {
  questions: StudentQuestion[];
  teacherView?: boolean;
  showFeedback?: boolean;
  onCompletion?: () => void;
  onUpdateResponse: (question: Question, response: QuestionResponse) => void;
  onMissedWord?: (
    word: string,
    index: number,
    known: boolean,
    question?: Question,
  ) => Promise<void>;
};

const MultipartAssignment: React.FC<MultiPartAssignmentProps> = ({
  questions,
  teacherView = false,
  showFeedback = true,
  onCompletion,
  onUpdateResponse,
  onMissedWord,
}) => {
  const [mutableQuestions, setMutableQuestions] =
    useState<StudentQuestion[]>(questions);
  const [questionIdx, setActiveFrameIdx] = useState<number>(0);
  const [responses, setResponses] = useState<QuestionResponse[]>(
    questions.map((q) => q.response),
  );

  const handlePrevious = () => {
    setActiveFrameIdx((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setActiveFrameIdx((prev) =>
      Math.min(prev + 1, mutableQuestions.length - 1),
    );
  };

  const handleCheck = () => {
    if (
      !mutableQuestions ||
      mutableQuestions[questionIdx].question.check ||
      (responses[questionIdx].choice === null &&
        responses[questionIdx].response === '' &&
        mutableQuestions[questionIdx].question.question_type !==
          QuestionType.READALOUD)
    )
      return;

    const updatedQuestions = [...questions];
    updatedQuestions[questionIdx].question.check = true;
    setMutableQuestions(updatedQuestions);

    var updatedResponses = [...responses];
    updatedResponses[questionIdx] = QuestionResponse.fromQuestionResponse(
      updatedResponses[questionIdx],
    );
    updatedResponses[questionIdx].num_attempts += 1;

    setResponses(updatedResponses);

    if (
      updatedResponses.every((response) => response.num_attempts >= 1) &&
      onCompletion
    ) {
      onCompletion();
    }
  };

  const updateResponse = (
    response: QuestionResponse,
    resetCheck: boolean = true,
  ) => {
    var updatedResponses = [...responses];
    updatedResponses[questionIdx] = response;
    setResponses(updatedResponses);

    if (
      (response.completed && questions[questionIdx].question.check) ||
      [
        QuestionType.READALOUD,
        QuestionType.CONVERSATION,
        QuestionType.RESPOND,
      ].includes(questions[questionIdx].question.question_type)
    ) {
      onUpdateResponse(questions[questionIdx].question, response);
    }

    if (
      questions[questionIdx].question.question_type ===
        QuestionType.READALOUD &&
      onCompletion
    ) {
      onCompletion();
    }

    if (!resetCheck) return;

    const updatedQuestions = [...questions];
    updatedQuestions[questionIdx].question.check = false;
    setMutableQuestions(updatedQuestions);
  };

  const gradeResponse = () => {
    if (!teacherView) return;
    onUpdateResponse(questions[questionIdx].question, responses[questionIdx]);
  };

  useEffect(() => {
    if (!teacherView && responses && responses.every((r) => r.completed)) {
      triggerConfetti(3000);
      if (onCompletion) onCompletion();
    }
  }, [teacherView, responses, onCompletion]);

  const FrameComponent = useMemo(
    () => MultiPartFrames[mutableQuestions[questionIdx].question.question_type],
    // eslint-disable-next-line
    [questionIdx],
  );

  if (!mutableQuestions) return <AppPage />;

  return (
    <div className="reading-container">
      <ReadingFrame>
        <FrameComponent
          key={`frame-${questionIdx}`}
          question={mutableQuestions[questionIdx].question}
          response={responses[questionIdx]}
          teacherView={teacherView}
          updateResponse={updateResponse}
          showFeedback={showFeedback}
        />
      </ReadingFrame>
      <MultipartControls
        questionComplete={responses[questionIdx].completed}
        questionIdx={questionIdx}
        teacherView={teacherView}
        showPrev={questionIdx > 0}
        showNext={
          questionIdx < mutableQuestions.length - 1 &&
          (responses[questionIdx].completed || teacherView)
        }
        onPrevious={handlePrevious}
        onNext={handleNext}
        onSubmit={teacherView ? gradeResponse : handleCheck}
      />
    </div>
  );
};

export default MultipartAssignment;

export interface FrameProps {
  question: Question;
  response: QuestionResponse;
  teacherView?: boolean;
  showFeedback?: boolean;
  updateResponse: (response: QuestionResponse, resetCheck?: boolean) => void;
}
