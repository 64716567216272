import React, { useEffect, useState } from 'react';
import { FrameProps } from './MultipartAssignment';
import useApiCall from 'contexts/ApiCall';
import aiService from 'services/aiService';
import QuestionResponse from 'models/QuestionResponse';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import QuestionChoices from '../assignment/QuestionChoices';
import CachingEngine from 'utils/CachingEngine';
import VisualMultipleChoiceOptions from './VisualMultipleChoiceOptions';
import QuestionFeedback from './QuestionFeedback';

const wormy: string = require('assets/images/logos/wormy-1.png');

const QuestionFrame: React.FC<FrameProps> = ({
  question,
  response,
  teacherView = false,
  showFeedback = true,
  updateResponse,
}) => {
  const [audioSrc, setAudioSrc] = useState<string>();
  const makeApiCall = useApiCall();

  useEffect(() => {
    if (question.text && question.question_type === 'listening' && !audioSrc) {
      CachingEngine.getData(`${question.index}-audio`).then((cachedAudio) => {
        if (cachedAudio) {
          setAudioSrc(cachedAudio);
        } else {
          makeApiCall(aiService.convertTextToSpeech, question.text)
            .then((resp) => {
              setAudioSrc(resp);
              CachingEngine.setData(`${question.index}-audio`, resp);
            })
            .catch((error) => alert(error));
        }
      });
    }
  }, [
    question.text,
    audioSrc,
    makeApiCall,
    question.index,
    question.question_type,
  ]);

  useEffect(() => {
    if (!question.check || teacherView) return;
    if (question.choices && question.choices.length) {
      // update the response
      handleUpdateResponse(
        response.response,
        response.choice,
        response.grade > 0,
        response.feedback,
        true,
        false,
      );
      return;
    }
    // check the student's short answer response
    if (response.feedback) return;
    makeApiCall(
      aiService.gradeShortAnswer,
      question.text,
      response.response,
      question.image_url,
      question.prompt,
    )
      .then((resp) =>
        handleUpdateResponse(
          response.response,
          response.choice,
          resp.correct,
          resp.feedback,
          true,
          false,
        ),
      )
      .catch((error) => alert(error));
    // eslint-disable-next-line
  }, [question.check]);

  const handleUpdateResponse = (
    responseText: string,
    responseChoice: string,
    correct: boolean,
    feedback?: string,
    isAttempt: boolean = true,
    resetCheck: boolean = true,
  ) => {
    if (teacherView) return;
    const updatedResponse = QuestionResponse.fromQuestionResponse(response);
    updatedResponse.response = responseText;
    updatedResponse.choice = responseChoice;
    updatedResponse.feedback = feedback;
    updatedResponse.grade = Number(correct) * 100;
    updatedResponse.num_attempts += Number(isAttempt);
    updatedResponse.completed = updatedResponse.completed || isAttempt;

    updateResponse(updatedResponse, resetCheck);
  };

  const handleGradeResponse = (grade: number, feedback: string) => {
    if (!teacherView) return;

    const updatedResponse = QuestionResponse.fromQuestionResponse(response);
    updatedResponse.feedback = feedback;
    updatedResponse.grade = grade;

    updateResponse(updatedResponse);
  };

  return (
    <div className="conversation-frame">
      <div className="question-input">
        <div className="row">
          {question.question_type === 'listening' ? (
            <img className="wormy" src={wormy} alt="wormy" />
          ) : (
            <label className="label-medium">{question.text}</label>
          )}
          <AudioAssistanceButton text={question.text} audioSrc={audioSrc} />
        </div>
        {question.question_type === 'listening' && question.prompt && (
          <div className="row">
            <label className="label-medium">{question.prompt}</label>
            <AudioAssistanceButton text={question.prompt} />
          </div>
        )}
        <div className="row spaced">
          {question.choices?.length ? (
            !question.choices[0].img || question.choices[0].img === '' ? (
              <QuestionChoices
                choice={response.choice}
                choices={question.choices}
                highlightCorrect={question.check}
                onUpdateResponse={(a, b, c, d, e) => {
                  if (response.num_attempts === 0 && !response.completed) {
                    handleUpdateResponse(a, b, c, d, e, false);
                  }
                }}
              />
            ) : (
              <VisualMultipleChoiceOptions
                choices={question.choices}
                check={question.check ?? false}
                onUpdateResponse={(
                  responseChoice,
                  correct,
                  feedback,
                  isAttempt,
                ) =>
                  handleUpdateResponse(
                    '',
                    responseChoice,
                    correct,
                    feedback,
                    isAttempt,
                    false,
                  )
                }
                response={response}
              />
            )
          ) : (
            <textarea
              value={response.response ?? ''}
              onChange={(event) =>
                handleUpdateResponse(
                  event.target.value,
                  response.choice,
                  false,
                  response.feedback,
                  false,
                )
              }
            />
          )}
          {question.image_url && (
            <div className="book-card-cover">
              <img src={question.image_url} alt="failed to load" />
            </div>
          )}
        </div>
      </div>
      {(teacherView ||
        (!teacherView && response.completed && showFeedback)) && (
        <QuestionFeedback
          feedback={response.feedback ?? ''}
          grade={response.grade}
          teacherView={teacherView}
          onGradeResponse={handleGradeResponse}
        />
      )}
    </div>
  );
};

export default QuestionFrame;
