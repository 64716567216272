import React from 'react';
import Book from 'models/Book';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';

import 'view/style/catalog/components/bookCover.css';

interface BookCoverProps {
  book?: Book;
  onSelect?: (book: Book) => void;
}

const BookCover: React.FC<BookCoverProps> = ({ book, onSelect = () => {} }) => {
  return (
    <div className="book-card-cover">
      {book?.cover_url ? (
        <img src={book.cover_url} alt="" />
      ) : (
        <FontAwesomeIcon icon={faBookOpen} size="3x" />
      )}
    </div>
  );
};

export default BookCover;
