import React, { ReactNode } from 'react';
import { debounce } from 'lodash';
import contentService from 'services/contentService';

import 'view/style/common/components/searchBar.css';

const wormy_search: string = require('assets/images/logos/wormy-search.png');

interface SearchBarProps {
  showDropdown?: boolean;
  onSearch: (searchTerm: string) => void;
  children?: ReactNode;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  showDropdown,
  onSearch,
  children,
}) => {
  const formContent = contentService.getCommonForms().searchbar;
  const debouncedSearch = debounce(onSearch, 500);

  return (
    <div className="searchbar">
      <div className="searchbar-title">
        <img src={wormy_search} alt="" />
        <label className="label-small">{formContent.title}</label>
      </div>
      <div className="col" style={{ alignSelf: 'stretch' }}>
        <input
          type="text"
          placeholder={formContent.placeholder}
          onChange={(event) =>
            debouncedSearch(event.target.value.toLowerCase())
          }
        />
        {showDropdown && children && (
          <div className="searchbar-dropdown">{children}</div>
        )}
      </div>
    </div>
  );
};
