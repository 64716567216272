import React, { useState } from 'react';
import Assignment from 'models/Assignment';
import ActionButton from 'view/components/buttons/ActionButton';
import { useParams } from 'react-router-dom';
import AssignmentSidebar from '../AssignmentSidebar';
import { UserInput } from 'view/components/common/UserInput';
import useApiCall from 'contexts/ApiCall';
import aiService from 'services/aiService';
import MultiPartQuestionFrame from './MultipartQuestionFrame';
import MultiPartConversationFrame from './MultipartConversationFrame';
import MultiPartReadAloudFrame from './MultipartReadAloudFrame';
import IconButton from 'view/components/buttons/IconButton';
import Question from 'models/Question';
import { QuestionType } from 'utils/types';
import MultipleChoiceOption from 'models/MultipleChoiceOption';
import {
  faArrowLeft,
  faArrowRight,
  faLightbulb,
  faListUl,
  faMagicWandSparkles,
  faPlusSquare,
  faSave,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PanelButton from 'view/components/common/PanelButton';

import 'view/style/student/components/newcomer/multipartAssignment.css';

interface MultiPartAssignmentFormProps {
  assignment: Assignment;
  questions: Question[];
  setAssignment: React.Dispatch<React.SetStateAction<Assignment | undefined>>;
  updateAssignment: (event: any) => void;
  updateBook: (event: any) => void;
  setViewBooks: () => void;
  handleInputFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBookCoverChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCreateAssignment: (shouldAnalyze: boolean) => void;
  handleDeleteAssignment: () => void;
  onCreateQuestion: (
    text: string,
    choices?: MultipleChoiceOption[],
    index?: number,
    type?: QuestionType,
  ) => void;
  onUpdateQuestion: (
    question: Question,
    submit?: boolean,
    image?: File,
  ) => void;
  onDeleteQuestion: (question: Question) => void;
  onCancel: () => void;
}

const MultiPartAssignmentForm: React.FC<MultiPartAssignmentFormProps> = ({
  assignment,
  questions,
  setAssignment,
  updateAssignment,
  handleInputFileChange,
  handleBookCoverChange,
  handleCreateAssignment,
  handleDeleteAssignment,
  onCreateQuestion,
  onUpdateQuestion,
  onDeleteQuestion,
  onCancel,
  setViewBooks,
}) => {
  const { assignmentId } = useParams();
  // eslint-disable-next-line
  const [topic, setTopic] = useState<string>('Any');
  const [readingDifficulty, setReadingDifficulty] = useState<string>('');
  const [length, setLength] = useState<number>(6);
  const [showGenerateForm, setShowGenerateForm] = useState<boolean>(false);
  const makeApiCall = useApiCall();

  const handleGenerateAssignment = () => {
    makeApiCall(
      aiService.generateNewcomerJson,
      topic,
      readingDifficulty,
      length,
    )
      .then((resp) => handleGenerateQuestions(resp))
      .catch((error) => alert(error));
  };

  const handleStartFromScratch = () => {
    onCreateQuestion('');
  };

  const handleGenerateQuestions = (generatedQuestions?: Question[]) => {
    generatedQuestions?.forEach((q, idx) =>
      onCreateQuestion(
        q.text,
        q.choices?.map((c, index) =>
          MultipleChoiceOption.fromServerOption({ ...c, index: index + 1 }),
        ),
        idx + 1,
        q.question_type,
      ),
    );
  };

  return (
    <div className="app-page-content">
      <AssignmentSidebar
        assignment={assignment}
        options={['show-feedback', 'key-words']}
        handleBookCoverChange={handleBookCoverChange}
        handleInputFileChange={handleInputFileChange}
        onCancel={onCancel}
        setAssignment={setAssignment}
        updateAssignment={updateAssignment}
      >
        <div className="row">
          {assignmentId && (
            <ActionButton
              type="delete"
              onClick={handleDeleteAssignment}
              label="Delete"
              icon={faTrash}
              requiresConfirmation={true}
              confirmationMessage="Are you sure you want to delete this assignment? All submissions and grades will be lost."
            />
          )}
          <ActionButton
            type="go"
            onClick={() => handleCreateAssignment(false)}
            label={assignmentId ? 'Update' : 'Upload'}
            icon={faSave}
          />
        </div>
      </AssignmentSidebar>
      <div className="app-main-content">
        <div className="book-details-editor">
          <div className="assignment-editor-title">
            <FontAwesomeIcon
              icon={faListUl}
              size="3x"
              color="var(--dark-green)"
            />
            <input
              className="title"
              type="text"
              placeholder="Type your assignment name here..."
              id="title"
              value={assignment?.title}
              onChange={updateAssignment}
            />
          </div>
        </div>
        {questions?.length ? (
          <MultiPartAssignment
            questions={questions}
            onCreateQuestion={onCreateQuestion}
            onUpdateQuestion={onUpdateQuestion}
            onDeleteQuestion={onDeleteQuestion}
          />
        ) : (
          <div className="reading-frame">
            {showGenerateForm ? (
              <>
                <UserInput
                  type="text"
                  id="topic"
                  label="Topic"
                  hint="Optional: If you want to auto generate the assignment, tell us what it should be about"
                  value={topic}
                  onChange={(event) => setTopic(event.target.value)}
                />
                <UserInput
                  type="text"
                  id="reading-difficulty"
                  label="Learner level"
                  hint="Optional: Who is your target audience?"
                  value={readingDifficulty}
                  onChange={(event) => setReadingDifficulty(event.target.value)}
                />
                <UserInput
                  type="number"
                  id="assignment-length"
                  label="Number of questions"
                  value={length}
                  onChange={(event) => setLength(event.target.value)}
                />
                <ActionButton
                  label="Generate"
                  icon={faMagicWandSparkles}
                  type="go"
                  onClick={handleGenerateAssignment}
                />
              </>
            ) : (
              <>
                <PanelButton
                  label="Auto Generate"
                  icon={faMagicWandSparkles}
                  hint="Enter some criteria, and we'll generate the assignment for you"
                  onClick={() => setShowGenerateForm(true)}
                />
                <PanelButton
                  label="Build your own"
                  icon={faLightbulb}
                  hint="Make it your own by building it from scratch"
                  onClick={handleStartFromScratch}
                />
                {/* <PanelButton
                  label="Choose from catalog"
                  icon={faBookOpen}
                  hint="Use an existing assignment from your district or the open source Orator community"
                  onClick={setViewBooks}
                /> */}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiPartAssignmentForm;

type MultiPartAssignmentProps = {
  questions: Question[];
  onCreateQuestion: (text: string, choices?: MultipleChoiceOption[]) => void;
  onUpdateQuestion: (updatedquestion: Question) => void;
  onDeleteQuestion: (updatedquestion: Question) => void;
};

const MultiPartAssignment: React.FC<MultiPartAssignmentProps> = ({
  questions,
  onCreateQuestion,
  onUpdateQuestion,
  onDeleteQuestion,
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const handleNext = () => {
    if (currentPage < questions.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleAddQuestion = () => {
    onCreateQuestion('');
    // TODO: automatically go to the next page
    // setCurrentPage(currentPage + 1);
  };

  const handleChangeContentType = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const updatedQuestion = Question.fromQuestion(questions[currentPage]);
    updatedQuestion.question_type = event.target.value as QuestionType;

    onUpdateQuestion(updatedQuestion);
  };

  const handleDeleteQuestion = (question: Question) => {
    handlePrevious();
    onDeleteQuestion(question);
  };

  return (
    <>
      <div className="reading-frame">
        {(questions[currentPage].question_type === 'choice' ||
          questions[currentPage].question_type === 'fillInBlank' ||
          questions[currentPage].question_type === 'text' ||
          questions[currentPage].question_type === 'listening') && (
          <MultiPartQuestionFrame
            question={questions[currentPage]}
            onUpdateQuestion={onUpdateQuestion}
            onDeleteQuestion={handleDeleteQuestion}
          />
        )}
        {(questions[currentPage].question_type === 'conversation' ||
          questions[currentPage].question_type === 'respond' ||
          questions[currentPage].question_type === 'transcribe') && (
          <MultiPartConversationFrame
            question={questions[currentPage]}
            onUpdateQuestion={onUpdateQuestion}
          />
        )}
        {questions[currentPage].question_type === 'readAloud' && (
          <MultiPartReadAloudFrame
            question={questions[currentPage]}
            onUpdateQuestion={onUpdateQuestion}
          />
        )}
      </div>
      <div className="row">
        {currentPage > 0 && (
          <IconButton
            onClick={handlePrevious}
            icon={faArrowLeft}
            disabled={currentPage === 0}
          />
        )}
        <select
          value={questions[currentPage].question_type}
          onChange={handleChangeContentType}
        >
          <option value="choice">Multiple Choice</option>
          <option value="text">Short Answer</option>
          <option value="conversation">Conversation</option>
          <option value="readAloud">Read Aloud</option>
          <option value="transcribe">Transcribe</option>
          <option value="listening">Listening</option>
          <option value="fillInBlank">Fill in the blank</option>
          <option value="respond">Respond</option>
        </select>
        {currentPage < Object.keys(questions).length - 1 ? (
          <IconButton onClick={handleNext} icon={faArrowRight} />
        ) : (
          <IconButton onClick={handleAddQuestion} icon={faPlusSquare} />
        )}
      </div>
    </>
  );
};
