import React, { useCallback, useEffect, useState } from 'react';
import dictionaryService from 'services/dictionaryService';
import useApiCall from 'contexts/ApiCall';
import MissedWordPopup from './MissedWordPopup';
import ScreenReader from 'utils/ScreenReader';
import Button from '../buttons/Button';
import ReadingFrame from '../common/ReadingFrame';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';

const new_releases: string = require('assets/icons/new_releases.svg').default;
const hearing: string = require('assets/icons/hearing.svg').default;
const restart_alt: string = require('assets/icons/restart_alt.svg').default;
const arrow_back: string = require('assets/icons/arrow_back.svg').default;
const flag: string = require('assets/icons/flag_regular.svg').default;
const play: string = require('assets/icons/play_circle_filled.svg').default;
const pause: string = require('assets/icons/pause_circle.svg').default;

enum MissedWordState {
  Start = 0,
  Listen = 1,
  Final = 2,
}

interface MissedWordDisplayProps {
  word: string;
  index: number;
  showPrompt?: boolean;
  showMissedWordIndicator?: boolean;
  allowPractice?: boolean;
  onExit: () => void;
  onMissedWord?: (word: string, index: number, known: boolean) => void;
}

const MissedWordDisplay: React.FC<MissedWordDisplayProps> = ({
  word,
  index,
  showPrompt = true,
  showMissedWordIndicator = true,
  allowPractice = false,
  onExit,
  onMissedWord = () => {},
}) => {
  const [audio, setAudio] = useState<HTMLAudioElement>();
  const [missedWordState, setMissedWordState] = useState<MissedWordState>(
    MissedWordState.Start,
  );
  const [definition, setDefinition] = useState<any>();
  const [isReported, setIsReported] = useState<boolean>(false);
  const [practiceMode, setPracticeMode] = useState<boolean>(false);
  const [speaking, setSpeaking] = useState<boolean>(false);
  const makeApiCall = useApiCall();

  useEffect(() => {
    if (missedWordState === MissedWordState.Start && !showPrompt) {
      setMissedWordState(MissedWordState.Final);
    }
    // eslint-disable-next-line
  }, [showPrompt]);

  useEffect(() => {
    if (missedWordState === MissedWordState.Start || definition) return;
    makeApiCall(dictionaryService.getWordDefinition, word)
      .then((respDef) => setDefinition(respDef ?? {}))
      .catch((error) => console.error(error.message));
  }, [word, definition, missedWordState, makeApiCall]);

  const handleMissedWord = useCallback(
    (missed_word: string, index: number, known: boolean) => {
      onMissedWord(missed_word, index, known);
      if (known) onExit();
      else setMissedWordState(MissedWordState.Listen);
    },
    [onMissedWord, onExit],
  );

  const handleMissedWordListen = () => {
    if (definition?.audio_url) {
      if (!audio) {
        const newAudio = new Audio(definition.audio_url);
        setAudio(newAudio);
        newAudio.play();
      } else {
        audio.play();
      }
    } else {
      ScreenReader.speak(word);
    }

    if (missedWordState === MissedWordState.Listen) {
      setMissedWordState(MissedWordState.Final);
    }
  };

  const handleReportDefinition = () => {
    makeApiCall(dictionaryService.reportWordDefinition, word)
      .then((resp) => {
        alert(
          'Definition successfully reported, we will review it as soon as possible!',
        );
        setIsReported(true);
      })
      .catch((error) => console.error(error.message));
  };

  const handleStartSpeaking = () => {
    setSpeaking(true);
  };

  const handleStopSpeaking = () => {
    setSpeaking(false);
  };

  return (
    <div className="reading-container">
      <ReadingFrame>
        <MissedWordPopup
          word={word}
          index={index}
          showDefinition={missedWordState !== MissedWordState.Start}
          definition={definition}
          onWordPromptSelect={handleMissedWord}
          practiceMode={practiceMode}
          isSpeaking={speaking}
        />
      </ReadingFrame>
      {missedWordState === MissedWordState.Start && showMissedWordIndicator ? (
        <Button
          type="delete"
          text="Missed Word"
          leadIcon={new_releases}
          label="Missed word"
        />
      ) : null}
      {missedWordState === MissedWordState.Listen && (
        <Button
          type="go"
          onClick={handleMissedWordListen}
          text="Listen"
          leadIcon={hearing}
          label="Listen"
        />
      )}
      {missedWordState === MissedWordState.Final && (
        <div className="row stretch">
          {!allowPractice ? (
            <div className="book-completed-text">
              <Button
                onClick={onExit}
                text="Back"
                leadIcon={arrow_back}
                label="Back"
              />
            </div>
          ) : !practiceMode ? (
            <div className="book-completed-text">
              <Button
                onClick={() => setPracticeMode(true)}
                text="Practice"
                fontAwesomeIcon={faGraduationCap}
                label="Practice"
              />
            </div>
          ) : (
            <div className="book-completed-text">
              <Button
                onClick={() => setPracticeMode(false)}
                text="Definition"
                leadIcon={arrow_back}
                label="Definition"
              />
            </div>
          )}
          {practiceMode ? (
            <div className="book-completed-text">
              <Button
                type={speaking ? 'delete' : 'go'}
                onClick={speaking ? handleStopSpeaking : handleStartSpeaking}
                text={speaking ? 'Stop' : 'Speak'}
                leadIcon={speaking ? pause : play}
                label={speaking ? 'Stop' : 'Speak'}
              />
            </div>
          ) : (
            <div className="book-completed-text">
              <Button
                type="go"
                onClick={handleMissedWordListen}
                text="Repeat"
                leadIcon={restart_alt}
                label="Repeat"
              />
            </div>
          )}
          <div className="book-completed-text">
            {!isReported && (
              <Button
                type="delete"
                onClick={handleReportDefinition}
                text="Report"
                leadIcon={flag}
                label="Report"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MissedWordDisplay;
