import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConfirmation } from 'contexts/ConfirmationContext';
import React from 'react';
import { ButtonType } from 'utils/types';

import 'view/style/common/buttons.css';

interface ActionButtonProps {
  type?: ButtonType;
  disabled?: boolean;
  text?: string;
  icon?: IconDefinition;
  label: string;
  buttonType?: 'button' | 'submit' | 'reset' | undefined;
  requiresConfirmation?: boolean;
  confirmationMessage?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  type,
  disabled = false,
  text,
  icon,
  label,
  buttonType,
  requiresConfirmation = false,
  confirmationMessage = '',
  onClick,
}) => {
  const { openModal } = useConfirmation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!onClick) return;
    if (requiresConfirmation) {
      event.preventDefault();
      event.stopPropagation();
      openModal(confirmationMessage, onClick);
    } else {
      onClick(event);
    }
  };

  return (
    <button
      className={`action-button ${type || ''}`}
      onClick={handleClick}
      disabled={disabled}
      type={buttonType}
    >
      {icon && <FontAwesomeIcon icon={icon} />}
      <span className="label-normal">{label}</span>
    </button>
  );
};

export default ActionButton;
