import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, KeyboardEvent } from 'react';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';

import 'view/style/common/components/userInput.css';

type RangeValue = { min: number; max: number };

interface UserInputProps {
  id: string;
  label: string;
  hint?: string;
  type: string;
  value?: string | number;
  defaultValue?: string | number;
  rangeValue?: RangeValue;
  text?: string;
  options?: any[];
  optionsField?: string;
  defaultOption?: string;
  readonly?: boolean;
  inlineLabel?: ReactElement;
  valid?: boolean;
  maxLength?: number;
  minLength?: number;
  placeHolder?: string;
  onChange?: (event: any) => void;
  onClick?: (event: any) => void;
  onBlur?: (event: any) => void;
}

export const UserInput: React.FC<UserInputProps> = ({
  id,
  label,
  hint,
  type,
  value,
  defaultValue,
  rangeValue,
  text,
  options,
  optionsField,
  defaultOption,
  readonly,
  inlineLabel,
  valid,
  maxLength,
  minLength,
  placeHolder = 'Type here...',
  onChange,
  onClick,
  onBlur,
}) => {
  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  };

  return (
    <div className="user-input" onClick={onClick}>
      <div className="user-input-header">
        <div className="user-input-label">
          <label className="label-small" htmlFor={id}>
            {label}
          </label>
          {text && <AudioAssistanceButton text={text} />}
        </div>
        {hint && (
          <div className="hint">
            <FontAwesomeIcon icon={faEye} size="xs" color="var(--dark-green)" />
            <label className="label-small hint">{hint}</label>
          </div>
        )}
      </div>
      {type === 'from-to' && rangeValue && (
        <FromToInput id={id} value={rangeValue} onChange={onChange} />
      )}
      {type === 'select' && options && (
        <select id={id} value={value} onChange={onChange}>
          {defaultOption && (
            <option value={defaultOption}>{defaultOption}</option>
          )}
          {options.map((opt, idx) => (
            <option
              key={`option-${idx}`}
              value={optionsField ? opt[optionsField] : opt}
            >
              {optionsField ? opt[optionsField] : opt}
            </option>
          ))}
        </select>
      )}
      {(type === 'text' ||
        type === 'number' ||
        type === 'checkbox' ||
        type === 'password' ||
        type === 'email') &&
        !inlineLabel && (
          <input
            className={valid !== undefined ? (valid ? 'valid' : 'invalid') : ''}
            id={id}
            type={type}
            placeholder={placeHolder}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            readOnly={readonly}
            onKeyDown={handleKeyPress}
            onBlur={onBlur}
            maxLength={maxLength}
            minLength={minLength}
          />
        )}
      {(type === 'text' ||
        type === 'number' ||
        type === 'checkbox' ||
        type === 'password' ||
        type === 'email') &&
        inlineLabel && (
          <div
            className={`labeled-input label-right ${
              valid !== undefined ? (valid ? 'valid' : 'invalid') : ''
            }`}
          >
            <input
              id={id}
              type={type}
              placeholder={placeHolder}
              value={value}
              defaultValue={defaultValue}
              readOnly={readonly}
              onKeyDown={handleKeyPress}
              onChange={onChange}
              onBlur={onBlur}
              maxLength={maxLength}
              minLength={minLength}
            />
            <label>{inlineLabel}</label>
          </div>
        )}
      {type === 'textarea' && (
        <textarea
          id={id}
          placeholder={placeHolder}
          value={value}
          defaultValue={defaultValue}
          readOnly={readonly}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={maxLength}
          minLength={minLength}
        />
      )}
    </div>
  );
};

interface FromToInputProps {
  id: string;
  value: RangeValue;
  onChange?: (event: any) => void;
}

export const FromToInput: React.FC<FromToInputProps> = ({
  id,
  value,
  onChange,
}) => {
  return (
    <div className="from-to" id={id}>
      <div className="labeled-input label-left">
        <label>From</label>
        <input
          type="number"
          value={value.min}
          id={`min_${id}`}
          onChange={onChange}
        />
      </div>
      <div className="labeled-input label-left">
        <label>To</label>
        <input
          type="number"
          value={value.max}
          id={`max_${id}`}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
