import React, { useEffect, useRef, useState } from 'react';
import Button from 'view/components/buttons/Button';

const play_circle_filled: string =
  require('assets/icons/play_circle_filled.svg').default;
const pause_circle: string = require('assets/icons/pause_circle.svg').default;

interface AudioRecorderProps {
  onStartRecording: () => void;
  onStopRecording: () => void;
  onSubmit: (audioBlob: Blob) => void;
}

const AudioRecorder: React.FC<AudioRecorderProps> = ({
  onStartRecording,
  onStopRecording,
  onSubmit,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const chunksRef = useRef<Blob[]>([]);

  useEffect(() => {
    if (navigator.mediaDevices) {
      const constraints = { audio: true };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          streamRef.current = stream;
          const mediaRecorder = new MediaRecorder(stream);
          mediaRecorderRef.current = mediaRecorder;

          mediaRecorder.ondataavailable = (e: BlobEvent) => {
            chunksRef.current.push(e.data);
          };

          mediaRecorder.onstop = () => {
            const blob = new Blob(chunksRef.current, {
              type: 'audio/ogg; codecs=opus',
            });
            chunksRef.current = [];
            onSubmit(blob);
          };
        })
        .catch((err) => {
          console.error(`The following error occurred: ${err}`);
        });
    } else {
      console.error('getUserMedia not supported on your browser!');
    }

    // Cleanup: stop all tracks when component unmounts
    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, [onSubmit]);

  const startRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.start();
      setIsRecording(true);
      onStartRecording();
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== 'inactive'
    ) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      onStopRecording();
    }
  };

  return (
    <div className="row">
      {isRecording ? (
        <Button
          type="default"
          onClick={stopRecording}
          text="Stop"
          leadIcon={pause_circle}
          label="Stop"
        />
      ) : (
        <Button
          type="go"
          onClick={startRecording}
          text="Start"
          leadIcon={play_circle_filled}
          label="Start"
        />
      )}
    </div>
  );
};

export default AudioRecorder;
