import React, { useState } from 'react';
import contentService from 'services/contentService';
import Button from 'view/components/buttons/Button';
import { UserInput } from 'view/components/common/UserInput';

const arrow_forward: string =
  require('assets/icons/arrow_forward_black.svg').default;

type JoinClassroomFormData = {
  classroomId: string;
  accessCode: string;
};

interface JoinClassroomFormProps {
  joinClassroomHandler: Function;
}

export const JoinClassroomForm: React.FC<JoinClassroomFormProps> = ({
  joinClassroomHandler,
}) => {
  const [formData, setFormData] = useState<JoinClassroomFormData>({
    classroomId: '',
    accessCode: '',
  });
  const formContent = contentService.getStudentForms().join_classroom_form;

  const handleInputChange = (event: any) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.id]: event.target.value,
    }));
  };

  return (
    <div className="form">
      <h2>{formContent.title}</h2>
      <div
        className="form-contents"
        style={{ width: '30vw', minWidth: '300px' }}
      >
        {formContent.content.inputs.map((formInput: Record<string, string>) => (
          <UserInput
            key={formInput.id}
            id={formInput.id}
            label={formInput.label}
            hint={formInput.hint}
            type={formInput.type}
            text={formInput.label}
            onChange={handleInputChange}
          />
        ))}
      </div>
      <Button
        type="go"
        onClick={() =>
          joinClassroomHandler(formData.classroomId, formData.accessCode)
        }
        text="Join"
        label={formContent.content.confirm_button.label}
        trailIcon={arrow_forward}
      />
    </div>
  );
};
