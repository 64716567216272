import React, { useMemo, useState } from 'react';
import BarChart from './BarChart';
import {
  ChartData,
  defaultMissedWordChartData,
} from 'view/pages/teacher/analytics/analytics';
import { DisplayMissedWord } from 'utils/types';

interface MissedWordGraphProps {
  missedWords: DisplayMissedWord[];
}

const MissedWordGraph: React.FC<MissedWordGraphProps> = ({ missedWords }) => {
  const [numDisplayWords, setNumDisplayWords] = useState<number>(10);
  const [keyWordsOnly, setKeyWordsOnly] = useState<boolean>(false);

  const displayData: ChartData[] = useMemo(() => {
    const displayMissedKeyWords = missedWords
      .filter((dmw) => dmw.isKeyWord || !keyWordsOnly)
      .slice(0, numDisplayWords);

    return [
      {
        ...defaultMissedWordChartData[0],
        data: displayMissedKeyWords.map((dmw) => dmw.missed),
      },
      {
        ...defaultMissedWordChartData[1],
        data: displayMissedKeyWords.map((dmw) => dmw.unknown),
      },
    ];
  }, [numDisplayWords, missedWords, keyWordsOnly]);

  const displayLabels: string[] = useMemo(() => {
    const displayMissedKeyWords = missedWords
      .filter((dmw) => dmw.isKeyWord || !keyWordsOnly)
      .slice(0, numDisplayWords);

    return displayMissedKeyWords
      .slice(0, numDisplayWords)
      .map((dmw) => dmw.word);
  }, [numDisplayWords, missedWords, keyWordsOnly]);

  return (
    <>
      <BarChart
        options={{}}
        data={{
          labels: displayLabels ?? [],
          datasets: displayData,
        }}
      />
      <div className="row">
        <div className="labeled-input label-left">
          <label className="label-small"># Words</label>
          <input
            type="number"
            value={numDisplayWords}
            onChange={(event) =>
              setNumDisplayWords(
                Math.max(
                  Math.min(Number(event.target.value), missedWords.length),
                  1,
                ),
              )
            }
          />
        </div>
        <label className="label-small">Key words only</label>
        <input
          type="checkbox"
          onChange={(event) => setKeyWordsOnly(event.target.checked)}
        />
      </div>
    </>
  );
};

export default MissedWordGraph;
