import { faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import ScreenReader from 'utils/ScreenReader';

import 'view/style/common/buttons.css';

interface AudioAssistanceButtonProps {
  text: string;
  lang?: string;
  audioSrc?: string;
}

export const AudioAssistanceButton: React.FC<AudioAssistanceButtonProps> = ({
  text,
  lang,
  audioSrc,
}) => {
  const [audio, setAudio] = useState<HTMLAudioElement>();

  const speakWord = () => {
    if (audioSrc) {
      if (!audio) {
        const newAudio = new Audio(audioSrc);
        setAudio(newAudio);
        newAudio.play();
      } else {
        audio.play();
      }
    } else {
      ScreenReader.speak(text, lang);
    }
  };

  if (!text) return null;

  return (
    <div className="audio-assistance-button">
      <FontAwesomeIcon
        icon={faComment}
        onClick={speakWord}
        color="var(--dark-blue)"
        size="xs"
      />
    </div>
  );
};
