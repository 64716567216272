import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ButtonType } from 'utils/types';

import 'view/style/common/buttons.css';

interface SidebarButtonProps {
  id?: string;
  type?: ButtonType;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  text?: string;
  label: string;
  icon?: string;
  fontAwesomeIcon?: IconDefinition;
}

const SidebarButton: React.FC<SidebarButtonProps> = ({
  id,
  type,
  onClick,
  text,
  label,
  icon,
  fontAwesomeIcon,
}) => {
  return (
    <button
      id={id}
      className={`sidebar-button ${type || ''}`}
      onClick={onClick}
    >
      {icon && <img src={icon} alt="" />}
      {fontAwesomeIcon && <FontAwesomeIcon icon={fontAwesomeIcon} size="lg" />}
      <span className="label-medium">{label}</span>
    </button>
  );
};

export default SidebarButton;
