import React, { useEffect, useMemo, useState } from 'react';
import { useUserContext } from 'contexts/UserContext';
import Modal from 'view/components/common/Modal';
import { SearchBar } from 'view/components/common/SearchBar';
import { Filter, FilterOption } from 'view/components/common/Filter';
import Book from 'models/Book';
import { catalogService } from 'services/catalogService';
import { BookRow } from 'view/components/catalog//BookRow';
import { BookReader, ReaderViewType } from 'view/components/common/BookReader';
import contentService from 'services/contentService';
import useApiCall from 'contexts/ApiCall';
import AppSidebar from 'view/components/common/Sidebar';
import AssignmentRow from './AssignmentRow';
import Assignment, { AssignmentType } from 'models/Assignment';
import AssignmentPreview from './AssignmentPreview';
import Question from 'models/Question';
import CachingEngine from 'utils/CachingEngine';
import { debounce } from 'lodash';

import 'view/style/catalog/catalog.css';

const filter_list: string = require('assets/icons/filter_list.svg').default;

interface CatalogFilters {
  title: string;
  genres: FilterOption[];
  lexileRange: number[];
  materialTypes: FilterOption[];
  learnerLevel: FilterOption[];
}

interface CatalogObjects {
  next: string | null;
}

interface CatalogBooks extends CatalogObjects {
  books: Book[];
}

interface CatalogAssignments extends CatalogObjects {
  assignments: Assignment[];
}

interface BookCatalogProps {
  assignmentType?: 'All' | AssignmentType; // TODO: refactor this
  onExit: () => void;
  onSelect?: (book: Book, assignmentType?: string) => void;
  onSelectAssignment?: (
    assignment: Assignment,
    book?: Book,
    questions?: Question[],
  ) => void;
}

export const BookCatalog: React.FC<BookCatalogProps> = ({
  assignmentType = 'All',
  onExit,
  onSelect,
  onSelectAssignment,
}) => {
  const { user } = useUserContext();
  const [books, setBooks] = useState<CatalogBooks>();
  const [likedBooks, setLikedBooks] = useState<CatalogBooks>();
  const [catalogAssignments, setCatalogAssignments] =
    useState<CatalogAssignments>();
  const [selectedBook, setSelectedBook] = useState<Book>();
  const [selectedAssignment, setSelectedAssignment] = useState<Assignment>();
  const [selectedIdx, setSelectedIdx] = useState<number>(0);
  const constants = contentService.getTeacherConstants();
  const [filters, setFilters] = useState<CatalogFilters>({
    title: '',
    genres: constants.genres.map((genre: string) => ({
      is_active: true,
      value: genre,
    })),
    lexileRange: [0, 1400],
    materialTypes: ['Books', 'Lessons', 'Flashcards', 'Pronunciation'].map(
      (v: string) => ({ is_active: true, value: v }),
    ),
    learnerLevel: [
      'Young Learners',
      'Intermediate',
      'Advanced',
      'Adult/Professional',
    ].map((v: string) => ({ is_active: true, value: v })),
  });
  const [viewBook, setViewBook] = useState<boolean>(false);
  const makeApiCall = useApiCall();

  useEffect(() => {
    return () => {
      CachingEngine.deleteData('catalog');
      CachingEngine.deleteData('catalog-likes');
    };
  }, []);

  useEffect(() => {
    if (!user) return;
    if (!books && !likedBooks && !catalogAssignments) {
      makeApiCall(catalogService.getCatalogBooks)
        .then((resp) => setBooks({ books: resp.books, next: resp.next }))
        .catch((error) => alert(error.message));
      makeApiCall(catalogService.getLikedBooks)
        .then((resp) => setLikedBooks({ books: resp.books, next: resp.next }))
        .catch((error) => alert(error.message));
      makeApiCall(catalogService.getCatalogAssignments, assignmentType)
        .then((resp) =>
          setCatalogAssignments({
            assignments: resp.assignments,
            next: resp.next,
          }),
        )
        .catch((error) => alert(error.message));
    }
  }, [
    user,
    books,
    likedBooks,
    catalogAssignments,
    assignmentType,
    makeApiCall,
  ]);

  const updateFilters = (
    title: string,
    genres: FilterOption[],
    difficultyRange: number[],
    learner_levels: FilterOption[],
  ) => {
    CachingEngine.deleteData('catalog');
    CachingEngine.deleteData('catalog-likes');

    makeApiCall(catalogService.getCatalogBooks, null, {
      title: title,
      genre: genres.filter((g) => g.is_active).map((g) => g.value),
      learner_level: learner_levels
        .filter((g) => g.is_active)
        .map((g) => g.value),
      min_difficulty: difficultyRange[0],
      max_difficulty: difficultyRange[1],
    })
      .then((resp) => setBooks({ books: resp.books, next: resp.next }))
      .catch((error) => alert(error.message));
    makeApiCall(catalogService.getLikedBooks, null, {
      title: title,
      genre: genres.filter((g) => g.is_active).map((g) => g.value),
      learner_level: learner_levels
        .filter((g) => g.is_active)
        .map((g) => g.value),
      min_difficulty: difficultyRange[0],
      max_difficulty: difficultyRange[1],
    })
      .then((resp) => setLikedBooks({ books: resp.books, next: resp.next }))
      .catch((error) => alert(error.message));
    if (title !== '') {
      makeApiCall(
        catalogService.getCatalogAssignments,
        assignmentType,
        null,
        title !== '' ? title : undefined,
        learner_levels.filter((g) => g.is_active).map((g) => g.value),
      )
        .then((resp) =>
          setCatalogAssignments({
            assignments: resp.assignments,
            next: resp.next,
          }),
        )
        .catch((error) => alert(error.message));
    }
  };

  const handleLikeBook = (book: Book, liked: boolean) => {
    if (!user) return;
    makeApiCall(catalogService.addLikedBook, user, book, liked)
      .then((resp) => {
        if (liked) {
          setLikedBooks((prev) => {
            return {
              next: prev?.next ?? null,
              books: [...(prev?.books ?? []), book],
            };
          });
        } else {
          setLikedBooks((prev) => {
            return {
              next: prev?.next ?? null,
              books: [...(prev?.books ?? []).filter((b) => b.id !== book.id)],
            };
          });
        }
      })
      .catch((error) => alert(error.message));
  };

  const handleSearchTermChange = (search: string) => {
    setFilters((prev) => {
      return { ...prev, title: search };
    });
    updateFilters(
      search,
      filters.genres,
      filters.lexileRange,
      filters.learnerLevel,
    );
  };

  const handleReadingDifficutlyRangeChange = (
    event: Event,
    value: number | number[],
    activeThumb: number,
  ) => {
    const values = value as number[];
    setFilters((prev) => {
      return { ...prev, lexileRange: values };
    });
    updateFilters(filters.title, filters.genres, values, filters.learnerLevel);
  };

  const debouncedReadingDifficultyChange = debounce(
    handleReadingDifficutlyRangeChange,
    500,
  );

  const handleGenreFilterChange = (checked: boolean, genre: FilterOption) => {
    var updatedGenreOptions = filters.genres.slice();

    if (genre.value === 'All' && checked) {
      updatedGenreOptions.forEach(
        (_, index, arr) => (arr[index].is_active = true),
      );
    } else if (genre.value !== 'All') {
      const genreIdx = updatedGenreOptions.indexOf(genre);
      updatedGenreOptions[genreIdx] = {
        ...updatedGenreOptions[genreIdx],
        is_active: checked,
      };
    }

    setFilters((prev) => {
      return { ...prev, genres: updatedGenreOptions };
    });
    updateFilters(
      filters.title,
      updatedGenreOptions,
      filters.lexileRange,
      filters.learnerLevel,
    );
  };

  const handleLearnerLevelFilterChange = (
    checked: boolean,
    learnerLevel: FilterOption,
  ) => {
    var updatedLearnerLevelOptions = filters.learnerLevel.slice();

    if (learnerLevel.value === 'All' && checked) {
      updatedLearnerLevelOptions.forEach(
        (_, index, arr) => (arr[index].is_active = true),
      );
    } else if (learnerLevel.value !== 'All') {
      const idx = updatedLearnerLevelOptions.indexOf(learnerLevel);
      updatedLearnerLevelOptions[idx] = {
        ...updatedLearnerLevelOptions[idx],
        is_active: checked,
      };
    }

    setFilters((prev) => {
      return { ...prev, learnerLevel: updatedLearnerLevelOptions };
    });
    updateFilters(
      filters.title,
      filters.genres,
      filters.lexileRange,
      updatedLearnerLevelOptions,
    );
  };

  const handleSelectBook = async (book: Book) => {
    if (!book.html_url) {
      alert("We're sorry, this book is currently unavailable");
      setSelectedBook(undefined);
      setViewBook(false);
      return;
    }
    if (book && (!book.html_content || book.html_content === '')) {
      await book.getHtmlContent();
    }
    setSelectedBook(book);
    setViewBook(true);
  };

  const handleSelectAssignment = async (assignment: Assignment) => {
    setSelectedAssignment(assignment);
  };

  const getNextBooks = async () => {
    return makeApiCall(catalogService.getCatalogBooks, books?.next).then(
      (resp) =>
        setBooks((prev) => {
          return {
            next: resp.next,
            books: [...(prev?.books ?? []), ...resp.books],
          };
        }),
    );
  };

  const getNextLikedBooks = async () => {
    return makeApiCall(catalogService.getLikedBooks, likedBooks?.next).then(
      (resp) =>
        setLikedBooks((prev) => {
          return {
            next: resp.next,
            books: [...(prev?.books ?? []), ...resp.books],
          };
        }),
    );
  };

  const getNextAssignments = async () => {
    return makeApiCall(
      catalogService.getCatalogAssignments,
      assignmentType,
      catalogAssignments?.next,
    ).then((resp) =>
      setCatalogAssignments((prev) => {
        return {
          next: resp.next,
          assignments: [...(prev?.assignments ?? []), ...resp.assignments],
        };
      }),
    );
  };

  const likedBookIds = useMemo(
    () => new Set(likedBooks?.books.map((lb: Book) => lb.id)),
    [likedBooks],
  );

  const tabLabels = ['Readings', 'Assignments', 'Favorites'];
  const tabContents = [
    <BookRow
      label="Readings"
      liked={likedBookIds}
      books={books?.books ?? []}
      hasNextPage={!!books?.next}
      loadNextPage={getNextBooks}
      // onSelect={onSelect}
      onLikeBook={handleLikeBook}
      onView={handleSelectBook}
    />,
    <AssignmentRow
      label="My Activities"
      liked={new Set()}
      assignments={catalogAssignments?.assignments ?? []}
      hasNextPage={!!catalogAssignments?.next}
      loadNextPage={getNextAssignments}
      onSelect={handleSelectAssignment}
    />,
    <BookRow
      label="Liked Readings"
      liked={likedBookIds}
      books={likedBooks?.books ?? []}
      hasNextPage={!!likedBooks?.next}
      loadNextPage={getNextLikedBooks}
      // onSelect={onSelect}
      onLikeBook={handleLikeBook}
      onView={handleSelectBook}
    />,
  ];

  return (
    <div className="app-page-content">
      <AppSidebar onExit={onExit}>
        <>
          <div className="sidebar-content-data">
            <SearchBar onSearch={handleSearchTermChange} />
          </div>
          <div className="sidebar-content-data">
            <div className="sidebar-content-label">
              <img src={filter_list} alt="" />
              <label className="label-small">Filter</label>
            </div>
            <Filter
              type="range"
              label="Lexile Level"
              max={1400}
              rangeInput={filters.lexileRange}
              onRangeChange={debouncedReadingDifficultyChange}
            />
            <Filter
              type="multichoice"
              label="Genre"
              options={filters.genres}
              onOptionChange={handleGenreFilterChange}
            />
            <Filter
              type="multichoice"
              label="Learner Level"
              options={filters.learnerLevel}
              onOptionChange={handleLearnerLevelFilterChange}
            />
          </div>
        </>
      </AppSidebar>
      <div className="app-main-content">
        <h1>Book Catalog</h1>
        <div className="book-catalog-content">
          <div className="tab-headers">
            {tabLabels.map((label, idx) => (
              <span
                key={`tab-header-label-${idx}-${label}`}
                className={`tab-header-label ${
                  idx === selectedIdx ? 'active-label' : ''
                }`}
                onClick={() => setSelectedIdx(idx)}
              >
                {label}
              </span>
            ))}
          </div>
          {tabContents[selectedIdx]}
        </div>
      </div>
      {selectedBook && viewBook && (
        <Modal isOpen={viewBook} onClose={() => setViewBook(false)}>
          <div className="reading-container">
            <BookReader
              book={selectedBook}
              startIndex={0}
              isReading={false}
              showMissedWordPopup={false}
              setIsReading={() => {}}
              onMissedWord={() => {}}
              selectView={!!onSelect}
              onSelect={onSelect}
              viewType={ReaderViewType.CATALOG}
            />
          </div>
        </Modal>
      )}
      {selectedAssignment && (
        <Modal
          isOpen={selectedAssignment !== undefined}
          onClose={() => setSelectedAssignment(undefined)}
          style={{
            width: '100%',
            maxWidth: '100%',
            height: '90%',
          }}
        >
          <AssignmentPreview
            assignment={selectedAssignment}
            onSelect={onSelectAssignment}
            onExit={() => setSelectedAssignment(undefined)}
          />
        </Modal>
      )}
    </div>
  );
};
