import Assignment from 'models/Assignment';
import Book from 'models/Book';
import { useParams } from 'react-router-dom';
import ActionButton from 'view/components/buttons/ActionButton';
import { TextEditor } from '../TextEditor';
import AssignmentSidebar from '../AssignmentSidebar';
import {
  faEarListen,
  faListUl,
  faSave,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ListeningAssignmentFormProps {
  assignment: Assignment;
  book: Book;
  coverImage?: string;
  updateAssignment: (event: any) => void;
  updateBook: (event: any) => void;
  handleInputFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBookCoverChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCreateAssignment: (generateAudio: boolean) => void;
  handleDeleteAssignment: () => void;
  setAssignment: React.Dispatch<React.SetStateAction<Assignment | undefined>>;
  setViewBooks: () => void;
  setViewQuestions: () => void;
  handleUpdateText: (richText: string) => void;
  onCancel: () => void;
  onGenerateText: (text: string) => void;
}

const ListeningAssignmentForm: React.FC<ListeningAssignmentFormProps> = ({
  assignment,
  book,
  coverImage,
  updateAssignment,
  updateBook,
  handleInputFileChange,
  handleBookCoverChange,
  handleCreateAssignment,
  handleDeleteAssignment,
  setAssignment,
  setViewBooks,
  setViewQuestions,
  handleUpdateText,
  onCancel,
  onGenerateText,
}) => {
  const { assignmentId } = useParams();

  return (
    <div className="app-page-content">
      <AssignmentSidebar
        assignment={assignment}
        book={book}
        options={['audio', 'book-cover']}
        coverImage={coverImage}
        handleBookCoverChange={handleBookCoverChange}
        handleInputFileChange={handleInputFileChange}
        onCancel={onCancel}
        setAssignment={setAssignment}
        updateAssignment={updateAssignment}
        updateBook={updateBook}
      />
      <div className="app-main-content">
        <div className="book-details-editor">
          <div className="assignment-editor-title">
            <FontAwesomeIcon
              icon={faEarListen}
              size="3x"
              color="var(--dark-green)"
            />
            <input
              className="title"
              type="text"
              placeholder="Book Title"
              id="title"
              value={book?.title}
              onChange={(event) => {
                updateBook(event);
                updateAssignment(event);
              }}
            />
          </div>
        </div>
        <TextEditor
          initialValue={book?.html_content ?? ''}
          onChange={handleUpdateText}
          onGenerateText={onGenerateText}
        />
        <div className="assignment-editor-actions">
          {assignmentId && (
            <ActionButton
              type="delete"
              onClick={handleDeleteAssignment}
              label="Delete"
              icon={faTrash}
              requiresConfirmation={true}
              confirmationMessage="Are you sure you want to delete this assignment? All submissions and grades will be lost."
            />
          )}
          <ActionButton
            type="edit"
            onClick={setViewQuestions}
            label="Questions"
            icon={faListUl}
          />
          <ActionButton
            type="go"
            onClick={() => handleCreateAssignment(true)}
            label={assignmentId ? 'Update' : 'Upload'}
            icon={faSave}
          />
        </div>
      </div>
    </div>
  );
};

export default ListeningAssignmentForm;
