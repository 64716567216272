import {
  faArrowLeft,
  faArrowRight,
  faCheckCircle,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Button from 'view/components/buttons/Button';
import IconButton from 'view/components/buttons/IconButton';

import 'view/style/student/components/assignment/assignmentControls.css';

interface MutlipartControlsProps {
  questionComplete: boolean;
  questionIdx: number;
  teacherView: boolean;
  showPrev: boolean;
  showNext: boolean;
  onPrevious: () => void;
  onNext: () => void;
  onSubmit: () => void;
}

const MultipartControls: React.FC<MutlipartControlsProps> = ({
  questionComplete,
  questionIdx,
  teacherView,
  showNext,
  showPrev,
  onPrevious,
  onNext,
  onSubmit,
}) => {
  return (
    <div className="assignment-controls">
      <FontAwesomeIcon icon={questionComplete ? faCheckCircle : faPencil} />
      {showPrev && <IconButton onClick={onPrevious} icon={faArrowLeft} />}
      <Button
        type="go"
        onClick={onSubmit}
        label={teacherView ? 'Grade' : 'Submit'}
        text={teacherView ? 'grade' : 'submit'}
      />
      {showNext && <IconButton onClick={onNext} icon={faArrowRight} />}
      <label className="label-small">Question {questionIdx + 1}</label>
    </div>
  );
};

export default MultipartControls;
