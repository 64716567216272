import React, { useEffect, useRef, useState } from 'react';
import Book from 'models/Book';
import BookCard from './BookCard';

import 'view/style/catalog/components/bookRow.css';

interface BookRowProps {
  label: string;
  books: Book[];
  hasNextPage: boolean;
  liked: Set<string>;
  loadNextPage: () => Promise<void>;
  onView: (book: Book) => void;
  onSelect?: (book: Book) => void;
  onLikeBook: (book: Book, liked: boolean) => void;
}

export const BookRow: React.FC<BookRowProps> = ({
  label,
  books,
  hasNextPage,
  liked,
  onSelect,
  loadNextPage,
  onLikeBook,
  onView,
}) => {
  const rowRef = useRef<HTMLDivElement>(null);
  const lastBookRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!lastBookRef.current || !hasNextPage || isLoading) return;

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        // Mark that we are now fetching
        setIsLoading(true);
        loadNextPage().finally(() => {
          // Once loadNextPage is done (whether success or error),
          // set isLoading back to false
          setIsLoading(false);
        });
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });
    observer.observe(lastBookRef.current);

    return () => observer.disconnect();
  }, [books, hasNextPage, isLoading, loadNextPage]);

  return (
    <div className="book-catalog-row" ref={rowRef}>
      {books.map((book, index) => {
        const isLast = index === books.length - 1;
        return isLast ? (
          <BookCard
            key={`${label}-book-card-${book.id}`}
            book={book}
            onView={onView}
            onLike={onLikeBook}
            onSelect={onSelect}
            liked={liked.has(book.id)}
            ref={lastBookRef}
          />
        ) : (
          <BookCard
            key={`${label}-book-card-${book.id}`}
            book={book}
            onView={onView}
            onSelect={onSelect}
            onLike={onLikeBook}
            liked={liked.has(book.id)}
          />
        );
      })}
    </div>
  );
};
