import React, { useState } from 'react';
import ActionButton from 'view/components/buttons/ActionButton';
import Book from 'models/Book';
import { useParams } from 'react-router-dom';
import contentService from 'services/contentService';
import { TextEditor } from 'view/components/teacher/assignment/TextEditor';
import { FileInput } from 'view/components/teacher/assignment/FileInputs';
import { VersionTag } from 'view/components/common/VersionTag';
import Button from 'view/components/buttons/Button';
import { WordList } from 'view/components/common/WordList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faBookOpen,
  faPlusSquare,
  faSave,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

interface AdminBookEditorProps {
  book: Book;
  coverImage?: string;
  updateBook: (event: any) => void;
  setBook: React.Dispatch<React.SetStateAction<Book | undefined>>;
  handleInputFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBookCoverChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCreateBook: (removeCover: boolean) => void;
  handleDeleteBook: () => void;
  handleUpdateText: (richText: string) => void;
  onCancel: () => void;
}

const AdminBookEditor: React.FC<AdminBookEditorProps> = ({
  book,
  coverImage,
  updateBook,
  setBook,
  handleInputFileChange,
  handleBookCoverChange,
  handleCreateBook,
  handleDeleteBook,
  handleUpdateText,
  onCancel,
}) => {
  const [removeCover, setRemoveCover] = useState<boolean>(false);
  const [tag, setTag] = useState<string>('');
  const { bookId } = useParams();
  const constants = contentService.getTeacherConstants();

  const handleAddTag = () => {
    if (tag === '' || !tag) return;
    setBook((prev: any) =>
      Book.fromServerBook({
        ...prev,
        tags: [...prev.tags, tag],
      }),
    );
    setTag('');
  };

  const handleRemoveTag = (tagToDelete: string) => {
    setBook((prev: any) =>
      Book.fromServerBook({
        ...prev,
        tags: [...prev.tags.filter((tt: string) => tt !== tagToDelete)],
      }),
    );
  };

  return (
    <div className="app-page-content">
      <div className="app-sidebar">
        <div className="col">
          <Button
            onClick={onCancel}
            fontAwesomeIcon={faArrowLeft}
            label="Back"
          />
          <div className="assignment-sidebar-content">
            <FileInput
              label="Upload file"
              accept=".pdf, .txt, .jpeg, .jpg, .png, .heic"
              onFileChange={handleInputFileChange}
            />
            <div className="assignment-attribute-container">
              <FileInput
                id={'book-cover-upload'}
                label="Cover image"
                accept=".jpeg, .jpg, .png"
                onFileChange={handleBookCoverChange}
              />
              <div className="book-card-cover">
                <img src={coverImage ?? book.cover_url} alt="" />
              </div>
              {book.cover_url && (
                <ActionButton
                  type={removeCover ? 'go' : 'delete'}
                  label={removeCover ? 'Keep cover' : 'Remove cover'}
                  onClick={() => setRemoveCover((prev) => !prev)}
                />
              )}
            </div>
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <label className="label-normal">Tags</label>
              </div>
              <div className="row stretch">
                <input
                  type="text"
                  placeholder="enter a tag"
                  value={tag}
                  onChange={(event) => setTag(event.target.value)}
                />
                <FontAwesomeIcon icon={faPlusSquare} onClick={handleAddTag} />
              </div>
              <WordList
                words={Array.from(book.tags)}
                onDeleteWord={handleRemoveTag}
              />
            </div>
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <label className="label-normal">Author</label>
              </div>
              <input
                className="normal"
                type="text"
                placeholder="Author"
                id="author"
                value={book?.author}
                onChange={updateBook}
              />
            </div>
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <label className="label-normal">Lexile level</label>
              </div>
              <input
                className="normal"
                type="number"
                placeholder="Reading Difficulty"
                id="difficulty"
                value={book?.difficulty}
                onChange={updateBook}
              />
            </div>
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <label className="label-normal">Genre</label>
              </div>
              <select
                className="normal"
                id="genre"
                value={book?.genre}
                onChange={updateBook}
              >
                {constants.genres.map((genre: string) => (
                  <option key={`option-${genre}`} value={genre}>
                    {genre}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <VersionTag />
      </div>
      <div className="app-main-content">
        <div className="book-details-editor">
          <div className="assignment-editor-title">
            <FontAwesomeIcon icon={faBookOpen} size="2x" />
            <input
              className="title"
              type="text"
              placeholder="Book Title"
              id="title"
              value={book?.title}
              onChange={(event) => {
                updateBook(event);
              }}
            />
          </div>
        </div>
        <TextEditor
          initialValue={book?.html_content ?? ''}
          onChange={handleUpdateText}
        />
        <div className="assignment-editor-actions">
          <ActionButton
            type="delete"
            onClick={handleDeleteBook}
            label="Delete"
            icon={faTrash}
          />
          <ActionButton
            type="go"
            onClick={() => handleCreateBook(removeCover)}
            label={bookId ? 'Update' : 'Upload'}
            icon={faSave}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminBookEditor;
