import React, { useState } from 'react';
import Assignment from 'models/Assignment';
import ActionButton from 'view/components/buttons/ActionButton';
import contentService from 'services/contentService';
import { faGraduationCap, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CatalogTags } from './CatalogTags';
import BookCover from './BookCover';

import 'view/style/catalog/components/bookCard.css';

interface AssignmentCardProps {
  assignment: Assignment;
  liked: boolean;
  onLike: (assignment: Assignment, liked: boolean) => void;
  onView: (assignment: Assignment) => void;
  onSelect?: (assignment: Assignment) => void;
}

type AssignmentDetail = {
  label: string;
  accessor: keyof Assignment;
};

const AssignmentCard = React.forwardRef<HTMLDivElement, AssignmentCardProps>(
  ({ assignment, liked, onLike, onView, onSelect }, ref) => {
    const formContent = contentService.getCatalogPage().assignment_preview;
    const [isLiked, setIsLiked] = useState<boolean>(liked);

    const handleLike = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      event?.stopPropagation();
      setIsLiked((prev) => {
        onLike(assignment, !prev);
        return !prev;
      });
    };

    const handleViewAssignment = () => {
      onView(assignment);
    };

    return (
      <div className="book-card" onClick={handleViewAssignment} ref={ref}>
        <BookCover />
        <div className="book-card-details">
          <div className="row spaced">
            <span className="label-large">{assignment.title}</span>
            <FontAwesomeIcon
              icon={faStar}
              onClick={handleLike}
              color={isLiked ? '#447D00' : '#D4D4D4'}
            />
          </div>
          {assignment.learner_level.length > 0 && (
            <div key={`assignment-detail-level`} className="book-card-metadata">
              <div className="row">
                <FontAwesomeIcon icon={faGraduationCap} size="xs" />
                <label className="label-normal">
                  {assignment.learner_level.join(' / ')}
                </label>
              </div>
            </div>
          )}
          <div className="col">
            {formContent.details.map((detail: AssignmentDetail) => (
              <div
                key={`assignment-detail-${detail.label}`}
                className="book-card-metadata"
                style={{ justifyContent: 'space-between' }}
              >
                <label className="label-small">{detail.label}:</label>
                <label className="label-small">
                  {assignment[detail.accessor]?.toString() ?? 'N/A'}
                </label>
              </div>
            ))}
            {assignment.topics.length > 0 && (
              <div
                key={`assignment-detail-topics`}
                className="book-card-metadata"
              >
                <CatalogTags tags={assignment.topics} />
              </div>
            )}
            {assignment.topics.length > 0 && (
              <div
                key={`assignment-detail-tags`}
                className="book-card-metadata"
              >
                <CatalogTags tags={assignment.tags} />
              </div>
            )}
          </div>
          {onSelect && (
            <ActionButton
              type="go"
              onClick={() => onSelect(assignment)}
              label="Select"
            />
          )}
        </div>
      </div>
    );
  },
);

export default AssignmentCard;
