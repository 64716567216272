import {
  faChevronDown,
  faChevronRight,
  faQuestionCircle,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEventHandler, ReactNode, useState } from 'react';

type PanelDropdownProps = {
  icon?: IconDefinition;
  label: string;
  hint?: string;
  children?: ReactNode;
  startExpanded?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const PanelDropdown: React.FC<PanelDropdownProps> = ({
  label,
  hint,
  icon,
  children,
  startExpanded = false,
  onClick,
}) => {
  const [expanded, setExpanded] = useState<boolean>(startExpanded);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onClick) onClick(event);
    setExpanded((prev) => !prev);
  };

  return (
    <>
      <div className="item-panel selected" onClick={handleClick} title={hint}>
        <div className="row">
          {icon && <FontAwesomeIcon icon={icon} size="xl" />}
          <label className="label-medium">{label}</label>
          {hint && (
            <span className="tooltip-container" title={hint}>
              <FontAwesomeIcon icon={faQuestionCircle} />
            </span>
          )}
        </div>
        <FontAwesomeIcon
          icon={expanded ? faChevronDown : faChevronRight}
          size="lg"
        />
      </div>
      {expanded && children}
    </>
  );
};

export default PanelDropdown;
