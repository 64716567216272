import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConfirmation } from 'contexts/ConfirmationContext';
import React from 'react';

import 'view/style/common/buttons.css';

type IconButtonType = 'transparent';

// const icons: Record<string, string> = {
//   settings: require('assets/icons/settings.svg').default,
//   delete: require('assets/icons/delete_forever.svg').default,
//   add: require('assets/icons/person_add_green.svg').default,
//   confirm: require('assets/icons/check-solid.svg').default,
//   cancel: require('assets/icons/xmark-solid.svg').default,
//   reverse: require('assets/icons/rotate.svg').default,
//   hide: require('assets/icons/eye-slash-solid-white.svg').default,
//   back: require('assets/icons/arrow_back.svg').default,
//   next: require('assets/icons/arrow_next.svg').default,
//   plus: require('assets/icons/add_circle_outline.svg').default,
// };

interface IconButtonProps {
  icon: IconDefinition;
  type?: IconButtonType;
  disabled?: boolean;
  color?: string;
  size?: SizeProp;
  requiresConfirmation?: boolean;
  confirmationMessage?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  type,
  disabled,
  color,
  size = 'lg',
  requiresConfirmation = false,
  confirmationMessage = '',
  onClick,
}) => {
  const { openModal } = useConfirmation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!onClick) return;
    if (requiresConfirmation) {
      event.preventDefault();
      event.stopPropagation();
      openModal(confirmationMessage, onClick);
    } else {
      onClick(event);
    }
  };

  return (
    <button
      className={`icon-button ${type || ''}`}
      onClick={handleClick}
      disabled={disabled}
    >
      <FontAwesomeIcon icon={icon} color={color} size={size} />
    </button>
  );
};

export default IconButton;
