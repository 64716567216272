import {
  faCheckCircle,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';

interface QuestionFeedbackProps {
  feedback: string;
  teacherView: boolean;
  grade: number;
  onGradeResponse: (grade: number, feedback: string) => void;
}

const QuestionFeedback: React.FC<QuestionFeedbackProps> = ({
  feedback,
  teacherView,
  grade,
  onGradeResponse,
}) => {
  return (
    <>
      {teacherView ? (
        <div className="row stretch">
          <label className="label-normal">Feedback</label>
          <textarea
            id="question-feedback"
            value={feedback}
            onChange={(event) => onGradeResponse(grade, event.target.value)}
          />
          <label className="label-normal">Grade</label>
          <input
            type="number"
            value={grade}
            onChange={(event) =>
              onGradeResponse(Number(event.target.value), feedback)
            }
          />
          /100
        </div>
      ) : (
        <div className="row">
          <FontAwesomeIcon
            icon={grade > 0 ? faCheckCircle : faXmarkCircle}
            color={grade > 0 ? 'var(--dark-green)' : 'var(--dark-pink)'}
          />
          <label className="label-small">{feedback}</label>
          <AudioAssistanceButton text={feedback ?? ''} />
        </div>
      )}
    </>
  );
};

export default QuestionFeedback;
