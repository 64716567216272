import React from 'react';
import Classroom from 'models/Classroom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPlusSquare } from '@fortawesome/free-solid-svg-icons';

import 'view/style/common/components/classroomList.css';

interface ClassroomListProps {
  classrooms: Classroom[];
  onEnterClassroom: (classroom: Classroom) => void;
  openAddClassroomForm: (open: boolean) => void;
}

export const ClassroomList: React.FC<ClassroomListProps> = ({
  classrooms,
  onEnterClassroom,
  openAddClassroomForm,
}) => {
  return (
    <div className="classroom-list">
      {classrooms.map((classroom) => (
        <div
          key={`classroom-${classroom.id}`}
          id={`classroom-${classroom.id}`}
          className="classroom-panel"
          onClick={() => onEnterClassroom(classroom)}
        >
          <h3>{classroom.name}</h3>
          <FontAwesomeIcon icon={faArrowRight} size="xl" />
        </div>
      ))}
      <div
        className="join-classroom-panel"
        onClick={() => openAddClassroomForm(true)}
      >
        <FontAwesomeIcon icon={faPlusSquare} size="lg" />
        <span className="label-normal">Add New Classroom</span>
      </div>
    </div>
  );
};
