import {
  faArrowRight,
  faQuestionCircle,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEventHandler } from 'react';

type PanelButtonProps = {
  icon?: IconDefinition;
  label: string;
  hint?: string;
  onClick: MouseEventHandler<HTMLDivElement>;
};

const PanelButton: React.FC<PanelButtonProps> = ({
  label,
  hint,
  icon,
  onClick,
}) => {
  return (
    <div className="classroom-panel" onClick={onClick} title={hint}>
      <div className="row">
        {icon && <FontAwesomeIcon icon={icon} size="xl" />}
        <label className="label-medium">{label}</label>
        {hint && (
          <span className="tooltip-container" title={hint}>
            <FontAwesomeIcon icon={faQuestionCircle} />
          </span>
        )}
      </div>
      <FontAwesomeIcon icon={faArrowRight} size="xl" />
    </div>
  );
};

export default PanelButton;
