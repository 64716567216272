import React, { useEffect, useMemo, useState } from 'react';
import { useUserContext } from 'contexts/UserContext';
import StudentGradebook from 'models/StudentGradebook';
import studentService from 'services/studentService';
import StudentProfile from 'models/StudentProfile';
import { useClassroomContext } from 'contexts/ClassroomContext';
import { useNavigate, useParams } from 'react-router-dom';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import { useAssignmentContext } from 'contexts/AssignmentContext';
import GradebookEntry from 'models/StudentGradebookEntry';
import { formatDate } from 'utils/utils';
import StudentGradebookEntry from 'models/StudentGradebookEntry';
import { AppPage } from 'view/components/common/AppPage';
import userService from 'services/userService';
import useApiCall from 'contexts/ApiCall';
import AppSidebar from 'view/components/common/Sidebar';
import contentService from 'services/contentService';
import SidebarButton from 'view/components/buttons/SidebarButton';
import { SearchBar } from 'view/components/common/SearchBar';
import {
  faBookOpen,
  faCheckCircle,
  faExclamationCircle,
  faSchool,
} from '@fortawesome/free-solid-svg-icons';
import PanelDropdown from 'view/components/common/PanelDropdown';
import AssignmentList from 'view/components/student/classroom/AssignmentList';

import 'view/style/student/classroom.css';

export const StudentClassPage: React.FC = () => {
  const { user } = useUserContext();
  const { classroom, setClassroom } = useClassroomContext();
  const { classroomId } = useParams();
  const { setAssignment } = useAssignmentContext();
  const [gradebook, setGradebook] = useState<StudentGradebook>();
  const [studentProfile, setStudentProfile] = useState<StudentProfile>();
  const [previewEntry, setPreviewEntry] = useState<StudentGradebookEntry>();
  const pageContent = contentService.getStudentClassroomPage();
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || classroom || !classroomId) return;
    makeApiCall(userService.getClassroomById, classroomId)
      .then((resp) => {
        setClassroom(resp.classroom);
        setStudentProfile(resp.student);
      })
      .catch((error) => navigate(-1));
  }, [user, classroom, classroomId, navigate, setClassroom, makeApiCall]);

  useEffect(() => {
    if (studentProfile || !user || !classroom) return;
    makeApiCall(studentService.getStudentProfile, user, classroom)
      .then((respStudentProfile) => setStudentProfile(respStudentProfile))
      .catch((error) => alert(error.message));
  }, [studentProfile, user, classroom, makeApiCall]);

  useEffect(() => {
    if (gradebook || !studentProfile || !user || !classroom) return;
    makeApiCall(
      studentService.getGradebook,
      studentProfile.id,
      classroom?.getId(),
    )
      .then((respGradebook) => setGradebook(respGradebook))
      .catch((error) => alert(error.message));
  }, [gradebook, user, studentProfile, classroom, makeApiCall]);

  const handleAssignmentSelect = (entry: GradebookEntry) => {
    if (previewEntry === entry) {
      setAssignment(entry);
      navigate(`assignment/${entry.assignment.getId()}/`);
    }
    setPreviewEntry(entry);
  };

  const handleExitClassroom = () => {
    setClassroom(null);
    navigate('/student/home/');
  };

  const filterGradebook = (searchTerm: string) => {
    if (!gradebook) return;
    const filteredGradebook = StudentGradebook.fromStudentGradebook(gradebook);
    filteredGradebook.entries.forEach(
      (entry) =>
        (entry.visible = entry.assignment.title
          .toLowerCase()
          .includes(searchTerm.toLowerCase())),
    );
    setGradebook(filteredGradebook);
  };

  const dateOrNull = (dateString?: string | null) => {
    if (dateString) return new Date(dateString);
    return null;
  };

  const lateAssignments: StudentGradebookEntry[] = useMemo(() => {
    if (!gradebook) return [];
    return gradebook?.entries.filter((entry) => {
      const dueDate = dateOrNull(entry.assignment.due_date);
      const submittedDate = dateOrNull(
        entry.assignment_submission.date_completed,
      );
      const now = new Date();

      return entry.visible && dueDate && !submittedDate && dueDate < now;
    });
  }, [gradebook]);

  const todoAssignments: StudentGradebookEntry[] = useMemo(() => {
    if (!gradebook) return [];
    return gradebook?.entries.filter((entry) => {
      const dueDate = dateOrNull(entry.assignment.due_date);
      const submittedDate = dateOrNull(
        entry.assignment_submission.date_completed,
      );
      const now = new Date();
      return (
        entry.visible &&
        ((!dueDate && !submittedDate) ||
          (dueDate && !submittedDate && dueDate > now))
      );
    });
  }, [gradebook]);

  const doneAssignments: StudentGradebookEntry[] = useMemo(() => {
    if (!gradebook) return [];
    return gradebook?.entries.filter((entry) => {
      const submittedDate = dateOrNull(
        entry.assignment_submission.date_completed,
      );
      return entry.visible && !!submittedDate;
    });
  }, [gradebook]);

  return (
    <AppPage>
      <div className="app-page-content">
        <AppSidebar onExit={handleExitClassroom}>
          <>
            <SearchBar onSearch={filterGradebook} />
            <div className="sidebar-content-data">
              <p>{pageContent.sidebar.average.title}</p>
              <b>{gradebook ? gradebook.averages.grade : 0}%</b>
            </div>
            {previewEntry && (
              <div className="sidebar-content-data">
                <div className="assignment-details-container">
                  <div className="assignment-detail-header">
                    <label className="label-small">
                      {pageContent.sidebar.assignment_preview.title}
                    </label>
                    <AudioAssistanceButton text="Selected Assignment" />
                  </div>
                  <div className="assignment-details-list">
                    <div className="assignment-detail">
                      <label className="label-small">
                        {
                          pageContent.sidebar.assignment_preview.attributes[0]
                            .label
                        }
                      </label>
                      <label className="label-small">
                        {previewEntry.assignment_submission.grade}%
                      </label>
                    </div>
                    <div className="assignment-detail">
                      <label className="label-small">
                        {
                          pageContent.sidebar.assignment_preview.attributes[1]
                            .label
                        }
                      </label>
                      <label className="label-small">
                        {previewEntry.assignment_submission.completion_score}%
                      </label>
                    </div>
                    <div className="assignment-detail">
                      <label className="label-small">
                        {
                          pageContent.sidebar.assignment_preview.attributes[2]
                            .label
                        }
                      </label>
                      <label className="label-small">
                        {previewEntry.assignment.due_date
                          ? formatDate(
                              new Date(previewEntry.assignment.due_date),
                            )
                          : 'No due date'}
                      </label>
                    </div>
                    <div className="assignment-detail">
                      <label className="label-small">
                        {
                          pageContent.sidebar.assignment_preview.attributes[3]
                            .label
                        }
                      </label>
                      <label className="label-small">
                        {previewEntry.assignment.start_date
                          ? formatDate(
                              new Date(previewEntry.assignment.start_date),
                            )
                          : 'Any time'}
                      </label>
                    </div>
                    <div className="assignment-detail">
                      <label className="label-small">
                        {
                          pageContent.sidebar.assignment_preview.attributes[4]
                            .label
                        }
                      </label>
                      <label className="label-small">
                        {previewEntry.assignment_submission.questions_answered}/
                        {previewEntry.assignment.num_questions}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <SidebarButton
              fontAwesomeIcon={faSchool}
              label="Practice"
              text="practice"
              onClick={() => navigate(`practice/${studentProfile?.id}`)}
            />
          </>
        </AppSidebar>
        <div className="app-main-content">
          <h1>{classroom?.name}</h1>
          <div className="item-list">
            <PanelDropdown
              icon={faExclamationCircle}
              label={`Overdue (${lateAssignments.length})`}
              startExpanded={true}
            >
              <AssignmentList
                type="late"
                assignments={lateAssignments}
                selected={previewEntry}
                onSelect={handleAssignmentSelect}
              />
            </PanelDropdown>
            <PanelDropdown
              icon={faBookOpen}
              label={`To do (${todoAssignments.length})`}
              startExpanded={true}
            >
              <AssignmentList
                type="todo"
                assignments={todoAssignments}
                selected={previewEntry}
                onSelect={handleAssignmentSelect}
              />
            </PanelDropdown>
            <PanelDropdown
              icon={faCheckCircle}
              label={`Done (${doneAssignments.length})`}
            >
              <AssignmentList
                type="done"
                assignments={doneAssignments}
                selected={previewEntry}
                onSelect={handleAssignmentSelect}
              />
            </PanelDropdown>
          </div>
        </div>
      </div>
    </AppPage>
  );
};
