import TeacherGradebook from 'models/TeacherGradebook';
import TeacherGradebookEntry from 'models/TeacherGradebookEntry';
import { useMemo } from 'react';
import contentService from 'services/contentService';
import { ChartData, ChartOptions } from 'chart.js';
import { Bar } from 'react-chartjs-2';

interface ClassComparisonChartProps {
  student?: TeacherGradebookEntry;
  gradebook: TeacherGradebook;
}

const ClassComparisonChart: React.FC<ClassComparisonChartProps> = ({
  student,
  gradebook,
}) => {
  const content = contentService.getProgressReportContent('English');
  const classAverages = gradebook.averages;

  const snapshotChartData = useMemo<ChartData<'bar'>>(() => {
    var datasets = [
      {
        label: content.snapshotChart.datasetLabel,
        data: [
          classAverages.grade,
          classAverages.completion_score,
          classAverages.correctness_score,
          classAverages.key_word_accuracy_score,
        ],
        backgroundColor: '#447D00',
        borderColor: '#447D00',
        borderWidth: 1,
      },
    ];
    if (student) {
      datasets.push({
        label: student.displayName(),
        data: [
          student.averages.grade,
          student.averages.completion_score,
          student.averages.correctness_score,
          student.averages.key_word_accuracy_score,
        ],
        backgroundColor: '#5A007D',
        borderColor: '#5A007D',
        borderWidth: 1,
      });
    }
    return {
      labels: content.snapshotChart.labels,
      datasets: datasets,
    };
  }, [content, student, classAverages]);

  const snapshotChartOptions = useMemo<ChartOptions<'bar'>>(() => {
    return {
      responsive: true,
      scales: {
        x: {
          type: 'category',
          title: { display: true, text: 'Metrics' },
        },
        y: {
          type: 'linear',
          beginAtZero: true,
          title: { display: true, text: 'Value' },
        },
      },
    };
  }, []);
  return <Bar data={snapshotChartData} options={snapshotChartOptions} />;
};

export default ClassComparisonChart;
