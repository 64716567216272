import React from 'react';
import Button from 'view/components/buttons/Button';

interface BookReaderTeacherControlsProps {
  hideQuestions: boolean;
  onViewQuestions?: () => void;
}

const BookReaderTeacherControls: React.FC<BookReaderTeacherControlsProps> = ({
  hideQuestions,
  onViewQuestions,
}) => {
  return (
    <div className="assignment-controls">
      {!hideQuestions && (
        <div className="book-completed-text">
          {onViewQuestions && (
            <Button
              type="option"
              onClick={onViewQuestions}
              text="Questions"
              label="Questions"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default BookReaderTeacherControls;
