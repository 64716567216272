import {
  faArrowAltCircleRight,
  faPauseCircle,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Button from 'view/components/buttons/Button';
import { ReadingState } from 'view/components/common/BookReader';

interface BookReaderStudentControlsProps {
  readingState: ReadingState;
  hideQuestions: boolean;
  onStartReading: () => void;
  onStopReading: () => void;
  onViewQuestions?: () => void;
}

const BookReaderStudentControls: React.FC<BookReaderStudentControlsProps> = ({
  readingState,
  hideQuestions,
  onStartReading,
  onStopReading,
  onViewQuestions,
}) => {
  return (
    <div className="assignment-controls">
      {readingState === ReadingState.Paused && (
        <Button
          type="go"
          onClick={onStartReading}
          text="Go"
          fontAwesomeIcon={faArrowAltCircleRight}
          label="Go"
        />
      )}
      {readingState === ReadingState.Reading && (
        <Button
          type="default"
          onClick={onStopReading}
          text="Stop"
          fontAwesomeIcon={faPauseCircle}
          label="Stop"
        />
      )}
      {!hideQuestions && (
        <div className="book-completed-text">
          {onViewQuestions && (
            <Button
              type="option"
              onClick={onViewQuestions}
              text="Questions"
              label="Questions"
            />
          )}
        </div>
      )}
      {readingState === ReadingState.Completed && hideQuestions && (
        <>
          <div className="book-completed-text"></div>
          <div className="book-completed-text">
            <h2>You're Done</h2>
          </div>
          <div className="book-completed-text">
            {onViewQuestions && (
              <Button
                type="option"
                onClick={onViewQuestions}
                text="Questions"
                label="Questions"
                shake={true}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default BookReaderStudentControls;
