import React, { useState, useEffect, useCallback, useRef } from 'react';
import { SpeechRecognitionService } from 'services/speechRecognitionService';
import parse from 'html-react-parser';
import Book from 'models/Book';
import Timer from 'utils/Timer';
import MissedWord from 'models/MissedWord';
import ReadingUtils from 'utils/ReadingUtils';
import MissedWordDisplay from '../student/MissedWordDisplay';
// import transcriptionService from 'services/transcriptionService';
import { SpeechMode } from 'utils/types';
import ReadingFrame from './ReadingFrame';
import Modal from './Modal';
import triggerConfetti from 'contexts/celebrations/Confetti';
import BookReaderStudentControls from './BookReader/StudentControls';
import BookReaderTeacherControls from './BookReader/TeacherControls';
import BookReaderCatalogControls from './BookReader/CatalogControls';

import 'view/style/student/assignment.css';
import 'view/style/common/celebrations/confetti.css';
import 'view/style/common/celebrations/fireworks.css';

const wormy_b: string = require('assets/images/logos/wormy-book-2.png');
const wormy_you: string = require('assets/images/logos/wormy-you.png');

export enum ReaderViewType {
  STUDENT = 0,
  TEACHER = 1,
  CATALOG = 2,
}

interface BookReaderProps {
  book: Book;
  startIndex: number;
  missedWords?: MissedWord[];
  isReading: boolean;
  showMissedWordPopup?: boolean;
  missedWordInterval?: number;
  customEndMessage?: string;
  dyslexicMode?: boolean;
  speechMode?: SpeechMode;
  hideQuestions?: boolean;
  teacherView?: boolean;
  selectView?: boolean;
  viewType: ReaderViewType;
  setIsReading: (value: boolean) => void;
  onCompletion?: () => void;
  onStopReading?: (index: number) => void;
  onMissedWord: (word: string, index: number, known: boolean) => void;
  onViewQuestions?: () => void;
  onSelect?: (book: Book, assignmentType?: string) => void;
}

export interface HtmlContent {
  content: string;
  index: number;
}

export enum ReadingState {
  Paused = 0,
  Reading = 1,
  Completed = 2,
  MissedWord = 3,
}

export const BookReader: React.FC<BookReaderProps> = ({
  book,
  startIndex,
  missedWords,
  isReading,
  showMissedWordPopup = true,
  missedWordInterval = 4,
  customEndMessage,
  dyslexicMode = false,
  speechMode = SpeechMode.Close,
  hideQuestions = true,
  teacherView = false,
  viewType,
  setIsReading,
  onCompletion,
  onStopReading,
  onMissedWord,
  onViewQuestions,
  onSelect,
}) => {
  const [wordIndex, setWordIndex] = useState(0);
  const [htmlContent, setHtmlContent] = useState<string>('');
  const [words, setWords] = useState<string[]>();
  const [timer] = useState<Timer>(new Timer());
  const [readingState, setReadingState] = useState<ReadingState>(
    ReadingState.Paused,
  );
  const [speechRecognitionService] = useState<SpeechRecognitionService>(
    new SpeechRecognitionService(),
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const missedWordTimerKey = 'missedWord';
  const autosaveTimerKey = 'autosave';

  useEffect(() => {
    const targetElement = document.getElementById((wordIndex - 1).toString());
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [htmlContent, wordIndex, readingState, isReading]);

  useEffect(() => {
    if (
      words &&
      words.length > 0 &&
      wordIndex >= words.length &&
      readingState !== ReadingState.MissedWord
    ) {
      setReadingState(ReadingState.Completed);
      if (!teacherView) triggerConfetti(3000);
      setIsReading(false);
      speechRecognitionService.stopSpeechRecognition();
      speechRecognitionService.clearTranscript();
      timer.clear(missedWordTimerKey);
      if (onCompletion) onCompletion();
    }
    if (
      words &&
      words.length > 0 &&
      wordIndex > 0 &&
      wordIndex < words.length - 1 &&
      !timer.timerExists(autosaveTimerKey) &&
      readingState !== ReadingState.Paused
    ) {
      timer.start(autosaveTimerKey, () => autosave(wordIndex), 15000);
    }
    // eslint-disable-next-line
  }, [wordIndex, words, readingState]);

  useEffect(() => {
    return () => {
      timer.clear(autosaveTimerKey);
      speechRecognitionService.stopSpeechRecognition();
      // transcriptionService.stopTranscription();
      timer.clear(missedWordTimerKey);
    };
  }, [timer, speechRecognitionService]);

  useEffect(() => {
    if (!book || !book.html_content || words || htmlContent) return;
    const bookWords = book.words ?? book.getWords();
    setHtmlContent(ReadingUtils.createHtmlString(bookWords, book.html_content));
    setWords(bookWords);
  }, [book, words, htmlContent]);

  useEffect(() => {
    if (htmlContent && startIndex > 0 && missedWords!! && wordIndex === 0) {
      // mark the reading up until the current word
      const missedWordIdxs: number[] = missedWords.map(
        (word) => word.word_index,
      );
      // create html string
      for (let i = 0; i < startIndex; i += 1) {
        highlightWord(!missedWordIdxs.includes(i), i);
      }
    }
    // eslint-disable-next-line
  }, [htmlContent, missedWords]);

  const highlightWord = useCallback(
    (isCorrect: boolean, wordIndex: number) => {
      const wordNode = containerRef.current?.querySelector('.brw.current');
      if (wordNode) {
        wordIndex = Number(wordNode.id.split('-')[1]);
        wordNode.className = `brw${isCorrect ? ' read' : ' incorrect'}`;
      }

      const nextWordNode = containerRef.current?.querySelector(
        `#word-${wordIndex + 1}`,
      );
      if (nextWordNode) {
        nextWordNode.className = `brw current`;
      }

      setWordIndex(wordIndex + 1);
    },
    [containerRef, setWordIndex],
  );

  const autosave = (wordIndex: number) => {
    timer.clear(autosaveTimerKey);
    if (onStopReading) onStopReading(wordIndex);
  };

  const handleStopReading = useCallback(() => {
    setIsReading(false);
    speechRecognitionService.stopSpeechRecognition();
    // transcriptionService.stopTranscription();
    timer.clear(missedWordTimerKey);
    speechRecognitionService.clearTranscript();

    if (onStopReading) onStopReading(wordIndex);

    timer.clear(autosaveTimerKey);
    setReadingState(ReadingState.Paused);
  }, [onStopReading, setIsReading, timer, wordIndex, speechRecognitionService]);

  const resetTimer = useCallback(() => {
    const timeThresholdExceeded = (wordIndex: number) => {
      timer.clear(missedWordTimerKey);
      highlightWord(false, wordIndex);
      if (showMissedWordPopup) {
        speechRecognitionService.stopSpeechRecognition();
        setHtmlContent(containerRef.current?.innerHTML as string);
        setReadingState(ReadingState.MissedWord);
      } else {
        speechRecognitionService.startSpeechRecognition();
        resetTimer();
      }
    };

    timer.clear(missedWordTimerKey);
    timer.start(
      missedWordTimerKey,
      () => timeThresholdExceeded(wordIndex),
      missedWordInterval * 1000,
    );
  }, [
    timer,
    missedWordTimerKey,
    wordIndex,
    speechRecognitionService,
    missedWordInterval,
    showMissedWordPopup,
    highlightWord,
  ]);

  const check = useCallback(
    (transcript: string[]) => {
      /*
      count the word as correct if any word in the transcript is similar to
      this current word  or if the current word is <= 3 characters and not a number
      and the most recent word in the transcript is the next word
    */
      if (!words || wordIndex >= words.length) return;
      let wordIdx = wordIndex;
      for (let i = 0; i < transcript.length && wordIdx < words.length; i++) {
        const currentWord = words[wordIdx];
        const currentWordIdx = wordIdx;
        // console.log(transcript, currentWord);
        if (
          transcript.some((mw) =>
            ReadingUtils.compare(mw, currentWord, SpeechMode.Close),
          ) ||
          (transcript.length >= 2 &&
            ReadingUtils.compare(
              `${transcript.at(-2)}${transcript.at(-1)}`,
              currentWord,
              SpeechMode.Close,
            ))
        ) {
          highlightWord(true, currentWordIdx);
          resetTimer();
          wordIdx += 1;
        } else if (
          currentWordIdx < words.length + 1 &&
          currentWord.length <= 3 &&
          isNaN(parseFloat(currentWord)) &&
          isNaN(Number(currentWord)) &&
          transcript.some((mw) =>
            ReadingUtils.compare(
              mw,
              words[currentWordIdx + 1],
              SpeechMode.Close,
            ),
          )
        ) {
          highlightWord(true, currentWordIdx);
          highlightWord(true, currentWordIdx + 1);
          resetTimer();
          wordIdx += 2;
        }
      }
    },
    [wordIndex, words, resetTimer, highlightWord],
  );

  useEffect(() => {
    const unsubscribe = speechRecognitionService.subscribe(check);

    return () => {
      unsubscribe();
    };
  }, [check, speechRecognitionService]);

  const handleStartReading = () => {
    if (words?.length && wordIndex >= words.length) {
      setReadingState(ReadingState.Completed);
      if (!teacherView) triggerConfetti(3000);
      return;
    }
    setIsReading(true);
    speechRecognitionService.startSpeechRecognition();
    // transcriptionService.startTranscription();
    resetTimer();
    setReadingState(ReadingState.Reading);
    timer.start(autosaveTimerKey, () => autosave(wordIndex), 15000);
  };

  const handleRetry = () => {
    setWordIndex(0);
    setReadingState(ReadingState.Reading);
    if (book.html_content) {
      const bookWords = book.words ?? book.getWords();
      setHtmlContent(
        ReadingUtils.createHtmlString(bookWords, book.html_content),
      );
    } else {
      console.log('failed to restart book');
    }
  };

  return (
    <>
      <ReadingFrame
        isBlurred={
          false &&
          onCompletion &&
          !isReading &&
          readingState !== ReadingState.Completed
        }
        isDyslexic={dyslexicMode}
        refLink={containerRef}
      >
        <>
          {parse(htmlContent)}
          {readingState === ReadingState.Completed && (
            <div className="col" style={{ alignItems: 'center' }}>
              <img
                src={wormy_you}
                alt="celebrate"
                className="celebration-image"
              />
              <br />
              {customEndMessage && (
                <span className="label-large">{customEndMessage}</span>
              )}
            </div>
          )}
        </>
      </ReadingFrame>
      <Modal
        isOpen={readingState === ReadingState.MissedWord && showMissedWordPopup}
      >
        <MissedWordDisplay
          word={
            book.words![
              Math.min(Math.max(0, wordIndex - 1), words?.length ?? 0)
            ]
          }
          index={Math.min(Math.max(0, wordIndex - 1), words?.length ?? 0)}
          onExit={handleStartReading}
          onMissedWord={onMissedWord}
        />
      </Modal>
      {viewType === ReaderViewType.STUDENT && (
        <BookReaderStudentControls
          readingState={readingState}
          hideQuestions={hideQuestions}
          onStartReading={handleStartReading}
          onStopReading={handleStopReading}
          onViewQuestions={onViewQuestions}
        />
      )}
      {viewType === ReaderViewType.TEACHER && (
        <BookReaderTeacherControls
          hideQuestions={hideQuestions}
          onViewQuestions={onViewQuestions}
        />
      )}
      {viewType === ReaderViewType.CATALOG && (
        <BookReaderCatalogControls
          readingState={readingState}
          hideQuestions={hideQuestions}
          onStartReading={handleStartReading}
          onStopReading={handleStopReading}
          onViewQuestions={onViewQuestions}
          onRetry={handleRetry}
          onSelect={
            onSelect
              ? (assignmentType: string) => onSelect(book, assignmentType)
              : undefined
          }
        />
      )}
      {!(
        true ||
        !onCompletion ||
        isReading ||
        readingState === ReadingState.Completed
      ) && (
        <div className="book-emoji">
          <img src={book.cover_url !== '' ? book.cover_url : wormy_b} alt="" />
        </div>
      )}
    </>
  );
};
