import React, { useEffect, useState } from 'react';
import ActionButton from '../buttons/ActionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusSquare,
  faSave,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

import 'view/style/common/components/inputTable.css';

type InputTableProps<T> = {
  headers: Array<{ key: keyof T; label: string; type: string }>;
  importRows?: T[];
  size?: number;
  maxRows?: number;
  onSubmit?: (rows: T[]) => Promise<boolean[]>;
};

const InputTable = <T extends Record<string, any>>({
  headers,
  size = 1,
  maxRows,
  importRows,
  onSubmit,
}: InputTableProps<T>) => {
  // Initialize state with the initialRows or empty rows based on the size
  const [rows, setRows] = useState<T[]>(
    Array.from({ length: size }, () =>
      headers.reduce((acc, { key }) => ({ ...acc, [key]: '' }), {} as T),
    ),
  );

  useEffect(() => {
    if (!importRows) return;
    setRows(importRows);
  }, [importRows]);

  const addRow = () => {
    if (maxRows && rows.length >= maxRows) return;
    setRows([
      ...rows,
      headers.reduce((acc, { key }) => ({ ...acc, [key]: '' }), {} as T),
    ]);
  };

  const deleteRow = (index: number) => {
    const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
    if (updatedRows.length === 0) {
      updatedRows.push(
        headers.reduce((acc, { key }) => ({ ...acc, [key]: '' }), {} as T),
      );
    }
    setRows(updatedRows);
  };

  const updateCell = (rowIndex: number, key: keyof T, value: string) => {
    const updatedRows = rows.map((row, rIndex) =>
      rIndex === rowIndex ? { ...row, [key]: value } : row,
    );
    setRows(updatedRows);
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(rows).then((results) =>
        setRows((prev) => prev?.filter((_, index) => !results[index])),
      );
    }
  };

  return (
    <>
      <div className="input-table-container">
        <table className="input-table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header.label}</th>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {headers.map(({ key, label, type }) => (
                  <td key={String(key)}>
                    <input
                      type={type}
                      value={row[key]}
                      onChange={(e) =>
                        updateCell(rowIndex, key, e.target.value)
                      }
                    />
                  </td>
                ))}
                <td onClick={() => deleteRow(rowIndex)}>
                  <FontAwesomeIcon icon={faTrash} color="var(--dark-pink)" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="row">
        <ActionButton
          type="edit"
          label="Add Row"
          icon={faPlusSquare}
          onClick={addRow}
          disabled={maxRows ? rows.length >= maxRows : false}
        />
        {onSubmit && (
          <ActionButton
            type="go"
            label="Submit"
            icon={faSave}
            onClick={handleSubmit}
          />
        )}
      </div>
    </>
  );
};

export default InputTable;
