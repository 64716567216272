import { useClassroomContext } from 'contexts/ClassroomContext';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PanelButton from 'view/components/common/PanelButton';
import Modal from 'view/components/common/Modal';
import PracticeAssignmentForm from './forms/Practice';
import Classroom from 'models/Classroom';
import {
  faBookOpen,
  faClipboardList,
  faEarListen,
  faLayerGroup,
  faListUl,
  faMicrophone,
} from '@fortawesome/free-solid-svg-icons';
import { BookCatalog } from 'view/components/catalog/BookCatalog';
import Assignment from 'models/Assignment';
import CachingEngine from 'utils/CachingEngine';
import Book from 'models/Book';

import 'view/style/teacher/components/assignment/assignmentTypeForm.css';

const arrow_drop_down: string =
  require('assets/icons/arrow_drop_down.svg').default;
const arrow_right: string = require('assets/icons/arrow_right.svg').default;

interface AssignmentTypeFormProps {}

const assignmentTypes = [
  {
    displayName: 'Multipart',
    serverName: 'Multi-Part',
    hint: 'Any combination of questions, readings, and other activities in a question by question quiz format',
    icon: faListUl,
  },
  {
    displayName: 'Reading',
    serverName: '',
    hint: 'Students read aloud with immediate missed word feedback in real time',
    icon: faBookOpen,
  },
  {
    displayName: 'Listening',
    serverName: 'Read-Aloud',
    hint: 'The text will be read aloud to the students in a realistic voice',
    icon: faEarListen,
  },
];

const assessmentTypes = [
  {
    displayName: 'Listening Assessment',
    serverName: 'Listening',
    hint: 'Like a Listening assignment, except the student cannot see the words being spoken',
    icon: faEarListen,
  },
  {
    displayName: 'Speaking Assessment (beta)',
    serverName: 'Speaking',
    hint: 'Like a Reading assignment, except the feedback is given all at once after the student completes the reading',
    icon: faMicrophone,
  },
];

const AssignmentTypeForm: React.FC<AssignmentTypeFormProps> = () => {
  const [showPracticeWindow, setShowPracticeWindow] = useState<boolean>(false);
  const [viewCatalog, setViewCatalog] = useState<boolean>(false);
  const { classroom } = useClassroomContext();
  const [viewTemplateOptions, setViewTemplateOptions] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const navToAssignmentForm = (assignmentType: string) => {
    navigate(
      `/teacher/classroom/${classroom?.getId()}/new-assignment/${assignmentType}`,
    );
  };

  const handleSelectCatalogBook = (book: Book, assignmentType?: string) => {
    CachingEngine.setData('book', book);
    setViewCatalog(false);
    navigate(`new-assignment/${assignmentType ?? ''}`);
  };

  const handleSelectCatalogAssignment = (
    assignment: Assignment,
    book?: Book,
  ) => {
    CachingEngine.setData('assignment', assignment);
    if (book) CachingEngine.setData('book', book);
    setViewCatalog(false);
    navigate(`new-assignment/${assignment.assignment_type}`);
  };

  return (
    <div className="form assignment-type">
      <label htmlFor="assignment-type-form" className="label-large">
        Choose assignment type
      </label>
      <div id="assignment-type-form" className="form-contents">
        <PanelButton
          key="asgn-type-from-catalog"
          label="From catalog"
          hint="Use an existing book or assignment from the catalog as a starting point"
          icon={faClipboardList}
          onClick={() => setViewCatalog(true)}
        />
        {assignmentTypes.map((assignmentType) => (
          <PanelButton
            key={`asgn-type-${assignmentType.displayName}`}
            label={assignmentType.displayName}
            hint={assignmentType.hint}
            icon={assignmentType.icon}
            onClick={() => navToAssignmentForm(assignmentType.serverName)}
          />
        ))}
        <PanelButton
          key={`asgn-type-practice`}
          label="Flashcards"
          hint="Choose either definition or pronunciation flashcards to target specific words or phrases"
          icon={faLayerGroup}
          onClick={() => setShowPracticeWindow(true)}
        />
        <div
          className="row"
          onClick={() => setViewTemplateOptions((prev) => !prev)}
        >
          <label className="label-medium">Or try an assessment</label>
          <img
            src={viewTemplateOptions ? arrow_drop_down : arrow_right}
            alt="dropdown"
          />
        </div>
        {viewTemplateOptions &&
          assessmentTypes.map((assessmentType) => (
            <PanelButton
              key={`asgn-type-${assessmentType.displayName}`}
              label={assessmentType.displayName}
              hint={assessmentType.hint}
              icon={assessmentType.icon}
              onClick={() => navToAssignmentForm(assessmentType.serverName)}
            />
          ))}
      </div>
      <Modal
        isOpen={showPracticeWindow}
        onClose={() => setShowPracticeWindow(false)}
      >
        <PracticeAssignmentForm
          classroom={classroom as Classroom}
          onExit={() => setShowPracticeWindow(false)}
        />
      </Modal>
      <Modal
        isOpen={viewCatalog}
        onClose={() => setViewCatalog(false)}
        style={{
          width: '100vw',
          height: '90vh',
          maxWidth: '100vw',
          maxHeight: '90vh',
        }}
      >
        <BookCatalog
          onExit={() => setViewCatalog(false)}
          onSelect={handleSelectCatalogBook}
          onSelectAssignment={handleSelectCatalogAssignment}
        />
      </Modal>
    </div>
  );
};

export default AssignmentTypeForm;
